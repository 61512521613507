import React, { useEffect } from "react";
import PT from "prop-types";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

const Anim = ({ children }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.25,
    });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <motion.div
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
            }}
            transition={{ ease: "easeOut", duration: 1, delay: 0.5 }}
        >
            {children}
        </motion.div>
    );
};

Anim.propTypes = {
    children: PT.any,
};

export default Anim;
