import { createGlobalStyle } from "styled-components";

import { down } from "~/src/config/vars";

export const header = {
    height: "var(--header-height)",
    height_minus: "var(--header-height-minus)",
};

export const VarsHeaderStyle = createGlobalStyle`
    :root {
        --header-height: 86px;
        --header-height-minus: -86px;
        ${down("md")} {
            --header-height: 68px;
            --header-height-minus: -68px;
        }
        ${down("sm")} {
            --header-height: 68px;
            --header-height-minus: -68px;
        }
    }
`;
