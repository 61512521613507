import { createGlobalStyle } from "styled-components";

import { colors, fonts } from "~/src/config/vars";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        outline: none !important;
    }
    body {
        -webkit-font-smoothing: subpixel-antialiased;
        line-height: 1.4;
        background-color: ${colors.black};
        font-weight: ${fonts.fw_regular};
        color: ${colors.white};
        overflow-y: scroll;
        font-family: ${fonts.ff_global};
        @supports (font-variation-settings: normal) {
            font-family: ${fonts.ff_global_variable};
        }
    }
    input:focus {
        outline: none !important;
    }
    a {
        text-decoration: none;
        color: ${colors.red};
        transition: all 0.1s;
        cursor: pointer;
        @media (hover) {
            &:hover {
                color: ${colors.red_light};
                text-decoration: none;
            }
        }
    }
    svg {
        display: block;
    }
    body.open_modal #global_content {
        position: fixed !important;
        width: 100%;
    }
    [data-gatsby-image-wrapper] {
        pointer-events: none;
    }
`;

export default GlobalStyle;
