import { createGlobalStyle } from "styled-components";
import { lighten } from "polished";

export const colors = {
    body: "#fff",

    white: "var(--color-white)",
    black: "var(--color-black)",
    black_light: "var(--color-black-light)",
    black_light2: "var(--color-black-light2)",
    black_light3: "var(--color-black-light3)",
    black_light4: "var(--color-black-light4)",
    blue: "var(--color-blue)",
    red: "var(--color-red)",
    red_light: "var(--color-red-light)",
    yellow: "var(--color-yellow)",

    grey: "var(--color-grey)",
    grey_light: "var(--color-grey-light)",
    grey_light2: "var(--color-grey-light2)",
    grey_light3: "var(--color-grey-light3)",
    grey_light4: "var(--color-grey-light4)",
    grey_light5: "var(--color-grey-light5)",
    grey_light6: "var(--color-grey-light6)",
};

export const VarsThemeStyle = createGlobalStyle`
        :root {
            --color-body: #fff;
            //
            --color-white: #fff;
            --color-black: #000;
            --color-black-light: ${lighten(0.03, "#191818")};
            --color-black-light2: ${lighten(0.06, "#191818")};
            --color-black-light3: ${lighten(0.09, "#191818")};
            --color-black-light4: ${lighten(0.12, "#191818")};
            --color-blue: #3151ff;
            --color-red: #ed253f;
            --color-red-light: #e73e54;
            --color-yellow: #ffce2e;
            //
            --color-grey: #75767a;
            --color-grey-light: ${lighten(0.3, "#75767a")};
            --color-grey-light2: ${lighten(0.35, "#75767a")};
            --color-grey-light3: ${lighten(0.4, "#75767a")};
            --color-grey-light4: ${lighten(0.45, "#75767a")};
            --color-grey-light5: ${lighten(0.49, "#75767a")};
            --color-grey-light6: ${lighten(0.52, "#75767a")};
        }
`;
