import React from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";

import { colors, down } from "~/src/config/vars";

const Loader = ({ type, size, bg, bg_color }) => {
    return (
        <SLoader className={`${size} ${type}`}>
            {bg && <Bg style={{ backgroundColor: bg_color }} />}
            <Spinner />
        </SLoader>
    );
};

Loader.defaultProps = {
    type: "block",
    size: "large",
    bg_color: colors.white,
};

Loader.propTypes = {
    type: PT.oneOf(["block", "absolute", "fixed"]),
    size: PT.oneOf(["mini", "large", "big"]),
    bg: PT.bool,
    bg_color: PT.string,
};

const SLoader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 6;
    /* ////////////// */
    /* type */
    /* ////////////// */
    &.block {
        height: 10em;
        ${down("lg")} {
            height: 5em;
        }
    }
    &.absolute {
        ${position("absolute", "0", "0", "0", "0")};
    }
    &.fixed {
        ${position("fixed", "0", "0", "0", "0")};
    }
    /* ////////////// */
    /* size */
    /* ////////////// */
    &.mini {
        min-width: 1.4em;
        min-height: 1.4em;
    }
    &.large {
        min-width: 2.4em;
        min-height: 2.4em;
    }
    &.big {
        min-width: 3.4em;
        min-height: 3.4em;
    }
`;

const Bg = styled.div`
    ${position("absolute", "0", "0", "0", "0")};
    opacity: 0.95;
`;

const Spinner = styled.div`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateX(-50%) translateY(-50%);
    z-index: 6;
    background: 0 none;
    margin: 0;
    &:after {
        content: "";
        height: 5em;
        width: 5em;
        display: block;
        border-radius: 100%;
        border-style: solid;
        border-color: ${colors.yellow};
        border-bottom-color: ${colors.blue};
        animation: loader_spin 1s linear 0s infinite;
    }
    ${SLoader}.mini & {
        &:after {
            width: 1.4em;
            height: 1.4em;
            border-width: 2px;
        }
    }
    ${SLoader}.large & {
        &:after {
            width: 2.4em;
            height: 2.4em;
            border-width: 3px;
        }
    }
    ${SLoader}.big & {
        &:after {
            width: 3.4em;
            height: 3.4em;
            border-width: 3px;
        }
    }
    @keyframes loader_spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

export default Loader;
