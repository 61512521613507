import React, { useCallback, useEffect } from "react";
import PT from "prop-types";
import styled from "styled-components";
import { position } from "polished";

import useModalManager from "./useModalManager";
import { CrossIcon } from "~/src/components/icons";
import { colors, up, down, header } from "~/src/config/vars";

const Modal = ({ children, name, full }) => {
    const { isOpen, closeModal } = useModalManager(name);
    const is_open = isOpen(name);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            closeModal(name);
        }
    }, []);
    useEffect(() => {
        document.addEventListener("keydown", escFunction);
        return () => {
            document.removeEventListener("keydown", escFunction);
        };
    }, [escFunction]);

    return (
        <SModal
            ariaHideApp={false}
            className={`SModal ${is_open ? "active" : null} ${
                full ? "full" : null
            } ${name}`}
        >
            <SBody className={`${is_open ? "active" : null}`}>
                <SContent>{children}</SContent>
                <SClose onClick={() => closeModal(name)}>
                    <CrossIcon />
                </SClose>
            </SBody>

            <SOverlay
                className={`${is_open ? "active" : null}`}
                onClick={() => closeModal(name)}
            />
        </SModal>
    );
};

Modal.propTypes = {
    children: PT.any.isRequired,
    name: PT.string.isRequired,
    full: PT.bool,
    // hoooks
    onOpen: PT.func,
    onClose: PT.func,
};

export default Modal;

const SModal = styled.div`
    ${position("absolute", "0", "0", null, null)};
    z-index: 26;
    min-height: 100%;
    width: 100%;
    visibility: hidden;
    backface-visibility: hidden;
    height: 0;
    &.active {
        visibility: visible;
        height: auto;
    }
    ${up("lg")} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;
const SOverlay = styled.div`
    ${position("fixed", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    background-color: ${colors.black};
    z-index: 4;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    &.active {
        opacity: 0.9;
    }
    ${down("md")} {
        transition: all 0s;
        background-color: ${colors.white};
        &.active {
            opacity: 1;
        }
    }
`;
const SBody = styled.div`
    width: 100%;
    position: relative;
    z-index: 10;
    max-width: 850px;
    opacity: 0;
    transition: all 0s;
    &.active {
        opacity: 1;
    }
    ${down("md")} {
        max-width: none;
    }
    ${up("md")} {
        background: ${colors.white};
        margin: 2em auto;
        transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
            opacity 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: translateY(-400px);
        &.active {
            transform: translateY(0);
        }
    }
    ${SModal}.full & {
        margin: 0;
        max-width: none;
    }
`;
const SClose = styled.div`
    ${position("absolute", "1em", "1em", null, null)};
    display: flex;
    cursor: pointer;
    transition: all 0.1s;
    color: ${colors.white};
    @media (hover) {
        &:hover {
            color: ${colors.red};
        }
    }
    svg {
        width: 1.4em;
        height: 1.4em;
    }
    ${up("lg")} {
        transform: rotate(90deg);
        transform-origin: center;
    }
    ${down("md")} {
        ${position("fixed", "0", null, null, "0")};
        display: flex;
        align-items: center;
        z-index: 2;
        right: auto;
        width: 100%;
        justify-content: center;
        height: ${header.height};
        padding: 0 1em;
        background: ${colors.black};
        color: ${colors.red};
    }
`;
/////////////////////
const SContent = styled.div``;
