import React from "react";
import styled from "styled-components";

import { TelegramIcon } from "~/src/components/icons";
import { OpenModal } from "~/src/components";
import { colors, down, global, fonts } from "~/src/config/vars";

const HeaderTelegram = () => {
    return (
        <SHeaderTelegram>
            <STelegram href={`https://t.me/yurii_repin`} target="_blank">
                <span>Telegram</span> <TelegramIcon />
            </STelegram>
            <SCallme>
                <OpenModal name="callme">
                    <SCallmeText>
                        <span>Request a call back</span>
                        <div>Call back</div>
                    </SCallmeText>
                </OpenModal>
            </SCallme>
        </SHeaderTelegram>
    );
};

const SHeaderTelegram = styled.div`
    color: ${colors.grey_light};
    line-height: 1;
    padding-left: 2em;
    position: relative;
    top: -0.1em;
    ${down("md")} {
        padding-left: 1.3em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
`;
const STelegram = styled.a`
    display: block;
    text-decoration: none;
    color: ${colors.white};
    display: inline-flex;
    transition: all ${global.transition};
    font-size: ${fonts.fs_20};
    font-weight: ${fonts.fw_medium};
    ${down("md")} {
        font-size: ${fonts.fs_18};
        order: 2;
        padding: 15px;
        margin-right: -10px;
        span {
            display: none;
        }
    }
    > svg {
        display: none;
        ${down("md")} {
            width: 1.7em;
            height: 1.7em;
            display: block;
        }
    }
    @media (hover) {
        &:hover {
            color: ${colors.red};
        }
    }
`;

const SCallme = styled.div`
    order: 1;
`;

const SCallmeText = styled.div`
    color: ${colors.grey_light};
    cursor: pointer;
    display: inline-flex;
    font-size: ${fonts.fs_14};
    transition: all ${global.transition};
    ${down("md")} {
        display: block;
        padding: 1em 0;
    }
    @media (hover) {
        &:hover {
            color: ${colors.white};
        }
    }
    span,
    div {
        border-bottom: 1px dotted ${colors.white};
        line-height: 1.4;
    }
    span {
        ${down("md")} {
            display: none;
        }
    }
    div {
        display: none;
        ${down("md")} {
            display: block;
        }
    }
`;

export default HeaderTelegram;
