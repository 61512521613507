import { createGlobalStyle } from "styled-components";

import CodecPro_Ultra_woff2 from "../fonts/CodecPro/web/CodecPro-Ultra.woff2";
import CodecPro_Ultra_woff from "../fonts/CodecPro/web/CodecPro-Ultra.woff";

import CodecPro_ExtraBold_woff2 from "../fonts/CodecPro/web/CodecPro-ExtraBold.woff2";
import CodecPro_ExtraBold_woff from "../fonts/CodecPro/web/CodecPro-ExtraBold.woff";

import CodecPro_Bold_woff2 from "../fonts/CodecPro/web/CodecPro-Bold.woff2";
import CodecPro_Bold_woff from "../fonts/CodecPro/web/CodecPro-Bold.woff";

import CodecPro_News_woff2 from "../fonts/CodecPro/web/CodecPro-News.woff2";
import CodecPro_News_woff from "../fonts/CodecPro/web/CodecPro-News.woff";

import CodecProVariable from "../fonts/CodecPro/variable/CodecProVariableGX.woff2";

const FontsStyle = createGlobalStyle`

    @supports (font-variation-settings: normal) {
        @font-face {
            font-family: "Codec Pro Variable";
            src: url(${CodecProVariable});
            font-weight: 38 682;
            font-italic: 100 1000;
        }
    }

    @font-face {
        font-family: "Codec Pro";
        src: url(${CodecPro_Ultra_woff2}) format("woff2"),
            url(${CodecPro_Ultra_woff}) format("woff");
        font-weight: 350;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: "Codec Pro";
        src: url(${CodecPro_ExtraBold_woff2}) format("woff2"),
            url(${CodecPro_ExtraBold_woff}) format("woff");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: "Codec Pro";
        src: url(${CodecPro_Bold_woff2}) format("woff2"),
            url(${CodecPro_Bold_woff}) format("woff");
        font-weight: 240;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: "Codec Pro";
        src: url(${CodecPro_News_woff2}) format("woff2"),
            url(${CodecPro_News_woff}) format("woff");
        font-weight: 160;
        font-style: normal;
        font-display: swap;
    }

`;

export default FontsStyle;
