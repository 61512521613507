import React from "react";
import PT from "prop-types";
import styled from "styled-components";
import { position } from "polished";
import { Link } from "gatsby";

import { CheckIcon } from "~/src/components/icons";
import { colors, down } from "~/src/config/vars";

const FormCheckboxField = ({
    field,
    form,
    label,
    color,
    route,
    link_text,
    ...rest
}) => {
    const meta = form.getFieldMeta(field.name);
    return (
        <FormCheck className={`${color}`}>
            <FormCheckBlock>
                <FormCheckInput type="checkbox" {...field} {...rest} />
                <FormCheckFix>
                    <FormCheckArrow>
                        <CheckIcon />
                    </FormCheckArrow>
                </FormCheckFix>
                <FormCheckLabel>
                    <div className="FormCheckText">{label}</div>
                    {route && link_text ? (
                        <Link route={route}>
                            <a className="FormCheckLink">{link_text}</a>
                        </Link>
                    ) : null}
                </FormCheckLabel>
            </FormCheckBlock>
            {meta.error && <FormCheckError>{meta.error}</FormCheckError>}
        </FormCheck>
    );
};

FormCheckboxField.propTypes = {
    field: PT.object.isRequired,
    form: PT.object.isRequired,
    label: PT.string,
    color: PT.oneOf(["default", "white"]),
    // link
    route: PT.string,
    link_text: PT.string,
};

const FormCheck = styled.div`
    margin-bottom: 0.8em;
    &:last-of-type {
        margin-bottom: 0;
    }
`;

const FormCheckBlock = styled.label`
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const FormCheckFix = styled.div`
    transition: all 0.1s;
    position: relative;
    top: 0;
    flex: 0 0 1.4em;
    width: 1.4em;
    height: 1.4em;
    background-color: transparent;
    border: 2px solid ${colors.grey_light2};
    ${FormCheck}:hover & {
        border-color: ${colors.grey};
    }
    ${FormCheck}.white & {
        border-color: ${colors.white};
        &:hover {
            border-color: ${colors.white};
        }
    }
`;

const FormCheckArrow = styled.div`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    transition: all 0.1s;
    opacity: 0;
    svg {
        width: 0.6em;
        height: 0.6em;
        color: ${colors.white};
    }
`;

const FormCheckInput = styled.input`
    opacity: 0;
    margin: 0;
    padding: 0;
    width: 1px;
    height: 0;
    display: none;
    &:checked + ${FormCheckFix} {
        background-color: ${colors.red};
        border-color: ${colors.red} !important;
    }
    ${FormCheck}.white & {
        &:checked + ${FormCheckFix} {
            background-color: ${colors.red};
            border-color: ${colors.white} !important;
        }
    }
    &:checked + ${FormCheckFix} ${FormCheckArrow} {
        opacity: 1;
    }
`;

const FormCheckLabel = styled.div`
    padding-left: 0.5em;
    line-height: 1.2;
    ${down("sm")} {
        font-size: 0.9em;
    }
    .FormCheckText {
        display: inline-flex;
    }
    .FormCheckLink {
        color: ${colors.black};
        display: block;
        &:hover {
            color: ${colors.red};
        }
    }
    .FormCheckLinkText {
    }
`;

const FormCheckError = styled.div`
    margin-top: 0.3em;
    padding-left: 2.1em;
    font-size: 0.9em;
    color: ${colors.red};
`;

export default FormCheckboxField;
