import React from "react";
import styled from "styled-components";
import { position } from "polished";

import { Container } from "~/src/components";
import { HeaderLogo, HeaderTelegram } from "~/src/components/header";
import { colors, down, header } from "~/src/config/vars";

const Header = () => {
    return (
        <SHeader>
            <Container>
                <SHeaderBlock>
                    <HeaderLogo />
                    <HeaderTelegram />
                </SHeaderBlock>
            </Container>
        </SHeader>
    );
};

const SHeader = styled.header`
    ${position("fixed", "0", null, null, "0")};
    width: 100%;
    background-color: ${colors.black};
    border-bottom: 1px solid ${colors.black_light};
    z-index: 10;
`;
const SHeaderBlock = styled.div`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    height: ${header.height};
    ${down("lg")} {
        position: relative;
    }
`;

export default Header;
