import React from "react";
import PT from "prop-types";
import styled from "styled-components";

import Modal from "./Modal";
import { TelegramIcon } from "~/src/components/icons";
import { CallmeForm } from "~/src/components/forms";
import { colors, contacts, down, fonts } from "~/src/config/vars";

const ModalCallme = () => {
    return (
        <Modal name="callme" loading={false}>
            <SModalCallme>
                <SForm>
                    <CallmeForm />
                </SForm>
                <SInfo>
                    <Text>
                        With pleasure we will answer
                        <br />
                        all your questions by telegram
                    </Text>
                    <STelegram
                        href={`https://t.me/yurii_repin`}
                        target="_blank"
                    >
                        <span>yurii_repin</span> <TelegramIcon />
                    </STelegram>
                    <Text>
                        Answer any of your questions by mail{" "}
                        <a href={`mailto:${contacts.email_global}`}>
                            {contacts.email_global}
                        </a>
                    </Text>
                </SInfo>
            </SModalCallme>
        </Modal>
    );
};

ModalCallme.propTypes = {
    name: PT.string,
};

const SModalCallme = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const SForm = styled.div`
    width: 50%;
    padding: 3em;
    background-color: ${colors.red};
    color: ${colors.white};
    ${down("md")} {
        padding: 5em 3em 3em;
    }
    ${down("sm")} {
        width: 100%;
        order: 2;
        padding: 2em 1em 8em;
    }
`;
const SInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 3em;
    background-color: ${colors.black_light};
    ${down("sm")} {
        display: block;
        width: 100%;
        order: 1;
        padding: 5em 1em 2em;
    }
`;
const Text = styled.div`
    margin-bottom: 1em;
    text-align: center;
    ${down("sm")} {
        text-align: left;
    }
`;
const STelegram = styled.a`
    margin-bottom: 1em;
    font-size: ${fonts.fs_22};
    display: flex;
    align-items: center;
    font-weight: ${fonts.fw_regular};
    color: ${colors.white};
    span {
    }
    > svg {
        width: 1.4em;
        height: 1.4em;
        margin-left: 0.3em;
        color: ${colors.red_light};
    }
    ${down("sm")} {
        font-size: ${fonts.fs_22};
    }
`;

export default ModalCallme;
