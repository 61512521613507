import React, { useState, useEffect } from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";
import HoverVideoPlayer from "react-hover-video-player";
import Observer from "@researchgate/react-intersection-observer";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";
import { size_down } from "~/src/config/vars";

import video_mp4 from "./card/kghoney_video.mp4";
import video_webm from "./card/kghoney_video.webm";

const KghoneyCard = () => {
    const data = useStaticQuery(graphql`
        query LabyrinthQuery {
            loading: file(name: { eq: "kghoney_video" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 370)
                }
            }
        }
    `);
    const loading = getImage(data.loading);
    //
    const only_mobile = size_down("md");
    const [play, setPlay] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (only_mobile) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [only_mobile]);

    const handleIntersection = (event) => {
        if (isMobile) {
            if (event.isIntersecting) {
                setPlay(true);
            } else {
                setPlay(false);
            }
        }
    };

    const options = {
        onChange: handleIntersection,
        threshold: 1,
    };

    return (
        <ProjectCard
            title="Kyrgyz Honey"
            desc="Honey manufacturers"
            slug="kghoney"
        >
            <Observer {...options}>
                <SKghoneyCard className={play ? "kghoney_card_hover" : ""}>
                    <SBottle>
                        <HoverVideoPlayer
                            videoSrc={[
                                { src: video_webm, type: "video/webm" },
                                { src: video_mp4, type: "video/mp4" },
                            ]}
                            style={{ display: "flex" }}
                            videoStyle={{
                                width: "60%",
                            }}
                            focused={play}
                            loadingOverlayWrapperStyle={{
                                width: "60%",
                                transform: "translateX(-50%)",
                                left: "50%",
                            }}
                            loadingOverlay={
                                <GatsbyImage
                                    image={loading}
                                    alt="Blindville2 loading"
                                    objectFit="contain"
                                />
                            }
                        />
                    </SBottle>
                </SKghoneyCard>
            </Observer>
        </ProjectCard>
    );
};

KghoneyCard.propTypes = {
    xy: PT.array,
};

const SKghoneyCard = styled.div`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    padding: 2em;
    background-color: #000;
    color: #fff;
    svg {
        pointer-events: none;
        position: relative;
        transition: all 0.2s;
        z-index: 2;
        .project_card_hover & {
            opacity: 0;
        }
    }
    &.kghoney_card_hover {
        svg {
            opacity: 0;
        }
    }
`;
const SBottle = styled.div`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    div {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
`;

export default KghoneyCard;
