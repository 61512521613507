import React from "react";
import PT from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

import defaultImage from "~/src/images/meta_share.jpg";

const concatMeta = (...meta_objects) =>
    meta_objects.reduce((result, current) => {
        if (current) {
            result.push(current);
        }
        return result;
    }, []);

function Meta({
    title,
    image,
    description,
    published_time,
    modified_time,
    lang = "ru",
}) {
    const { pathname } = useLocation();
    const { site } = useStaticQuery(query);

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        siteAuthor,
    } = site.siteMetadata;

    const meta = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        siteUrl: `${siteUrl}${pathname}`,
        author: siteAuthor,
    };

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={meta.title}
            titleTemplate={titleTemplate}
            meta={concatMeta(
                {
                    name: "viewport",
                    content:
                        "width=device-width, minimum-scale=1, maximum-scale=1, initial-scale=1, user-scalable=no",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:site_name",
                    content: "DangerCactus",
                },
                {
                    property: "og:title",
                    content: title,
                },
                meta?.description
                    ? {
                          property: "og:description",
                          content: meta.description,
                      }
                    : null,
                image
                    ? {
                          property: "og:image",
                          content: `${meta.image}`,
                      }
                    : null,
                image
                    ? {
                          property: "og:image:secure_url",
                          content: `${meta.image}`,
                      }
                    : null,
                image
                    ? {
                          property: "og:image:width",
                          content: `1200px`,
                      }
                    : null,
                image
                    ? {
                          property: "og:image:height",
                          content: `630px`,
                      }
                    : null,

                published_time
                    ? {
                          property: "article:published_time",
                          content: published_time,
                      }
                    : null,
                modified_time
                    ? {
                          property: "article:modified_time",
                          content: modified_time,
                      }
                    : null,
                modified_time
                    ? {
                          property: "og:updated_time",
                          content: modified_time,
                      }
                    : null,
                modified_time
                    ? {
                          property: "og:updated_time",
                          content: modified_time,
                      }
                    : null,
                {
                    name: "author",
                    content: meta.author,
                },
                {
                    property: "og:locale",
                    content: "en_US",
                },
                siteUrl
                    ? {
                          property: "og:url",
                          content: siteUrl,
                      }
                    : null,
                {
                    name: "description",
                    content: meta.description,
                },
                {
                    name: "twitter:site",
                    content: "@danger_cactus",
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: "twitter:description",
                    content: meta.description,
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:image",
                    content: meta.image,
                },
                {
                    name: "telegram:channel",
                    content: "@danger_cactus",
                }
            )}
        />
    );
}

Meta.propTypes = {
    title: PT.string.isRequired,
    image: PT.string,
    og_url: PT.string,
    description: PT.string,
    part: PT.string,
    published_time: PT.string,
    modified_time: PT.string,
    siteAuthor: PT.string,

    meta: PT.arrayOf(PT.object),
    lang: PT.string,
};

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: siteUrl
                siteAuthor: siteAuthor
            }
        }
    }
`;

export default Meta;
