import React from "react";
import styled from "styled-components";

import { Container } from "~/src/components";
import { colors, down } from "~/src/config/vars";

const Footer = () => {
    return (
        <SFooter>
            <Container>
                <SBlock>
                    <SCopyRight>
                        © Danger Cactus, 2016 — {new Date().getFullYear()}.
                    </SCopyRight>
                </SBlock>
            </Container>
        </SFooter>
    );
};

const SFooter = styled.footer`
    color: ${colors.grey_light4};
    margin-top: 4em;
    ${down("sm")} {
        margin-top: 2em;
    }
`;

const SBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3em 0 2em;
    ${down("sm")} {
        padding: 2em 0 2em;
        flex-wrap: wrap;
    }
`;
const SCopyRight = styled.div`
    font-size: 0.9em;
    width: 100%;
    ${down("sm")} {
        text-align: center;
    }
`;

export default Footer;
