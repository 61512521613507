import React from "react";

const FamodaCyrcleRound = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 640">
            <g transform="translate(8640.5 -17073.229)">
                <g transform="translate(-8638 17076)">
                    <circle
                        cx="317.5"
                        cy="317.5"
                        r="317.5"
                        transform="translate(0 -0.271)"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                        fill="none"
                    />
                </g>
                <g transform="translate(-8605.07 17108.93)">
                    <circle
                        cx="284.5"
                        cy="284.5"
                        r="284.5"
                        transform="translate(0.07 -0.201)"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                        fill="none"
                    />
                </g>
                <g transform="translate(-8572.14 17141.859)">
                    <circle
                        cx="251.5"
                        cy="251.5"
                        r="251.5"
                        transform="translate(0.14 -0.131)"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                        fill="none"
                    />
                </g>
                <g transform="translate(-8539.21 17174.791)">
                    <circle
                        cx="218.5"
                        cy="218.5"
                        r="218.5"
                        transform="translate(0.21 -0.063)"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                        fill="none"
                    />
                </g>
                <g transform="translate(-8504.633 17209.367)">
                    <ellipse
                        cx="185"
                        cy="184.5"
                        rx="185"
                        ry="184.5"
                        transform="translate(-0.367 0.361)"
                        fill="none"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                    />
                </g>
                <g transform="translate(-8471.703 17242.297)">
                    <ellipse
                        cx="152"
                        cy="151.5"
                        rx="152"
                        ry="151.5"
                        transform="translate(-0.297 0.432)"
                        fill="none"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                    />
                </g>
                <g transform="translate(-8438.772 17275.227)">
                    <circle
                        cx="119.5"
                        cy="119.5"
                        r="119.5"
                        transform="translate(-0.228 -0.498)"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                        fill="none"
                    />
                </g>
                <g transform="translate(-8405.843 17308.158)">
                    <circle
                        cx="86.5"
                        cy="86.5"
                        r="86.5"
                        transform="translate(-0.157 -0.43)"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                        fill="none"
                    />
                </g>
                <g transform="translate(-8373.735 17341.088)">
                    <ellipse
                        cx="54"
                        cy="54.5"
                        rx="54"
                        ry="54.5"
                        transform="translate(-0.265 -0.359)"
                        fill="none"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                    />
                </g>
                <g transform="translate(-8339.982 17375.664)">
                    <ellipse
                        cx="20"
                        cy="19.5"
                        rx="20"
                        ry="19.5"
                        transform="translate(-0.018 0.064)"
                        fill="none"
                        stroke="#e1e1e1"
                        strokeLinecap="round"
                        strokeWidth="5"
                    />
                </g>
            </g>
        </svg>
    );
};

export default FamodaCyrcleRound;
