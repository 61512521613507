import { useWindowWidth } from "@react-hook/window-size";

export const grid = {
    xs: 0,
    es: 362,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xga: 1460,
};

export const grid_down = {
    xs: grid.es - 0.02,
    es: grid.sm - 0.02,
    sm: grid.md - 0.02,
    md: grid.lg - 0.02,
    lg: grid.xl - 0.02,
    xl: grid.xga - 0.02,
};

export const container = {
    xs: 352,
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1140,
    xga: 1400,
};

export const size_down = (max) => useWindowWidth() <= grid_down[max];

export const up = (min) => `@media (min-width: ${grid[min]}px)`;
export const down = (max) => `@media (max-width: ${grid_down[max]}px)`;
export const only = (val) =>
    `@media (max-width: ${grid_down[val]}px) and (min-width: ${grid[val]}px)`;
