import React, { Component } from "react";
import PT from "prop-types";
import styled from "styled-components";

import { colors, global } from "~/src/config/vars";

class FormNotification extends Component {
    static propTypes = {
        children: PT.any.isRequired,
        type: PT.string,
    };

    render() {
        const { type, children } = this.props;
        return (
            <FrmNotification>
                <FrmNotificationText className={type}>
                    {children}
                </FrmNotificationText>
            </FrmNotification>
        );
    }
}

const FrmNotification = styled.div`
    margin-bottom: 1.2em;
`;
const FrmNotificationText = styled.div`
    padding: 0.8em 1.2em;
    font-size: 1.1em;
    border-radius: ${global.border_radius};

    &.info {
        background-color: ${colors.yellow};
        color: ${colors.black};
    }
    &.error {
        background-color: ${colors.yellow};
        color: ${colors.white};
    }
    &.success {
        background-color: ${colors.yellow};
        color: ${colors.white};
    }
`;

export default FormNotification;
