import React from "react";
import { position } from "polished";
import styled from "styled-components";

import { ProjectCard } from "~/src/components";
import StompharmCardLogos from "./StompharmCardLogos";

const StompharmCard = () => {
    return (
        <ProjectCard
            title="StomPharm"
            desc="Stomatological shop"
            href="https://stompharm.kg"
            slug="stompharm"
        >
            <SStompharmCard>
                <StompharmCardLogos />
            </SStompharmCard>
        </ProjectCard>
    );
};

const SStompharmCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #f0f9fc;
`;

export default StompharmCard;
