import React from "react";
import CN from "classnames";
import PT from "prop-types";
import styled from "styled-components";

import { down, global } from "~/src/config/vars";

const HorizontalScroll = ({ children, from }) => {
    return (
        <SHorizontalScroll
            className={CN({
                sm: from === "sm",
                md: from === "md",
            })}
        >
            <Wrapper>{children}</Wrapper>
        </SHorizontalScroll>
    );
};

HorizontalScroll.defaultProps = {
    from: "sm",
};

HorizontalScroll.propTypes = {
    children: PT.any,
    from: PT.oneOf(["sm", "md"]).isRequired,
};

const SHorizontalScroll = styled.div`
    ${down("sm")} {
        width: calc(100% + calc(${global.container_padding} * 2));
        margin: 0 ${global.container_padding_minus};
    }
`;

const Wrapper = styled.div`
    ${SHorizontalScroll}.sm & {
        ${down("sm")} {
            flex-wrap: nowrap;
            overflow-x: auto;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            padding-left: ${global.container_padding};
            &:after {
                content: "";
                display: block;
                flex: 0 0 auto;
                margin-right: 1.5em;
                width: 1px;
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    ${SHorizontalScroll}.md & {
        ${down("md")} {
            flex-wrap: nowrap;
            overflow-x: auto;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            padding-left: ${global.container_padding};
            &:after {
                content: "";
                display: block;
                flex: 0 0 auto;
                margin-right: 1.5em;
                width: 1px;
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
`;

export default HorizontalScroll;
