import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

import ntek_logo from "./card/ntek_logo.svg";

const NtekCard = () => {
    const data = useStaticQuery(graphql`
        query NtekQuery {
            bg: file(name: { eq: "ntek_bg" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
            barrel: file(name: { eq: "ntek_barrel" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 255)
                }
            }
        }
    `);
    const bg = getImage(data.bg);
    const barrel = getImage(data.barrel);
    return (
        <ProjectCard
            title="Ntek"
            desc="Polyurethane foam and building frames"
            slug="ntek"
            href="http://pena.kg"
        >
            <SNtekCard>
                <SBg image={bg} alt="Ntek" />
                <SBarrel image={barrel} alt="Ntek" />
                <SLogo />
            </SNtekCard>
        </ProjectCard>
    );
};

const SNtekCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
`;
const SBg = styled(GatsbyImage)`
    ${position("absolute", 0, 0, 0, 0)};
    width: 100%;
    height: 100%;
`;
const SBarrel = styled(GatsbyImage)`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    width: 132px;
    height: 150px;
    transition: top 0.3s, opacity 0.1s;
    opacity: 1;
    .project_card_hover & {
        top: 60%;
    }
`;
const SLogo = styled.div`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    background-image: url(${ntek_logo});
    background-size: cover;
    width: 104px;
    height: 34px;
    transition: top 0.3s, opacity 0.1s;
    top: -40%;
    opacity: 0;
    .project_card_hover & {
        top: 16%;
        opacity: 1;
    }
`;

export default NtekCard;
