export const contacts = {
    phone: "-",
    phone_spaces: "-",
    email_global: "info@dangercactus.io",
    domain: "dangercactus.io",
    telegram: "https://t.me/yurii_repin",
    instagram: null,
    // twitter: "https://twitter.com/danger_cactus",
    twitter: "",
    youtube: null,
};
