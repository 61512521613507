import { createGlobalStyle } from "styled-components";

export const fonts = {
    ff_global: "var(--ff-global)",
    ff_global_variable: "var(--ff-global_variable)",
    //
    fw_black: "var(--fw-black)",
    fw_bold: "var(--fw-bold)",
    fw_medium: "var(--fw-medium)",
    fw_regular: "var(--fw-regular)",

    fs_14: "var(--fs-14)",
    fs_16: "var(--fs-16)",
    fs_18: "var(--fs-18)",
    fs_20: "var(--fs-20)",
    fs_22: "var(--fs-22)",
    fs_24: "var(--fs-24)",
    fs_28: "var(--fs-28)",
    fs_32: "var(--fs-32)",
    fs_48: "var(--fs-48)",
    fs_64: "var(--fs-64)",
};

export const VarsFontsStyle = createGlobalStyle`
    :root {
        --ff-global: "Codec Pro", sans-serif;
        --ff-global_variable: "Codec Pro Variable", sans-serif;
        /* ------------------ */
        --fw-black: 350;
        --fw-bold: 300;
        --fw-medium: 240;
        --fw-regular: 160;
        /* ------------------ */
        /* base size 19px */
        --fs-14: 0.7em;
        --fs-16: 0.8em;
        --fs-18: 0.9em;
        --fs-20: 1rem;
        --fs-22: 1.1rem;
        --fs-24: 1.2em;
        --fs-28: 1.4em;
        --fs-32: 1.6em;
        --fs-48: 2.4em;
        --fs-64: 3.2em;
    }
`;
