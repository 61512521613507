import React from "react";
import { position } from "polished";
import styled from "styled-components";

import { colors, down } from "~/src/config/vars";

import project from "./images/project.jpg";
import project_tv from "./images/project_tv.png";

const BssMain = () => {
    return (
        <SBssMain>
            <SBg src={project} alr="bss" />
            <SBlock>
                <SScreen>
                    <SCircleList>
                        <SCircle className="one" />
                        <SCircle className="two" />
                        <SCircle className="three" />
                        <SCircle className="four" />
                    </SCircleList>
                    <SSvg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                    >
                        <defs xmlns="http://www.w3.org/2000/svg">
                            <linearGradient
                                x1="50%"
                                y1="0%"
                                x2="50%"
                                y2="100%"
                                id="a"
                            >
                                <stop stopColor="#FCC397" offset="0%" />
                                <stop stopColor="#FC9F95" offset="17.71%" />
                                <stop stopColor="#F77C88" offset="30.048%" />
                                <stop stopColor="#F25192" offset="42.704%" />
                                <stop stopColor="#D34CA3" offset="56.332%" />
                                <stop stopColor="#9A50A5" offset="69.115%" />
                                <stop stopColor="#59569E" offset="83.849%" />
                                <stop stopColor="#39477F" offset="100%" />
                            </linearGradient>
                        </defs>
                        <circle fill="url(#a)" cx="32" cy="32" r="30" />
                        <path
                            fill="#fff"
                            d="M48 32L32 21v6.9L22 21v22l10-6.9V43l16-11z"
                        />
                        <path
                            className="logo_path"
                            fill="none"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            d="M48 32L32 21v6.9L22 21v22l10-6.9V43l16-11z"
                            opacity="1"
                        />
                    </SSvg>
                </SScreen>
            </SBlock>
        </SBssMain>
    );
};

const SBssMain = styled.div`
    position: relative;
`;
const SBg = styled.img`
    width: 100%;
    display: block;
`;

const SBlock = styled.div`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    width: 400px;
    height: 265px;
    background-image: url(${project_tv});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 3;
    ${down("sm")} {
        width: 280px;
        height: 188px;
    }
`;
const SScreen = styled.div`
    width: calc(100% - 10px);
    position: relative;
    left: 6px;
    top: 3px;
    height: 222px;
    overflow: hidden;
    ${down("sm")} {
        width: calc(100% - 6px);
        left: 3px;
        top: 3px;
        height: 155px;
    }
`;
const SCircleList = styled.div`
    opacity: 1;
    transition: all 0.1s;
`;
const SCircle = styled.div`
    margin: 0 auto;
    border: 15px solid ${colors.white};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 0;
    width: 140px;
    height: 140px;
    transition: all 0.1s;
    &.one {
        animation: project_bss_circle infinite 8s linear;
    }
    &.two {
        animation: project_bss_circle infinite 8s linear 2s;
    }
    &.three {
        animation: project_bss_circle infinite 8s linear 4s;
    }
    &.four {
        animation: project_bss_circle infinite 8s linear 6s;
    }
    @keyframes project_bss_circle {
        0% {
            transform: translateY(-50%) translateX(-50%) scale(1);
            opacity: 0;
        }
        50% {
            transform: translateY(-50%) translateX(-50%) scale(3);
        }
        100% {
            transform: translateY(-50%) translateX(-50%) scale(6);
            opacity: 0.6;
        }
    }
`;
const SSvg = styled.svg`
    width: 5.5em;
    height: 5.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: all 0.1s;
    ${down("sm")} {
        width: 3.5em;
        height: 3.5em;
    }
    .logo_path {
        fill: transparent;
        stroke: #7223ff;
        stroke-dasharray: 0;
        stroke-width: 2px;
        opacity: 0.4;
        animation: project_bss_groove forwards 6s 3000 alternate;
    }
    @keyframes project_bss_groove {
        0%,
        100% {
            stroke-dasharray: 98;
        }
        25%,
        75% {
            stroke-dasharray: 20;
        }
        50% {
            stroke-dasharray: 40;
        }
    }
`;

export default BssMain;
