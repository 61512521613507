import React from "react";

const ArrowLeftIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.998 22.018">
            <path
                fill="currentColor"
                d="M11.957,13.988a1,1,0,0,0-.676.32L1.016,25,11.281,35.691a1,1,0,0,0,1.684-.41.982.982,0,0,0-.246-.973L4.746,26H48a1,1,0,1,0,0-2H4.746l7.973-8.309a.987.987,0,0,0,.2-1.109A1,1,0,0,0,11.957,13.988Z"
                transform="translate(-1.016 -13.987)"
            />
        </svg>
    );
};

export default ArrowLeftIcon;
