import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { colors, down } from "~/src/config/vars";

const HeaderLogo = () => {
    return (
        <SHeaderLogo>
            <Link to="/">
                <SSvg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 941.772 357.147"
                >
                    <g id="logo" transform="translate(-474.444 -362.642)">
                        <g id="bottom">
                            <path
                                id="bottom_bg"
                                d="M0,.276c1.225-.764,931.846-7.947,933-6.73s4.35,172.472,0,174.986S-3.248,180.564-5.965,177-1.225,1.04,0,.276Z"
                                transform="translate(481 542)"
                                fill="#ed253f"
                            />
                            <path
                                d="M826.462,167.012c.667,1.81,52.1,27.007,70.027,27.007,44.693,0,75.046-22.466,75.046-60.228,0-32.265-23.661-43.737-49-50.19-12.428-3.107-23.422-4.3-23.422-13.623,0-7.409,7.887-9.8,15.535-9.8,10.516,0,28.858,10.629,30.831,10.038s20.495-33.993,19.359-36.806-26.529-16.73-60.706-16.73c-47.561,0-68.593,28.68-68.593,58.316,0,37.045,35.372,45.41,49.473,49.234,11.95,3.107,22.7,5.5,22.7,15.057,0,4.063-2.39,9.56-14.1,9.56-14.579,0-39.4-19.448-41.825-19.12S825.8,165.2,826.462,167.012Z"
                                transform="translate(442 525)"
                                fill="#fff"
                            />
                            <path
                                d="M642.91,21.7c-1.676,2.251,0,96.556,0,96.556,0,18.881,4.541,75.763,85.323,75.763s85.323-56.882,85.323-75.763c0,0,1.366-95.018,0-96.556s-64.849-1.956-66.92,0,0,98.707,0,98.707c0,8.126-1.434,25.1-18.4,25.1s-18.4-16.969-18.4-25.1c0,0,1.813-96.748,0-98.707S644.586,19.449,642.91,21.7Z"
                                transform="translate(455 525)"
                                fill="#fff"
                            />
                            <path
                                d="M527.951,189c1.129,1.975,65.5,1.973,66.92,0s-.627-117.23,0-118.783,34.094,1.13,35.133,0,1.486-47.546,0-48.517-134.85-1.1-137.186,0-1.266,46.745,0,48.517,35.133,0,35.133,0S526.822,187.025,527.951,189Z"
                                transform="translate(458 525)"
                                fill="#fff"
                            />
                            <path
                                d="M342.965,189Zm141.966-63.813c-1.432-.105-22.466,15.3-37.284,15.3-17.208,0-37.045-8.365-37.045-33.46,0-15.3,7.409-36.806,37.284-36.806,11.711,0,35.775,18.244,37.045,17.686s.772-56.639,0-58.555-32.265-12.667-45.888-12.667c-42.542,0-92.254,27.963-92.254,88.669,0,53.536,38.718,88.669,93.21,88.669,20.076,0,44.049-9.078,44.932-10.277S486.363,125.292,484.931,125.187Z"
                                transform="translate(456 525)"
                                fill="#fff"
                            />
                            <path
                                d="M150.331,189c.886,2.1,67.117,1.543,68.593,0s5.466-23.366,6.692-24.378,40.176-1.787,42.064,0,4.888,23.4,6.692,24.378,67.249,2.6,68.593,0S285.215,23.076,283.454,21.7s-71.78-1.571-73.612,0S149.445,186.9,150.331,189ZM246.648,80.255c1.631.165,12.84,41.924,11.711,42.542s-22.535,1.785-23.422,0S245.017,80.09,246.648,80.255Z"
                                transform="translate(475 525)"
                                fill="#fff"
                            />
                            <path
                                d="M145.79,125.187c-1.87-.362-22.466,15.3-37.284,15.3-17.208,0-37.045-8.365-37.045-33.46,0-15.3,7.409-36.806,37.284-36.806,11.711,0,35.534,18.43,37.045,17.686s2.206-55.678,0-58.555S113.525,16.681,99.9,16.681c-42.542,0-92.254,27.963-92.254,88.669,0,53.536,38.718,88.669,93.21,88.669,20.076,0,43.755-8.344,44.932-10.277S147.66,125.549,145.79,125.187Z"
                                transform="translate(473 525)"
                                fill="#fff"
                            />
                        </g>
                        <g id="needles_out">
                            <path
                                d="M18123.947,602.318c-.191.524,10.246,8.122,10.766,8.054s4.947-10.968,4.51-11.275S18124.139,601.794,18123.947,602.318Z"
                                transform="translate(17939.967 5563.584) rotate(-166)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle"
                                d="M18123.945,602.508c-.234.555,12.754,8.6,13.4,8.525s6.152-11.61,5.609-11.935S18124.188,601.954,18123.945,602.508Z"
                                transform="matrix(0.848, 0.53, -0.53, 0.848, -14498.315, -9524.209)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-2"
                                d="M18123.947,603.966c-.242.79,13,12.248,13.656,12.145s6.271-16.539,5.719-17S18124.2,603.176,18123.947,603.966Z"
                                transform="matrix(-0.559, 0.829, -0.829, -0.559, 11151.128, -14003.546)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-3"
                                d="M18123.949,604.242c-.25.835,13.375,12.938,14.055,12.829s6.457-17.471,5.887-17.961S18124.2,603.407,18123.949,604.242Z"
                                transform="matrix(-0.259, 0.966, -0.966, -0.259, 5894.934, -16687.709)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-4"
                                d="M18123.949,601.992c-.367.471,19.668,7.3,20.668,7.244s9.492-9.865,8.656-10.141S18124.32,601.521,18123.949,601.992Z"
                                transform="matrix(-0.629, -0.777, 0.777, -0.629, 11553.506, 15030.901)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-5"
                                d="M18123.947,605.026c-.152.961,8.285,14.9,8.707,14.778s4-20.125,3.648-20.689S18124.107,604.065,18123.947,605.026Z"
                                transform="matrix(-0.259, 0.966, -0.966, -0.259, 5973.188, -16651.23)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-6"
                                d="M18123.947,604.362c-.254.854,13.689,13.24,14.383,13.129s6.607-17.879,6.023-18.38S18124.205,603.508,18123.947,604.362Z"
                                transform="matrix(-0.259, 0.966, -0.966, -0.259, 6327.934, -16740.709)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-7"
                                d="M18123.949,604.047c-.238.8,12.871,12.45,13.523,12.346s6.215-16.813,5.664-17.284S18124.191,603.244,18123.949,604.047Z"
                                transform="translate(-6816.7 -15775.412) rotate(63)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-8"
                                d="M18123.949,604.1c-.242.812,13.012,12.584,13.668,12.478s6.281-16.993,5.727-17.47S18124.2,603.289,18123.949,604.1Z"
                                transform="translate(-6294.903 -15877.7) rotate(63)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-9"
                                d="M18123.949,604.763c-.273.919,14.727,14.243,15.473,14.124s7.109-19.234,6.48-19.773S18124.227,603.844,18123.949,604.763Z"
                                transform="translate(-6383.98 -15774.345) rotate(63)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-10"
                                d="M18123.947,607.351c-.256,1.337,13.777,20.724,14.48,20.551s6.648-27.987,6.064-28.771S18124.207,606.014,18123.947,607.351Z"
                                transform="translate(-16448.836 -3514.093) rotate(11)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-11"
                                d="M18123.949,606.8c-.129,1.248,6.977,19.34,7.336,19.178s3.367-26.117,3.07-26.849S18124.078,605.551,18123.949,606.8Z"
                                transform="translate(-16604.789 -3508.194) rotate(11)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-12"
                                d="M18123.953,605.078c-.27.97,14.668,15.032,15.428,14.906.734-.126,7.076-20.3,6.453-20.868S18124.223,604.108,18123.953,605.078Z"
                                transform="translate(13471.788 13591.825) rotate(-136)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-13"
                                d="M18123.953,604.463c-.143.87,7.705,13.492,8.1,13.379s3.717-18.22,3.391-18.73S18124.094,603.593,18123.953,604.463Z"
                                transform="matrix(0.616, 0.788, -0.788, 0.616, -9800.215, -14061.884)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-14"
                                d="M18123.953,608.04c-.236,1.448,12.82,22.448,13.484,22.26.643-.188,6.184-30.315,5.641-31.164S18124.188,606.592,18123.953,608.04Z"
                                transform="matrix(0.616, 0.788, -0.788, 0.616, -9693.397, -13985.554)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-15"
                                d="M18123.957,605.463c-.191,1.032,10.219,16,10.746,15.862.512-.134,4.93-21.6,4.5-22.206S18124.141,604.431,18123.957,605.463Z"
                                transform="matrix(-0.616, 0.788, -0.788, -0.616, 12765.265, -13214.276)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-16"
                                d="M18123.949,603.6c-.227.732,12.031,11.34,12.641,11.245s5.809-15.313,5.3-15.742S18124.176,602.872,18123.949,603.6Z"
                                transform="translate(18691.096 2756.741) rotate(-175)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-17"
                                d="M18123.949,604.347c-.262.852,14.008,13.2,14.719,13.091s6.762-17.828,6.164-18.328S18124.215,603.5,18123.949,604.347Z"
                                transform="translate(-16028.636 7449.557) rotate(-24)"
                                fill="#ed253f"
                            />
                            <path
                                id="needle-18"
                                d="M18123.957,604.624c-.516.9,27.9,13.9,29.348,13.779,1.4-.116,13.459-18.765,12.275-19.29C18164.381,598.587,18124.465,603.728,18123.957,604.624Z"
                                transform="matrix(0.999, -0.052, 0.052, 0.999, -16990.805, 983.344)"
                                fill="#ed253f"
                            />
                        </g>
                        <g id="top">
                            <path
                                d="M932.781-23.312c1.288,1.828,55.848,1.259,57.609,0s-.929-44.153,0-44.647,17.636,43.54,18.929,44.647,65.485.97,66.044,0-36.876-54.252-36.417-56.169,27.364-19.546,27.364-40.738c0-38.886-33.125-47.116-53.7-47.116,0,0-78.228-2.314-79.829,0S931.493-25.14,932.781-23.312ZM990.39-129.888c.988-.48,5.761,0,5.761,0,5.349,0,15.225.823,15.225,13.579,0,12.962-10.082,13.785-15.225,13.785,0,0-5.284.558-5.761,0S989.4-129.408,990.39-129.888Z"
                                transform="translate(338.166 534.309)"
                                fill="#fff"
                            />
                            <path
                                d="M792.719-23.318c1.677,1.876,100.816,0,100.816,0s1.079-40.843,0-41.766-42.847.274-43.207,0-.386-13.555,0-13.785,37.948.623,38.886,0,.972-33.508,0-34.565-38.073.866-38.886,0-.7-11.808,0-12.139,42.4.591,43.207,0,1.344-40.9,0-41.766-98.982-2.264-100.816,0S791.043-25.194,792.719-23.318Z"
                                transform="translate(357.663 534.316)"
                                fill="#fff"
                            />
                            <path
                                d="M660.7-110.39c-.766,3.047-.766,30.995,0,33.125s29.539-.833,30.245,0-9.464,17.283-23.249,17.283a35.312,35.312,0,0,1-35.568-34.977c0-21.809,16.845-35.594,35.774-35.594,10.493,0,25.941,12.129,27.853,11.88s33.269-18.887,33.152-22.675-24.332-31.641-63.063-30.971-84.356,38.886-84.356,77.36c0,44.03,31.685,75.3,84.973,75.3,51.437,0,79.212-30.45,79.212-73.451,0,0,.533-15.817,0-17.283S661.465-113.437,660.7-110.39Z"
                                transform="translate(387.015 534.974)"
                                fill="#fff"
                            />
                            <path
                                d="M390.145-23.372c7.217,7.073,54.728,0,54.728,0s-2.414-68.533,0-69.131,34.944,66.328,38.886,69.131,50.671,4.18,53.288,0,2.268-138.83,0-144.022-52.43-2.14-54.728,0,2.6,67.855,0,67.69-34.584-65.7-38.063-67.69-50.139-4.872-54.111,0S382.929-30.445,390.145-23.372Z"
                                transform="translate(413.76 534.37)"
                                fill="#fff"
                            />
                            <path
                                d="M186.414-23.447c3.293,5.537,56.615,1.853,59.049,0s4.4-20,5.761-20.986,35.353-.379,36.211,0,3.4,19,5.761,20.986,56.329,4.995,59.049,0-47.059-140.793-51.231-144.022-60.019-1.341-63.37,0S183.121-28.985,186.414-23.447Zm82.916-93.614c2.117.07,11.222,34.7,10.082,36.623s-19.639,2.157-20.163,0S267.213-117.131,269.33-117.062Z"
                                transform="translate(441.989 534.445)"
                                fill="#fff"
                            />
                            <path
                                d="M14.884-23.335c8.183-.11,67.073,0,67.073,0,53.905,0,77.155-33.331,77.155-72.011,0-32.3-15.431-72.011-77.155-72.011,0,0-61.381-3.885-67.073,0S6.7-23.225,14.884-23.335ZM72.493-65.1c-1.244-.392-.449-60.044,0-60.489s4.526,0,4.526,0c16.048,0,27.158,9.464,27.158,30.245,0,28.6-20.369,30.245-27.158,30.245A16.77,16.77,0,0,1,72.493-65.1Z"
                                transform="translate(466 534.332)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </SSvg>
            </Link>
        </SHeaderLogo>
    );
};

const SHeaderLogo = styled.div`
    position: relative;
    z-index: 2;
    a {
        transition: all 0;
        color: ${colors.white};
        &.active {
            cursor: default;
        }
    }
`;
const SSvg = styled.svg`
    width: 6.2em;
    ${down("sm")} {
        width: 5.4em;
    }
`;

export default HeaderLogo;
