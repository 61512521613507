import { createGlobalStyle } from "styled-components";

import { fonts, colors } from "~/src/config/vars";

const FormStyle = createGlobalStyle`
    .SFormFieldInput {
        border-width: 2px;
        border-style: solid;
        border-radius: 0;
        appearance: none;
        box-shadow: none;
        height: 58px;
        overflow: hidden;
        position: relative;
        font-size: ${fonts.fs_14};
        padding: 0 3em 0 19px;
        display: block;
        width: 100%;
        background-color: transparent;
        color: ${colors.white};
        border-color: #5a5959;
        font-family: ${fonts.ff_global};
        @supports (font-variation-settings: normal) {
            font-family: ${fonts.ff_global_variable};
        }
        &:hover,
        &:focus {
            border-color: ${colors.grey};
        }
        &.SFormFieldInputPhone {
            padding-left: 66px;
        }
    }
`;

export default FormStyle;
