import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

import dental_logo from "./card/dental_logo.svg";

const DentalShopCard = () => {
    const data = useStaticQuery(graphql`
        query DentalShopQuery {
            boer: file(name: { eq: "dental_boer" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 370)
                }
            }
        }
    `);
    const bg = getImage(data.boer);
    //
    return (
        <ProjectCard
            title="Dental shop"
            desc="Dental products for clinics"
            slug="dental"
            href="https://dental-shop.kg"
        >
            <SDentalShopCard>
                <SBoer image={bg} alt="Dental shop" />
                <SLogo />
            </SDentalShopCard>
        </ProjectCard>
    );
};

const SDentalShopCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to bottom,
        rgba(183, 247, 233, 1) 0%,
        rgba(0, 93, 96, 1) 100%
    );
`;
const SBoer = styled(GatsbyImage)`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    width: 230px;
    height: 65px;
    transition: top 0.5s, opacity 0.1s;
    opacity: 1;
    .project_card_hover & {
        top: -30%;
        opacity: 0;
    }
`;
const SLogo = styled.div`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    background-image: url(${dental_logo});
    background-size: cover;
    width: 95px;
    height: 95px;
    transition: top 0.5s, opacity 0.1s;
    top: -40%;
    opacity: 0;
    .project_card_hover & {
        top: 40%;
        opacity: 1;
    }
`;

export default DentalShopCard;
