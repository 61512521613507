import { PureComponent } from "react";
import PT from "prop-types";

export default class Above extends PureComponent {
    constructor() {
        super();
        this.state = { screenWidth: null };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    static propTypes = {
        children: PT.any.isRequired,
        from: PT.number.isRequired,
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
        this.updateWindowDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
        this.updateWindowDimensions();
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }
    render() {
        const { screenWidth } = this.state;
        const { from, children } = this.props;
        return screenWidth >= from && children;
    }
}
