import React from "react";

const ArrowRightIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.001 22.014">
            <path
                fill="currentColor"
                d="M38.035,13.988a1.013,1.013,0,0,0-.941.605,1,1,0,0,0,.207,1.1L45.277,24H2.023a1,1,0,1,0,0,2H45.277L37.3,34.309a1,1,0,1,0,1.445,1.383L49.012,25,38.746,14.309A1,1,0,0,0,38.035,13.988Z"
                transform="translate(-1.011 -13.988)"
            />
        </svg>
    );
};

export default ArrowRightIcon;
