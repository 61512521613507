import React, { useState } from "react";
import PT from "prop-types";
import styled from "styled-components";
import { position } from "polished";

import { fonts, down } from "~/src/config/vars";

const FigmaChangeDevice = ({ link_desktop, link_mobile }) => {
    const [device, setDevice] = useState("desktop");

    const changeDeviceDesktop = () => {
        setDevice("desktop");
    };
    const changeDeviceMobile = () => {
        setDevice("mobile");
    };

    return (
        <SFigmaChangeDevice>
            <SIframeWrap className={device === "mobile" && "mobile"}>
                {link_desktop && device === "desktop" ? (
                    <SIframe src={link_desktop} allowFullScreen />
                ) : null}
                {link_mobile && device === "mobile" ? (
                    <SIframe src={link_mobile} allowFullScreen />
                ) : null}
            </SIframeWrap>
            <SToggle>
                {link_desktop && (
                    <SButton
                        onClick={changeDeviceDesktop}
                        className={device === "desktop" && "active"}
                    >
                        <svg
                            viewBox="0 0 30 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M29 1H1V19H29V1Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinejoin="bevel"
                            />
                            <path
                                d="M17.5 16H12.5C12.2239 16 12 16.2239 12 16.5C12 16.7761 12.2239 17 12.5 17H17.5C17.7761 17 18 16.7761 18 16.5C18 16.2239 17.7761 16 17.5 16Z"
                                fill="currentColor"
                            />
                        </svg>

                        {/* <span>Desktop</span> */}
                    </SButton>
                )}
                {link_mobile && (
                    <SButton
                        onClick={changeDeviceMobile}
                        className={device === "mobile" && "active"}
                    >
                        <svg
                            viewBox="0 0 15 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13 1H2V19H13V1Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinejoin="bevel"
                            />
                            <path
                                d="M8.75 3H6.25C6.11193 3 6 3.22386 6 3.5C6 3.77614 6.11193 4 6.25 4H8.75C8.88807 4 9 3.77614 9 3.5C9 3.22386 8.88807 3 8.75 3Z"
                                fill="currentColor"
                            />
                            <path
                                d="M8.2002 16.1504C8.2002 15.7362 7.86441 15.4004 7.4502 15.4004C7.03598 15.4004 6.7002 15.7362 6.7002 16.1504C6.7002 16.5646 7.03598 16.9004 7.4502 16.9004C7.86441 16.9004 8.2002 16.5646 8.2002 16.1504Z"
                                fill="currentColor"
                            />
                        </svg>

                        {/* <span>Mobile</span> */}
                    </SButton>
                )}
            </SToggle>
        </SFigmaChangeDevice>
    );
};

FigmaChangeDevice.propTypes = {
    link_desktop: PT.string,
    link_mobile: PT.string,
};

const SFigmaChangeDevice = styled.div`
    position: relative;
`;

const SIframeWrap = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 58%;
    z-index: 1;
    &.mobile {
        ${down("sm")} {
            padding-top: 0;
            height: 65vh;
        }
    }
`;
const SIframe = styled.iframe`
    ${position("absolute", "0", "0", "0", "0")};
    width: 101%;
    height: 100%;
    display: block;
`;
const SToggle = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    position: absolute;
    right: 0;
    bottom: 0;
    gap: 0;
    padding: 1em;
    ${down("sm")} {
        padding: 0.6em;
    }
    z-index: 2;
`;
const SButton = styled.button`
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: ${fonts.fs_20};
    border: 0;
    background-color: #e7e7e7;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: ${fonts.fw_medium};
    padding: 0.4em 0.6em;
    color: #292929;
    margin: 0;
    ${down("sm")} {
        padding: 0.4em 0.4em;
    }
    &:hover {
        background-color: #d4d4d4;
    }
    &.active {
        background-color: #ff714f;
        color: #fff;
        cursor: default;
    }
    svg {
        height: 1.4em;
        ${down("sm")} {
            height: 1.2em;
        }
    }
    &:first-child {
        border-radius: 5px 0 0 5px;
    }
    &:last-child {
        border-radius: 0 5px 5px 0;
    }
`;

export default FigmaChangeDevice;
