import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";
import FamodaCyrcleRound from "./card/FamodaCyrcleRound";

import famoda_text_fabrika from "./card/famoda_text_fabrika.svg";
import famoda_text_moda from "./card/famoda_text_moda.svg";

const FamodaCard = () => {
    const data = useStaticQuery(graphql`
        query FamodaQuery {
            bg: file(name: { eq: "famoda_bg" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 370)
                }
            }
        }
    `);
    const bg = getImage(data.bg);
    //
    return (
        <ProjectCard
            title="Fabrika Moda"
            desc="Clothing factory catalog"
            slug="famoda"
        >
            <SFamodaCard id="SFamodaCard">
                <SBg image={bg} alt="Fabrika Moda" />
                <SDotted>
                    <FamodaCyrcleRound />
                </SDotted>
                <SLogo>
                    <ShapeBlock>
                        <Shape
                            id="famoda_shape"
                            viewBox="0 0 135 132"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M30.1001 43.535C17.5441 81.83 -13.414 67.9499 6.45801 110.766C26.33 153.582 112.301 119.492 131.418 80.6087C150.535 41.7257 78.6281 -5.29098 58.2671 1.03402C37.9061 7.35901 42.6521 5.23999 30.1001 43.535Z"
                                fillRule="nonzero"
                                fill="#ffd933"
                            >
                                <animate
                                    dur="12s"
                                    repeatCount="1"
                                    attributeName="d"
                                    values="M36.766 1.08291C5.23399 10.4659 -9.59401 68.2697 5.78699 96.8827C21.168 125.496 56.644 141.683 97.611 121.883C143.722 97.8827 142.274 41.1107 115.519 36.7997C67.9279 28.1857 68.3 -8.30009 36.766 1.08291Z;M45.7 4.55426C15.853 11.2713 -16.627 73.5351 9.67605 87.7261C35.979 101.917 12.795 93.6519 67.533 125.153C122.271 156.654 140.399 42.8112 119.204 18.7452C98.0091 -5.32082 75.552 -2.16274 45.7 4.55426Z;M22.6 3.04785C-7.26198 9.76885 -7.55499 86.8191 22.6 118.393C52.755 149.967 116.377 111.631 124.017 101.287C131.657 90.9431 133.144 50.5961 107.79 26.0361C82.436 1.47613 52.459 -3.67315 22.6 3.04785Z;M27.8259 22.013C-22.6601 63.487 5.97193 84.5016 39.2309 116.924C72.4899 149.346 123.813 114.81 128.32 108.948C132.827 103.086 140.683 54.4158 115.41 29.9348C90.1369 5.45385 78.3119 -19.461 27.8259 22.013Z;M17.5851 20.1128C-25.1469 63.9418 20.2051 131.529 45.0951 130.613C69.9851 129.697 118.848 118.299 131.948 86.4658C145.048 54.6328 106.207 58.956 95.7272 36.686C85.2472 14.416 60.3171 -23.7142 17.5851 20.1128Z;M30.1001 43.535C17.5441 81.83 -13.414 67.9499 6.45801 110.766C26.33 153.582 112.301 119.492 131.418 80.6087C150.535 41.7257 78.6281 -5.29098 58.2671 1.03402C37.9061 7.35901 42.6521 5.23999 30.1001 43.535Z;M36.766 1.08291C5.23399 10.4659 -9.59401 68.2697 5.78699 96.8827C21.168 125.496 56.644 141.683 97.611 121.883C143.722 97.8827 142.274 41.1107 115.519 36.7997C67.9279 28.1857 68.3 -8.30009 36.766 1.08291Z;"
                                    fill="freeze"
                                    calcMode="spline"
                                    keySplines="0.4 0 0.2 1; 0.4 0 0.2 1; 0.4 0 0.2 1; 0.4 0 0.2 1; 0.4 0 0.2 1; 0.4 0 0.2 1"
                                    begin="famoda_shape.mouseover"
                                    restart="whenNotActive"
                                />
                            </path>
                        </Shape>
                    </ShapeBlock>
                    <TitleFull>
                        <TitleFullTop>
                            <img src={famoda_text_fabrika} alt="famoda" />
                        </TitleFullTop>
                        <TitleFullBottom>
                            <img src={famoda_text_moda} alt="famoda" />
                        </TitleFullBottom>
                    </TitleFull>
                </SLogo>
            </SFamodaCard>
        </ProjectCard>
    );
};

const SFamodaCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SBg = styled(GatsbyImage)`
    ${position("absolute", 0, 9, 9, 0)};
    width: 100%;
    height: 100%;
`;
const SDotted = styled.svg`
    ${position("absolute", "-70%", "-47%", null, null)};
    width: 22em;
    height: 22em;
    svg {
    }
    circle,
    ellipse {
        stroke-dasharray: 26 26;
        animation: circle_ellipse 50s linear infinite;
        stroke-dashoffset: 10px;
        animation-play-state: paused;
        .project_card_hover & {
            animation-play-state: running;
        }
    }
    @keyframes circle_ellipse {
        0% {
            stroke-dashoffset: 10px;
        }
        100% {
            stroke-dashoffset: 5000px;
        }
    }
`;

///////////////////////////
///////////////////////////
///////////////////////////

const SLogo = styled.div`
    position: relative;
    color: #0b0b0b;
    overflow: hidden;
    display: block;
    overflow: hidden;
    display: flex;
    align-items: center;
    min-height: 72px;
`;

const ShapeBlock = styled.div`
    ${position("absolute", "50%", "0", null, null)};
    transform: translateY(-50%);
`;
const Shape = styled.svg`
    transition: all 0.1s;
    width: 4.4em;
    height: 4.4em;
`;

const TitleFull = styled.div`
    position: relative;
    z-index: 2;
    font-size: 1.6em;
    line-height: 1.2;
    transition: all 0.2s;
    text-align: right;
    padding: 0.5em 1.1em 0.5em 0;
    pointer-events: none;
    img {
        width: auto;
        display: block;
        height: 20px;
        margin: 0.4em 0 0.4em auto;
    }
`;
const TitleFullTop = styled.div`
    padding-right: 0.5em;
`;

const TitleFullBottom = styled.div`
    transition: all 0.2s;
`;

export default FamodaCard;
