import React from "react";
import CN from "classnames";
import PT from "prop-types";
import styled from "styled-components";

import { down, fonts } from "~/src/config/vars";

const Headline = ({ children, text, level = 1 }) => {
    return (
        <SHeadline
            className={CN(`SHeadline`, {
                level1: level === 1,
                level2: level === 2,
                level3: level === 3,
                level4: level === 4,
            })}
        >
            {level === 1 && <SH1>{children}</SH1>}
            {level === 2 && <SH2>{children}</SH2>}
            {level === 3 && <SH3>{children}</SH3>}
            {level === 4 && <SH4>{children}</SH4>}
            {text && <SText>{text}</SText>}
        </SHeadline>
    );
};

Headline.propTypes = {
    level: PT.oneOf([1, 2, 3, 4]),
    children: PT.any,
    text: PT.string,
};

const SHeadline = styled.div`
    margin: 0 0 0.4em;
    &.level1 {
        font-size: 2em;
        ${down("sm")} {
            font-size: 1.6em;
        }
    }
    &.level2 {
        font-size: 1.5em;
        ${down("sm")} {
            font-size: 1.4em;
        }
    }
    &.level3 {
        font-size: 1.2em;
    }
    &.level4 {
        font-size: 1.2em;
    }
    ${down("md")} {
    }
`;

const DefHeadline = {
    margin: "0",
    fontFamily: fonts.ff_global,
    fontWeight: fonts.fw_black,
    fontSize: "1em",
};

const SH1 = styled.h1`
    ${DefHeadline};
    @supports (font-variation-settings: normal) {
        font-family: ${fonts.ff_global_variable};
    }
`;
const SH2 = styled.h2`
    ${DefHeadline};
    @supports (font-variation-settings: normal) {
        font-family: ${fonts.ff_global_variable};
    }
`;
const SH3 = styled.h3`
    ${DefHeadline};
    @supports (font-variation-settings: normal) {
        font-family: ${fonts.ff_global_variable};
    }
`;
const SH4 = styled.h4`
    ${DefHeadline};
    @supports (font-variation-settings: normal) {
        font-family: ${fonts.ff_global_variable};
    }
`;

const SText = styled.span`
    margin-top: 0.8em;
`;

export default Headline;
