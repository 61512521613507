import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { position } from "polished";
import { useSpring, to } from "react-spring";

import { down, fonts } from "~/src/config/vars";
import { ProjectCard } from "~/src/components";
import CoursiveCardCharacter from "./CoursiveCardCharacter";

const CoursiveCard = () => {
    const [characterBg, setCharacterBg] = useState(null);

    const [{ st, xy }, set] = useSpring(() => ({ st: 0, xy: [0, 0] }));
    const interpBg = xy.to(
        (x, y) =>
            `perspective(400px) rotateY(${x / 60}deg) rotateX(${
                -y / 60
            }deg) translate3d(0, -60%, 0)`
    );
    const interpEye = to(
        [st, xy],
        (o, xy) => `translate(${xy[0] / 20},${xy[1] / 20})`
    );
    const onMove = useCallback(
        ({ clientX: x, clientY: y }) =>
            set({
                xy: [x - window.innerWidth / 2, y - window.innerHeight / 2],
            }),
        []
    );

    return (
        <ProjectCard
            title="Coursive"
            desc="Educational courses"
            href="https://coursive.id"
            slug="coursive"
        >
            <SCoursiveCard onMouseMove={onMove}>
                <SSocial
                    onMouseLeave={() => {
                        setCharacterBg(null);
                    }}
                >
                    <SSocialItem
                        onMouseEnter={() => {
                            setCharacterBg("#8EC8F9");
                        }}
                    >
                        #contentmaker
                    </SSocialItem>
                    <SSocialItem
                        onMouseEnter={() => {
                            setCharacterBg("#C4ADFF");
                        }}
                    >
                        #writingarticles
                    </SSocialItem>
                    <SSocialItem
                        onMouseEnter={() => {
                            setCharacterBg("#BEDB7A");
                        }}
                    >
                        #shootvlogs
                    </SSocialItem>
                    <SSocialItem
                        onMouseEnter={() => {
                            setCharacterBg("#FF8568");
                        }}
                    >
                        #beginner
                    </SSocialItem>
                    <SSocialItem
                        onMouseEnter={() => {
                            setCharacterBg("#E67D89");
                        }}
                    >
                        #recordpodcast
                    </SSocialItem>
                </SSocial>
                <CoursiveCardCharacter
                    interpBg={interpBg}
                    interpSpot={interpEye}
                    background={characterBg}
                />
            </SCoursiveCard>
        </ProjectCard>
    );
};

const SCoursiveCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    background-color: #e2daa1;
`;

const SSocial = styled.div`
    padding: 1em;
    ${down("sm")} {
    }
`;
const SSocialItem = styled.div`
    font-size: 0.9em;
    color: #292929;
    border: 2px solid #292929;
    padding: 0.2em 0.5em;
    margin: 0 0.5em 0.5em 0;
    display: inline-block;
    border-radius: 20px;
    font-weight: ${fonts.fw_medium};
    font-variation-settings: "wght" 200;
    ${down("sm")} {
    }
`;

export default CoursiveCard;
