import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

const LcenterCard = () => {
    const data = useStaticQuery(graphql`
        query LcenterQuery {
            bg: file(name: { eq: "lcenter_bg" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 370)
                }
            }
            shadow: file(name: { eq: "lcenter_shadow" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 370)
                }
            }
            logo_shadow: file(
                name: { eq: "lcenter_logo_shadow" }
                ext: { eq: ".png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 349)
                }
            }
            logo: file(name: { eq: "logo_lcenter" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 370)
                }
            }
        }
    `);
    const bg = getImage(data.bg);
    const shadow = getImage(data.shadow);
    const logo_shadow = getImage(data.logo_shadow);
    const logo = getImage(data.logo);
    //
    return (
        <ProjectCard
            title="L-center"
            desc="Logo of the sports complex"
            slug="lcenter"
        >
            <SLcenterCard>
                <SBgHover image={bg} alt="lcenter" />
                <SShadow image={shadow} alt="lcenter" />
                <SLogoShadow image={logo_shadow} alt="lcenter" />
                <SLogo image={logo} alt="lcenter" />
            </SLcenterCard>
        </ProjectCard>
    );
};

const SLcenterCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
`;
const SBgHover = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    transition: opacity 0.2s;
`;
const SShadow = styled(GatsbyImage)`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    width: 290px;
    height: 180px;
    transition: opacity 0.1s;
`;
const SLogoShadow = styled(GatsbyImage)`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    width: 200px;
    height: 62px;
    transition: all 0.1s;
    .project_card_hover & {
        opacity: 0.5;
    }
`;
const SLogo = styled(GatsbyImage)`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    width: 200px;
    height: 62px;
    transition: all 0.1s;
    opacity: 0;
    .project_card_hover & {
        opacity: 1;
    }
`;

export default LcenterCard;
