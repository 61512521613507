import { createGlobalStyle } from "styled-components";

// import { colors } from "~/src/config/vars";

const ReactPerfectScrollbar = createGlobalStyle`
    .ps {
        overflow: hidden !important;
        overflow-anchor: none;
        -ms-overflow-style: none;
        touch-action: auto;
        -ms-touch-action: auto;
    }

    /*
    * Scrollbar rail styles
    */
    .ps__rail-x {
        display: none;
        opacity: 0;
        transition: background-color 0.2s linear, opacity 0.2s linear;
        -webkit-transition: background-color 0.2s linear,
            opacity 0.2s linear;
        height: 15px;
        bottom: 0px;
        position: absolute;
    }

    .ps__rail-y {
        display: none;
        opacity: 0;
        transition: background-color 0.2s linear, opacity 0.2s linear;
        -webkit-transition: background-color 0.2s linear,
            opacity 0.2s linear;
        width: 15px;
        right: 0;
        position: absolute;
    }

    .ps--active-x > .ps__rail-x {
        display: none !important;
    }
    .ps--active-y > .ps__rail-y {
        display: block;
        background-color: transparent;
    }

    .ps:hover > .ps__rail-x,
    .ps:hover > .ps__rail-y,
    .ps--focus > .ps__rail-x,
    .ps--focus > .ps__rail-y,
    .ps--scrolling-x > .ps__rail-x,
    .ps--scrolling-y > .ps__rail-y {
        opacity: 0.6;
    }

    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking {
        opacity: 1;
    }

    /*
    * Scrollbar thumb styles
    */
    .ps__thumb-x {
        background-color: var(--color-bg-link-active);
        transition: background-color 0.2s linear, height 0.2s ease-in-out;
        height: 6px;
        bottom: 0;
        position: absolute;
    }

    .ps__thumb-y {
        background-color: var(--color-bg-link-active);
        transition: background-color 0.2s linear, width 0.2s ease-in-out;
        -webkit-transition: background-color 0.2s linear,
            width 0.2s ease-in-out;
        width: 6px;
        right: 0;
        position: absolute;
    }

    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-x.ps--clicking .ps__thumb-x {
        background-color: var(--color-bg-link-active);
    }

    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
        background-color: var(--color-bg-link-active);
    }

    /* MS supports */
    @supports (-ms-overflow-style: none) {
        .ps {
            overflow: auto !important;
        }
    }

    @media screen and (-ms-high-contrast: active),
        (-ms-high-contrast: none) {
        .ps {
            overflow: auto !important;
        }
    }
    .scrollbar-container {
        position: relative;
        height: 100%;
    }
`;

export default ReactPerfectScrollbar;
