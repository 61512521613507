import React from "react";
import PT from "prop-types";
import CN from "classnames";
import styled from "styled-components";
import { position } from "polished";

import { HelpIcon, FieldErrorIcon } from "~/src/components/icons";
import { colors, down, fonts } from "~/src/config/vars";
import {
    SErrorTextTooltip,
    SHelp,
    STooltip,
    STooltipIcon,
    STooltipContent,
} from "~/src/components/form/form_field_styles";

const BaseFormField = ({
    label,
    help,
    color,
    focus,
    errors,
    id,
    required,
    show_label,
    children,
    className,
}) => {
    return (
        <SFormField
            className={CN(`SFormField ${color} ${className}`, {
                focus: focus,
            })}
        >
            {show_label ? (
                <SLabel
                    htmlFor={id}
                    className={CN({
                        focus,
                        error: errors,
                    })}
                >
                    {label} {required && <SRequired>*</SRequired>}
                </SLabel>
            ) : null}
            <div>
                {children}
                {errors ? (
                    <SErrorTextTooltip>
                        <STooltip>
                            <STooltipIcon>
                                <FieldErrorIcon />
                            </STooltipIcon>
                            <STooltipContent>{errors}</STooltipContent>
                        </STooltip>
                    </SErrorTextTooltip>
                ) : null}
                {help && (
                    <SHelp
                        className={CN({
                            active: focus,
                        })}
                    >
                        <STooltip>
                            <STooltipIcon>
                                <HelpIcon />
                            </STooltipIcon>
                            <STooltipContent>{help}</STooltipContent>
                        </STooltip>
                    </SHelp>
                )}
            </div>
        </SFormField>
    );
};

BaseFormField.defaultProps = {
    color: "default",
    show_label: true,
};

BaseFormField.propTypes = {
    label: PT.string,
    help: PT.string,
    color: PT.oneOf(["default", "white"]),
    focus: PT.bool,
    errors: PT.string,
    id: PT.string,
    required: PT.bool,
    show_label: PT.bool,
    children: PT.any,
    className: PT.string,
};

const SFormField = styled.div`
    position: relative;
    &.white {
        input,
        textarea {
            border: 0;
            background-color: ${colors.white};
            color: ${colors.black};
        }
    }
`;

const SLabel = styled.label`
    ${position("absolute", "18px", null, null, "20px")};
    font-weight: ${fonts.fw_regular};
    font-size: 18px;
    color: ${colors.grey};
    z-index: 1;
    cursor: text;
    transition: 0.18s;
    padding-right: 2.8em;
    ${down("sm")} {
        font-size: 16px;
    }
    &.focus {
        font-size: 13px;
        letter-spacing: 0;
        top: 10px;
        color: ${colors.black_light3};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 3em);
    }
    &.error {
    }
`;
const SRequired = styled.span`
    color: ${colors.red};
`;

export default BaseFormField;
