import React, { useState } from "react";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import {
    FormTextField,
    FormCheckboxField,
    FormNotificationSuccess,
} from "~/src/components/form";
import { checkTime } from "~/src/utils";
import { PhoneIcon } from "~/src/components/icons";
import { Button } from "~/src/components";
import { statuses } from "~/src/config";
import { down, fonts } from "~/src/config/vars";

import { ic } from "~/src/components/icons/lottie/NotificationSuccessWhite";

const initialValues = {
    name: "",
    phone_number: "",
    late_callback: false,
};

const createFormSchema = () =>
    Yup.object().shape({
        name: Yup.string()
            .required("Enter your name")
            .min(2, "At least 2 characters"),
        phone_number: Yup.string()
            .matches(/^\d+$/, "Only numbers are allowed")
            .test(
                "len",
                "The phone number must have at least 8 digits",
                (val) => {
                    if (val) return val.toString().length > 8;
                }
            ),
    });

const CallmeForm = () => {
    const [status, setStatus] = useState("VIRGIN");

    const onSubmit = (data) => {
        const api_token = "394582473:AAHTb9xWMYGNlaKLzUEy7jzW5aCTsDfc-8w";

        const name = `\n🖐️ *Name:* ${data.name}\n`;
        const phone = `📱 *Phone:* [+${data.phone_number}](tel:${data.phone_number})\n`;
        const late_callback = `🕔 *For more than 21:00:* ${
            data.late_callback ? "Yes" : "No"
        }\n`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                chat_id: "-1001475063078",
                text: name + phone + late_callback,
                parse_mode: "markdown",
            }),
        };
        fetch(
            `https://api.telegram.org/bot${api_token}/sendMessage`,
            requestOptions
        ).then((response) => {
            if (response.ok) {
                return response.json(), setStatus(statuses.READY);
            } else {
                setStatus(statuses.ERROR);
            }
        });
    };

    return (
        <>
            {status === "READY" ? (
                <FormNotificationSuccess icon={ic} type="callme" />
            ) : (
                <Formik
                    initialValues={initialValues}
                    validationSchema={createFormSchema()}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, isValid, errors }) => (
                        <Form className="form_callme_start">
                            <STitle>
                                Leave your contacts, and our call will not keep
                                you waiting
                            </STitle>
                            <SField>
                                <Field
                                    component={FormTextField}
                                    id="callme_name"
                                    name="name"
                                    label="Your name"
                                    color="white"
                                    errors={errors.name}
                                    required
                                />
                            </SField>
                            <SField>
                                <Field
                                    component={FormTextField}
                                    id="callme_phone"
                                    name="phone_number"
                                    label="Phone"
                                    color="white"
                                    errors={errors.phone_number}
                                    required
                                    type="tel"
                                />
                            </SField>
                            {checkTime() ? (
                                <SField>
                                    <Field
                                        component={FormCheckboxField}
                                        id="callme_late_callback"
                                        name="late_callback"
                                        label={
                                            "For more than 21:00, do you want us to call you now if possible?"
                                        }
                                        color="white"
                                    />
                                </SField>
                            ) : null}
                            <SSubmit>
                                <Button
                                    onClick={handleSubmit}
                                    color="white"
                                    disabled={!isValid}
                                    loading={false}
                                    className="form_callme_start_submit"
                                >
                                    <PhoneIcon />
                                    Call me back
                                </Button>
                            </SSubmit>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

const STitle = styled.div`
    margin-bottom: 1.5em;
    font-weight: ${fonts.fw_black};
    font-size: 1.1em;
`;

const SField = styled.div`
    margin-bottom: 1em;
    .FormCheckText {
        font-size: 0.8em;
    }
`;
const SSubmit = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    ${down("sm")} {
        display: block;
    }
    .SButton {
        ${down("sm")} {
            margin-top: 1em;
            width: 100%;
        }
    }
`;

export default CallmeForm;
