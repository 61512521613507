/* eslint-disable prettier/prettier */
export const ic = {
    v: "5.3.4",
    fr: 48,
    ip: 0,
    op: 137,
    w: 610,
    h: 540,
    nm: "atten",
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: "Слой 33",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0], y: [1] },
                            o: { x: [0.333], y: [0] },
                            n: ["0_1_0p333_0"],
                            t: 96,
                            s: [3],
                            e: [46],
                        },
                        {
                            i: { x: [0], y: [1] },
                            o: { x: [0.333], y: [0] },
                            n: ["0_1_0p333_0"],
                            t: 121,
                            s: [46],
                            e: [16],
                        },
                        { t: 140 },
                    ],
                    ix: 10,
                },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0, y: 1 },
                            o: { x: 0.333, y: 0 },
                            n: "0_1_0p333_0",
                            t: 96,
                            s: [103.788, 259.927, 0],
                            e: [163.788, 105.943, 0],
                            to: [-1.66666662693024, -55, 0],
                            ti: [-20.6666660308838, 37.6666679382324, 0],
                        },
                        {
                            i: { x: 0, y: 1 },
                            o: { x: 0.333, y: 0 },
                            n: "0_1_0p333_0",
                            t: 121,
                            s: [163.788, 105.943, 0],
                            e: [115.108, 214.89, 0],
                            to: [4.837233543396, -8.81624794006348, 0],
                            ti: [9.38874912261963, -36.2737846374512, 0],
                        },
                        { t: 140 },
                    ],
                    ix: 2,
                },
                a: { a: 0, k: [-237.212, -10.073, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: 0, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        n: "0_1_0p333_0",
                                        t: 118,
                                        s: [
                                            {
                                                i: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                o: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                v: [
                                                    [-162.212, -51.9],
                                                    [-237.212, 31.754],
                                                    [-312.212, -51.9],
                                                ],
                                                c: false,
                                            },
                                        ],
                                        e: [
                                            {
                                                i: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                o: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                v: [
                                                    [-236.122, 46.997],
                                                    [-235.51, 46.314],
                                                    [-234.898, 46.997],
                                                ],
                                                c: false,
                                            },
                                        ],
                                    },
                                    { t: 133 },
                                ],
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: { a: 0, k: [0.929, 0.145, 0.247, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 55, ix: 5 },
                            lc: 2,
                            lj: 2,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Group 1",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
            ],
            ip: 59,
            op: 137,
            st: 63,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: "Слой 32",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: {
                    a: 1,
                    k: [
                        {
                            i: { x: [0], y: [1] },
                            o: { x: [0.333], y: [0] },
                            n: ["0_1_0p333_0"],
                            t: 23,
                            s: [51],
                            e: [104],
                        },
                        { t: 41 },
                    ],
                    ix: 10,
                },
                p: {
                    a: 1,
                    k: [
                        {
                            i: { x: 0, y: 1 },
                            o: { x: 0.333, y: 0 },
                            n: "0_1_0p333_0",
                            t: 23,
                            s: [271.788, 45.827, 0],
                            e: [103.788, 259.927, 0],
                            to: [-36, -41.149974822998, 0],
                            ti: [-24, -126.850028991699, 0],
                        },
                        { t: 41 },
                    ],
                    ix: 2,
                },
                a: { a: 0, k: [-237.212, -10.073, 0], ix: 1 },
                s: { a: 0, k: [-100, -100, 100], ix: 6 },
            },
            ao: 1,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: 0, y: 1 },
                                        o: { x: 0.333, y: 0 },
                                        n: "0_1_0p333_0",
                                        t: 23,
                                        s: [
                                            {
                                                i: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                o: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                v: [
                                                    [-239.268, 32.138],
                                                    [-237.212, 31.754],
                                                    [-239.216, 31.643],
                                                ],
                                                c: false,
                                            },
                                        ],
                                        e: [
                                            {
                                                i: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                o: [
                                                    [0, 0],
                                                    [0, 0],
                                                    [0, 0],
                                                ],
                                                v: [
                                                    [-162.212, -51.9],
                                                    [-237.212, 31.754],
                                                    [-312.212, -51.9],
                                                ],
                                                c: false,
                                            },
                                        ],
                                    },
                                    { t: 45 },
                                ],
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: { a: 0, k: [0.929, 0.145, 0.247, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 55, ix: 5 },
                            lc: 2,
                            lj: 2,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Group 1",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
            ],
            ip: 27,
            op: 101,
            st: 0,
            bm: 0,
        },
        {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: "line",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [341, 270, 0], ix: 2 },
                a: { a: 0, k: [0, 0, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: "gr",
                    it: [
                        {
                            ind: 0,
                            ty: "sh",
                            ix: 1,
                            ks: {
                                a: 0,
                                k: {
                                    i: [
                                        [0, 0],
                                        [0, 0],
                                        [0, -96.218],
                                        [-96.218, 0],
                                        [0, 0],
                                        [0, 132.031],
                                        [132.031, 0],
                                        [0, -132.031],
                                    ],
                                    o: [
                                        [0, 0],
                                        [-96.218, 0],
                                        [0, 96.218],
                                        [0, 0],
                                        [132.031, 0],
                                        [0, -132.031],
                                        [-132.031, 0],
                                        [0, 132.031],
                                    ],
                                    v: [
                                        [214, -111.876],
                                        [0, -110.219],
                                        [-174.219, 64],
                                        [0, 238.219],
                                        [0, 239.063],
                                        [239.062, 0],
                                        [0, -239.063],
                                        [-239.063, 0],
                                    ],
                                    c: false,
                                },
                                ix: 2,
                            },
                            nm: "Path 1",
                            mn: "ADBE Vector Shape - Group",
                            hd: false,
                        },
                        {
                            ty: "st",
                            c: { a: 0, k: [0.929, 0.145, 0.247, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 55, ix: 5 },
                            lc: 2,
                            lj: 2,
                            nm: "Stroke 1",
                            mn: "ADBE Vector Graphic - Stroke",
                            hd: false,
                        },
                        {
                            ty: "tr",
                            p: { a: 0, k: [0, 0], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: "Transform",
                        },
                    ],
                    nm: "Group 1",
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                },
                {
                    ty: "tm",
                    s: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                n: ["0_1_0p333_0"],
                                t: 1,
                                s: [0],
                                e: [78],
                            },
                            {
                                i: { x: [0.32], y: [1] },
                                o: { x: [0.167], y: [0] },
                                n: ["0p32_1_0p167_0"],
                                t: 57,
                                s: [78],
                                e: [78],
                            },
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                n: ["0_1_0p333_0"],
                                t: 121,
                                s: [78],
                                e: [100],
                            },
                            { t: 141 },
                        ],
                        ix: 1,
                    },
                    e: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                n: ["0_1_0p333_0"],
                                t: 0,
                                s: [0],
                                e: [100],
                            },
                            {
                                i: { x: [0.378], y: [1] },
                                o: { x: [0.167], y: [0] },
                                n: ["0p378_1_0p167_0"],
                                t: 46,
                                s: [100],
                                e: [100],
                            },
                            { t: 121 },
                        ],
                        ix: 2,
                    },
                    o: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                n: ["0_1_0p333_0"],
                                t: 96,
                                s: [0],
                                e: [-31],
                            },
                            {
                                i: { x: [0], y: [1] },
                                o: { x: [0.333], y: [0] },
                                n: ["0_1_0p333_0"],
                                t: 121,
                                s: [-31],
                                e: [0],
                            },
                            { t: 141 },
                        ],
                        ix: 3,
                    },
                    m: 1,
                    ix: 2,
                    nm: "Trim Paths 1",
                    mn: "ADBE Vector Filter - Trim",
                    hd: false,
                },
            ],
            ip: 2,
            op: 137,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
};
