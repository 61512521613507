import React from "react";
import PT from "prop-types";
import styled from "styled-components";

const Picture = ({ className, style, src, webp, alt }) => {
    return (
        <SPicture className={className} style={style}>
            <source srcSet={webp} type="image/webp" />
            <source srcSet={src} type="image/gif" />
            <img src={src} alt={alt} />
        </SPicture>
    );
};

Picture.propTypes = {
    className: PT.string,
    style: PT.object,
    src: PT.string,
    webp: PT.string,
    alt: PT.string,
};

const SPicture = styled.picture``;

export default Picture;
