import React from "react";
import PT from "prop-types";
import styled from "styled-components";
import { MDXProvider as Provider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";

const MDXProvider = ({ children }) => (
    <SMDXProvider>
        <Provider>
            <MDXRenderer>{children}</MDXRenderer>
        </Provider>
    </SMDXProvider>
);

MDXProvider.propTypes = {
    children: PT.any,
};

const SMDXProvider = styled.div``;

export default MDXProvider;
