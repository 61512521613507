import React from "react";

const CrossIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <defs>
                <clipPath id="clip-Artboard_1">
                    <rect width="24" height="24" />
                </clipPath>
            </defs>
            <g clipPath="url(#clip-Artboard_1)">
                <path
                    d="M42,20,20,42m22,0L20,20"
                    transform="translate(-18.999 -19)"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
};

export default CrossIcon;
