import React from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";
import { down } from "~/src/config/vars";

import rotang_logo from "./card/rotang_logo.svg";

const RotangCard = () => {
    const data = useStaticQuery(graphql`
        query RotangQuery {
            bg: file(name: { eq: "rotang_card_bg" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);
    const bg = getImage(data.bg);
    const calc = (x, y, width, height) => [
        ((x - width / 2) / width) * 300,
        -((y - height / 2) / height) * 300,
    ];

    const trans1 = (x, y) => `translate3d(${x / 16}px,${y / 16}px,0)`;

    const [props, set] = useSpring(() => ({
        xy: [0, 0],
        config: { mass: 8, tension: 1000, friction: 100 },
    }));
    return (
        <ProjectCard
            title="Rotang"
            desc="Rattan furniture"
            size="big"
            slug="rotang"
            href="http://rotang.kg"
        >
            <SRotangCard
                onMouseMove={(e) => {
                    const { x, y, width, height } =
                        e.currentTarget.getBoundingClientRect();
                    const c = calc(e.clientX - x, e.clientY - y, width, height);
                    set({
                        xy: c,
                    });
                }}
            >
                <SBg image={bg} alt="Rotang" />
                <SLogo style={{ transform: props.xy.interpolate(trans1) }} />
            </SRotangCard>
        </ProjectCard>
    );
};

RotangCard.propTypes = {
    xy: PT.array,
};

const SRotangCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SBg = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    transition: all 0.1s;
    filter: grayscale(100%);
    opacity: 1;
    .project_card_hover & {
        filter: grayscale(0%);
    }
`;
const SLogo = styled(animated.div)`
    background-image: url(${rotang_logo});
    background-size: cover;
    width: 288px;
    height: 64px;
    transition: opacity 0.1s;
    ${down("sm")} {
        width: 250px;
        height: 56px;
    }
`;

export default RotangCard;
