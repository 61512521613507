import React from "react";

import { colors } from "~/src/config/vars";

const TelegramIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
                d="M2.926,13.098l3.085,1.209c0.326,0.128,0.578,0.395,0.688,0.727l1.287,3.918	c0.274,0.835,1.301,1.134,1.98,0.576l2.228-1.829c0.282-0.232,0.692-0.22,0.961,0.028l2.851,2.63	c1.035,0.955,2.716,0.408,2.991-0.973l2.979-14.961c0.186-0.935-0.747-1.698-1.627-1.33l-17.454,7.3	C1.687,10.9,1.706,12.62,2.926,13.098z"
                // opacity=".35"
                fill={colors.red}
            />
            <path
                d="M11.458,16.271l6.99-9.163c0.328-0.43-0.211-0.982-0.648-0.665L6.467,14.645c0.098,0.115,0.184,0.242,0.232,0.389	l1.287,3.918c0.126,0.383,0.413,0.647,0.75,0.773C9.639,19.981,11.458,16.271,11.458,16.271z"
                fill={colors.white}
            />
        </svg>

        // <svg viewBox="0 0 41 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path
        //         d="M2.82999 14.6157C15.398 9.08668 31.662 2.34568 33.907 1.41268C39.796 -1.02932 41.603 -0.561319 40.702 4.84668C40.055 8.73368 38.188 21.6027 36.7 29.6127C35.817 34.3627 33.836 34.9257 30.721 32.8707C29.223 31.8817 21.662 26.8817 20.021 25.7077C18.523 24.6377 16.457 23.3507 19.048 20.8157C19.97 19.9127 26.014 14.1417 30.723 9.64968C31.34 9.05968 30.565 8.09068 29.853 8.56368C23.506 12.7727 14.706 18.6147 13.586 19.3757C11.894 20.5247 10.269 21.0517 7.35199 20.2137C5.14799 19.5807 2.99499 18.8257 2.15699 18.5377C-1.07001 17.4297 -0.304013 15.9947 2.82999 14.6157Z"
        //         fill="currentColor"
        //     />
        // </svg>
    );
};

export default TelegramIcon;
