import PT from "prop-types";
import React from "react";

import useModalManager from "./useModalManager";

const OpenModal = ({ children, name }) => {
    const { openModal } = useModalManager();
    return <div onClick={() => openModal(name)}>{children}</div>;
};

OpenModal.propTypes = {
    name: PT.string.isRequired,
    children: PT.any,
};

export default OpenModal;
