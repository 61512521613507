import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

import { ProjectCard } from "~/src/components";

const TeremokCard = () => {
    const data = useStaticQuery(graphql`
        query TeremokQuery {
            bg: file(name: { eq: "teremok_card" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 370, placeholder: BLURRED)
                }
            }
            bg_hover: file(
                name: { eq: "teremok_card_hover" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 370)
                }
            }
        }
    `);
    return (
        <ProjectCard
            title="Teremok"
            desc="Construction of made of timber"
            image={data.bg}
            image_hover={data.bg_hover}
            slug="teremok"
            href="http://teremok.dangercactus.io"
        >
            <STeremokCard />
        </ProjectCard>
    );
};

const STeremokCard = styled.div``;

export default TeremokCard;
