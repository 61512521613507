import React, { useState, useEffect } from "react";
import { position } from "polished";
import styled from "styled-components";
import Lottie from "react-lottie";
import Observer from "@researchgate/react-intersection-observer";

import { ProjectCard } from "~/src/components";
import { size_down } from "~/src/config/vars";
import background_mask from "./card/background_mask.png";

import animationData from "./card/CharacterAbout.json";

const MediajasaCard = () => {
    const only_mobile = size_down("md");
    const [isStopped, setIsStopped] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (only_mobile) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [only_mobile]);

    const handleIntersection = (event) => {
        if (isMobile) {
            if (event.isIntersecting) {
                setIsStopped(false);
            } else {
                setIsStopped(true);
            }
        }
    };

    const options = {
        onChange: handleIntersection,
        threshold: 1,
    };

    const defaultOptions = {
        loop: true,
        autoplay: false,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <ProjectCard
            title="Mediajasa"
            desc="Financing cool ideas"
            href="https://mediajasa.ideaca.today"
            slug="mediajasa"
        >
            <Observer {...options}>
                <SMediajasaCard
                    onMouseEnter={() => setIsStopped(false)}
                    onMouseLeave={() => setIsStopped(true)}
                    className={!isStopped ? "mediajasa_card_hover" : ""}
                >
                    <SMediajasaCard>
                        <SCharacter>
                            <Lottie
                                options={defaultOptions}
                                isStopped={isStopped}
                                // isPaused={isPaused}
                            />
                        </SCharacter>
                    </SMediajasaCard>
                </SMediajasaCard>
            </Observer>
        </ProjectCard>
    );
};

const SMediajasaCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.2s;
    background-color: #fef3d5;
    &.mediajasa_card_hover,
    .project_card_hover & {
        background-color: #614494;
    }
    &:before {
        content: "";
        position: absolute;
        inset: 0px;
        pointer-events: none;
        -webkit-mask: url(${background_mask}) 0px 0px / 50vw 30vw repeat;
        mask: url(${background_mask}) 0px 0px / 50vw 30vw repeat;
        background-size: 100%;
        z-index: 0;
        background-color: rgb(235, 199, 172);
        .project_card_hover & {
            background-color: #fff;
        }
    }
`;

const SCharacter = styled.div`
    position: absolute;
    bottom: -2em;
    left: 5%;
    width: 90%;
    z-index: 2;
    svg {
        width: 100%;
    }
`;

export default MediajasaCard;
