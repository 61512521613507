import React from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

const BaltikaCard = () => {
    const data = useStaticQuery(graphql`
        query BaltikaQuery {
            bg1: file(name: { eq: "baltika_bg1" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            bg2: file(name: { eq: "baltika_bg2" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            bg3: file(name: { eq: "baltika_bg3" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            shadow: file(name: { eq: "baltika_shadow" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 323)
                }
            }
            bottle: file(name: { eq: "baltika_bottle" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 102)
                }
            }
            hop: file(name: { eq: "baltika_hop" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 90)
                }
            }
        }
    `);
    const bg1 = getImage(data.bg1);
    const bg2 = getImage(data.bg2);
    const bg3 = getImage(data.bg3);
    const shadow = getImage(data.shadow);
    const bottle = getImage(data.bottle);
    const hop = getImage(data.hop);
    //
    const calc = (x, y, width, height) => [
        ((x - width / 2) / width) * 300,
        -((y - height / 2) / height) * 300,
    ];

    const trans1 = (x, y) => `translate3d(${x / 200}px,${y / 16}px,0)`;
    const trans2 = (x, y) => `translate3d(${x / 200}px,${y / 24}px,0)`;
    const trans3 = (x, y) => `translate3d(${x / 200}px,${y / 16}px,0)`;

    const [props, set] = useSpring(() => ({
        xy: [0, 0],
        config: { mass: 8, tension: 1000, friction: 100 },
    }));

    return (
        <ProjectCard
            title="Baltika"
            desc="Promo website of the company"
            slug="baltika"
        >
            <SBaltikaCard
                onMouseMove={(e) => {
                    const { x, y, width, height } =
                        e.currentTarget.getBoundingClientRect();
                    const c = calc(e.clientX - x, e.clientY - y, width, height);
                    set({
                        xy: c,
                    });
                }}
            >
                <SBg1 style={{ transform: props.xy.interpolate(trans1) }}>
                    <GatsbyImage image={bg1} alt="baltika" />
                </SBg1>
                <SBg2 style={{ transform: props.xy.interpolate(trans2) }}>
                    <GatsbyImage image={bg2} alt="baltika" />
                </SBg2>
                <SBg3 style={{ transform: props.xy.interpolate(trans3) }}>
                    <GatsbyImage image={bg3} alt="baltika" />
                </SBg3>

                <SShadow image={shadow} alt="baltika" />
                <SBottle image={bottle} alt="baltika" />
                <SHop image={hop} alt="baltika" />
            </SBaltikaCard>
        </ProjectCard>
    );
};

BaltikaCard.propTypes = {
    xy: PT.array,
};

const SBaltikaCard = styled.div`
    width: 100%;
    height: 100%;
    background-color: #1d1d1d;
`;

const SBg1 = styled(animated.div)`
    ${position("absolute", "-10%", null, null, "-10%")};
    width: 120%;
    height: 120%;
`;
const SBg2 = styled(animated.div)`
    ${position("absolute", "-10%", null, null, "-10%")};
    width: 120%;
    height: 120%;
`;
const SBg3 = styled(animated.div)`
    ${position("absolute", "-10%", null, null, "-10%")};
    width: 120%;
    height: 120%;
`;

const SShadow = styled(GatsbyImage)`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateX(-50%) translateY(-50%);
    width: 176px;
    height: 100%;
`;
const SBottle = styled(GatsbyImage)`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateX(-50%) translateY(-50%);
    width: 40px;
    height: 153px;
`;
const SHop = styled(GatsbyImage)`
    ${position("absolute", "74%", null, null, "53%")};
    width: 53px;
    height: 26px;
`;

export default BaltikaCard;
