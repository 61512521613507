import React, { useState, useEffect } from "react";
import { position } from "polished";
import styled from "styled-components";
import Lottie from "react-lottie";
import Observer from "@researchgate/react-intersection-observer";

import { ProjectCard } from "~/src/components";
import { size_down } from "~/src/config/vars";

import animationData from "./card/lottie.json";

const LionsbyteCard = () => {
    const only_mobile = size_down("md");
    const [isStopped, setIsStopped] = useState(true);
    // const [isPaused, setIsPaused] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (only_mobile) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [only_mobile]);

    const handleIntersection = (event) => {
        if (isMobile) {
            if (event.isIntersecting) {
                setIsStopped(false);
            } else {
                setIsStopped(true);
            }
        }
    };

    const options = {
        onChange: handleIntersection,
        threshold: 1,
    };

    const defaultOptions = {
        renderer: "html",
        prerender: true,
        loop: false,
        autoplay: false,
        animationData: animationData,
    };

    return (
        <ProjectCard
            title="Lion's Byte"
            desc="Developer team in Switzerland"
            href="https://lionsbyte.ch"
            slug="lionsbyte"
        >
            <Observer {...options}>
                <SLionsbyteCard
                    onMouseEnter={() => setIsStopped(false)}
                    onMouseLeave={() => setIsStopped(true)}
                    className={
                        !isStopped ? "lionsbyte_card_hover" : "lionsbyte_card"
                    }
                >
                    <SLionsbyteCard>
                        <SLottie>
                            <Lottie
                                options={defaultOptions}
                                isStopped={isStopped}
                                // isPaused={isPaused}
                            />
                        </SLottie>
                    </SLionsbyteCard>
                </SLionsbyteCard>
            </Observer>
        </ProjectCard>
    );
};

const SLionsbyteCard = styled.div`
    ${position("absolute", "0", "-0", null, null)};
    width: 100%;
    height: 100%;
    background-color: #050720;
`;

const SLottie = styled.div`
    ${position("absolute", "-110%", "-100%", null, null)};
    width: 300%;
    height: 300%;
    transition: all 0.5s ease-in-out;
    .lionsbyte_card_hover & {
        transition: all 2s ease-in-out 1s;
        transform: translateY(20%) translateX(30%);
    }
`;

export default LionsbyteCard;
