import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

import words1 from "./card/words1.png";
import words2 from "./card/words2.png";
import words3 from "./card/words3.png";
import words4 from "./card/words4.png";

const LouviCard = () => {
    const data = useStaticQuery(graphql`
        query LouviQuery {
            bg: file(name: { eq: "louvi_bg" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
            words_figure: file(
                name: { eq: "words_figure" }
                ext: { eq: ".png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);
    const bg = getImage(data.bg);
    const words_figure = getImage(data.words_figure);
    //
    return (
        <ProjectCard title="Louvi" desc="Clothing store" slug="louvi">
            <SLouviCard>
                <SBg image={bg} alt="Louvi" />
                <SWordsFigure image={words_figure} alt="Louvi" />
                <SElem />
            </SLouviCard>
        </ProjectCard>
    );
};

const SLouviCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
`;
const SBg = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
`;
const SWordsFigure = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
`;
const SElem = styled.div`
    ${position("absolute", "0", null, null, "0")};
    background-size: cover;
    width: 100%;
    height: 100%;
    background-image: url(${words1});
    .project_card_hover & {
        animation-name: louvi_elem;
        animation-duration: 0.2s;
        animation-iteration-count: 8;
        animation-direction: alternate;
        animation-timing-function: steps(1, end);
    }
    @keyframes louvi_elem {
        0% {
            background-image: url(${words1});
            opacity: 0.7;
        }
        33.333% {
            background-image: url(${words2});
            opacity: 1;
        }
        66.6666% {
            background-image: url(${words3});
            opacity: 0.7;
        }
        100% {
            background-image: url(${words4});
            opacity: 1;
        }
    }
`;

export default LouviCard;
