import React from "react";
import PT from "prop-types";
import CN from "classnames";
import { position } from "polished";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProjectCardImage = ({ image, image_hover, title, grayscale }) => {
    const img = image ? getImage(image) : null;
    const img_hover = image_hover ? getImage(image_hover) : null;
    return (
        <SProjectCardImage
            className={CN({
                grayscale: grayscale,
            })}
        >
            {image && <SImageGatsby image={img} alt={title} />}
            {image_hover && <SImageGatsbyHover image={img_hover} alt={title} />}
        </SProjectCardImage>
    );
};

ProjectCardImage.propTypes = {
    image: PT.object,
    image_hover: PT.object,
    title: PT.string,
    grayscale: PT.bool,
};

const SProjectCardImage = styled.div`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    &.grayscale {
        filter: grayscale(100%);
        .project_card_hover & {
            filter: grayscale(0);
        }
    }
`;

const SImageGatsby = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    transition: opacity 0.2s;
    object-fit: cover;
    object-position: center center;
`;
const SImageGatsbyHover = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    transition: opacity 0.2s;
    object-fit: cover;
    object-position: center center;
    opacity: 0;
    .project_card_hover & {
        opacity: 1;
    }
`;

export default ProjectCardImage;
