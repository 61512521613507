import React from "react";
import { position } from "polished";
import styled from "styled-components";

import { ProjectCard } from "~/src/components";

const BssCard = () => {
    return (
        <ProjectCard
            title="Bss Live"
            desc="Promotional video broadcast"
            slug="bss"
        >
            <SBssCard>
                <SCircleList>
                    <SCircle className="one" />
                    <SCircle className="two" />
                    <SCircle className="three" />
                    <SCircle className="four" />
                </SCircleList>
                <SLogo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                    <defs xmlns="http://www.w3.org/2000/svg">
                        <linearGradient
                            x1="50%"
                            y1="0%"
                            x2="50%"
                            y2="100%"
                            id="bss_a"
                        >
                            <stop stopColor="#FCC397" offset="0%" />
                            <stop stopColor="#FC9F95" offset="17.71%" />
                            <stop stopColor="#F77C88" offset="30.048%" />
                            <stop stopColor="#F25192" offset="42.704%" />
                            <stop stopColor="#D34CA3" offset="56.332%" />
                            <stop stopColor="#9A50A5" offset="69.115%" />
                            <stop stopColor="#59569E" offset="83.849%" />
                            <stop stopColor="#39477F" offset="100%" />
                        </linearGradient>
                    </defs>
                    <circle fill="url(#bss_a)" cx="32" cy="32" r="30" />
                    <path
                        fill="#fff"
                        d="M48 32L32 21v6.9L22 21v22l10-6.9V43l16-11z"
                    />
                    <SLogoPath
                        fill="none"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        d="M48 32L32 21v6.9L22 21v22l10-6.9V43l16-11z"
                        opacity="1"
                    />
                </SLogo>
            </SBssCard>
        </ProjectCard>
    );
};

const SBssCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    background: linear-gradient(150deg, #ff2c68 15%, #31d9fd 70%, #a6ffcb 94%);
`;

const SCircleList = styled.div`
    opacity: 0;
    transition: all 0.1s;
    .project_card_hover & {
        opacity: 1;
    }
`;
const SCircle = styled.div`
    ${position("absolute", "50%", null, null, "50%")};
    margin: 0 auto;
    border: 15px solid #fff;
    border-radius: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 0;
    width: 140px;
    height: 140px;
    transition: all 0.1s;
    &.one {
        .project_card_hover & {
            animation: circle infinite 8s linear;
        }
    }
    &.two {
        .project_card_hover & {
            animation: circle infinite 8s linear 2s;
        }
    }
    &.three {
        .project_card_hover & {
            animation: circle infinite 8s linear 4s;
        }
    }
    &.four {
        .project_card_hover & {
            animation: circle infinite 8s linear 6s;
        }
    }
    @keyframes circle {
        0% {
            transform: translateY(-50%) translateX(-50%) scale(1);
            opacity: 0;
        }
        50% {
            transform: translateY(-50%) translateX(-50%) scale(3);
        }
        100% {
            transform: translateY(-50%) translateX(-50%) scale(6);
            opacity: 0.6;
        }
    }
`;
const SLogo = styled.svg`
    transform: translateY(-50%) translateX(-50%);
    width: 5.5em;
    height: 5.5em;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all 0.1s;
    .project_card_hover & {
        transform: translateY(-50%) translateX(-50%) scale(1.2);
    }
`;
const SLogoPath = styled.path`
    fill: transparent;
    stroke: #7223ff;
    stroke-dasharray: 0;
    stroke-width: 2px;
    opacity: 0.4;
    .project_card_hover & {
        animation: groove forwards 6s 3000 alternate;
    }
    @keyframes groove {
        0%,
        100% {
            stroke-dasharray: 98;
        }
        25%,
        75% {
            stroke-dasharray: 20;
        }
        50% {
            stroke-dasharray: 40;
        }
    }
`;

export default BssCard;
