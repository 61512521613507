import React, { useState, useEffect } from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";
import HoverVideoPlayer from "react-hover-video-player";
import Observer from "@researchgate/react-intersection-observer";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";
import { size_down } from "~/src/config/vars";

import video_mp4 from "./card/labyrinth_video_full.mp4";
import video_webm from "./card/labyrinth_video_full.webm";

const LabyrinthCard = () => {
    const data = useStaticQuery(graphql`
        query labyrinthQuery {
            loading: file(
                name: { eq: "labyrinth_video_full" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 370)
                }
            }
        }
    `);
    const loading = getImage(data.loading);
    //
    const only_mobile = size_down("md");
    const [play, setPlay] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (only_mobile) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [only_mobile]);

    const handleIntersection = (event) => {
        if (isMobile) {
            if (event.isIntersecting) {
                setPlay(true);
            } else {
                setPlay(false);
            }
        }
    };

    const options = {
        onChange: handleIntersection,
        threshold: 1,
    };
    return (
        <ProjectCard
            title="Blindville2"
            desc="Quest game"
            href="https://labyrinth.ideaca.today"
            slug="labyrinth"
        >
            <Observer {...options}>
                <SLabyrinthCard className={play ? "kghoney_card_hover" : ""}>
                    <SVideo>
                        <HoverVideoPlayer
                            videoSrc={[
                                { src: video_webm, type: "video/webm" },
                                { src: video_mp4, type: "video/mp4" },
                            ]}
                            style={{ display: "flex" }}
                            videoStyle={{
                                width: "180%",
                            }}
                            focused={play}
                            loadingOverlay={
                                <GatsbyImage
                                    image={loading}
                                    alt="Blindville2"
                                />
                            }
                        />
                    </SVideo>
                </SLabyrinthCard>
            </Observer>
        </ProjectCard>
    );
};

LabyrinthCard.propTypes = {
    xy: PT.array,
};

const SLabyrinthCard = styled.div`
    width: 100%;
    height: 100%;
    padding: 2em;
    background-color: #000;
    color: #fff;
    svg {
        pointer-events: none;
        position: relative;
        transition: all 0.2s;
        z-index: 2;
        .project_card_hover & {
            opacity: 0;
        }
    }
    &.kghoney_card_hover {
        svg {
            opacity: 0;
        }
    }
`;
const SVideo = styled.div`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    div {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        video {
            position: relative;
            top: 5%;
        }
    }
`;

export default LabyrinthCard;
