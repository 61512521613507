import React from "react";
import PT from "prop-types";
import styled from "styled-components";

import { up, container, global } from "~/src/config/vars";

const Container = ({ small, children }) => {
    return (
        <ContainerBlock className={`${small && "small"}`}>
            {children}
        </ContainerBlock>
    );
};

Container.propTypes = {
    children: PT.any,
    small: PT.bool,
};

const ContainerBlock = styled.div`
    width: 100%;
    padding-right: ${global.container_padding};
    padding-left: ${global.container_padding};
    margin-right: auto;
    margin-left: auto;
    ${up("md")} {
        max-width: ${container.md}px;
    }
    ${up("lg")} {
        max-width: ${container.lg}px;
    }
    ${up("xl")} {
        max-width: ${container.xl}px;
    }
`;

export default Container;
