import React from "react";
import PT from "prop-types";
import styled from "styled-components";
import Lottie from "react-lottie";

import { fonts } from "~/src/config/vars";

import { ic } from "~/src/components/icons/lottie/NotificationSuccess";

const FormNotificationSuccess = ({ icon = ic }) => {
    return (
        <SFormNotificationSuccess>
            <SIcon>
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: icon,
                        rendererSettings: {
                            preserveAspectRatio: "xMinYMid meet",
                        },
                    }}
                    height={80}
                    width={80}
                    isStopped={false}
                    isPaused={false}
                />
            </SIcon>
            <SInfo>
                <STitle>Your message sent</STitle>
                <SText>
                    Your application has been successfully submitted, we will
                    contact you soon.
                </SText>
            </SInfo>
        </SFormNotificationSuccess>
    );
};

FormNotificationSuccess.propTypes = {
    icon: PT.any.isRequired,
};

const SFormNotificationSuccess = styled.div``;
const SIcon = styled.div`
    margin-bottom: 1em;
    width: 80px;
    height: 80px;
`;
const SInfo = styled.div``;
const STitle = styled.div`
    font-weight: ${fonts.fw_black};
    font-size: ${fonts.fs_18};
    margin-bottom: 0.5em;
`;
const SText = styled.div`
    font-size: ${fonts.fs_16};
    font-weight: ${fonts.fw_regular};
`;

export default FormNotificationSuccess;
