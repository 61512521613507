import React from "react";

const PhoneIcon = () => {
    return (
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="currentColor"
                d="M4.088 6.748a11.79 11.79 0 0 0 5.125 5.126l1.712-1.711c.21-.21.521-.28.793-.187a8.892 8.892 0 0 0 2.776.443.78.78 0 0 1 .778.777v2.715a.78.78 0 0 1-.778.777c-7.303 0-13.222-5.919-13.222-13.223a.78.78 0 0 1 .777-.777h2.723a.78.78 0 0 1 .777.777c0 .973.155 1.906.443 2.777a.78.78 0 0 1-.194.793l-1.71 1.713z"
            ></path>
        </svg>
    );
};

export default PhoneIcon;

{
    /* <svg xmlns="http://www.w3.org/2000/svg" width="38" height="64" viewBox="0 0 38 64">
  <g id="Group_20" data-name="Group 20" transform="translate(-19232 -1545)">
    <rect id="Rectangle_28" data-name="Rectangle 28" width="38" height="64" transform="translate(19232 1545)" fill="#fff" opacity="0"/>
    <g id="Group_19" data-name="Group 19" transform="translate(19219 1545)">
      <path id="layer2" d="M15,10H49M15,48H49" fill="none" stroke="#202020" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      <rect id="layer1" width="34" height="60" rx="4" stroke-width="3" transform="translate(15 2)" stroke="#202020" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
      <circle id="layer1-2" data-name="layer1" cx="2" cy="2" r="2" transform="translate(30 53)" stroke-width="2" stroke="#202020" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
    </g>
  </g>
</svg> */
}
