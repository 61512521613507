import React from "react";

const NewWindowIcon = () => {
    return (
        <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.1673 7.64177L22.7228 5.91686L24.0485 7.2896"
                stroke="currentColor"
                strokeWidth="2"
            ></path>
            <path
                d="M23.2819 19.1512L25.3741 8.66234L24.0485 7.2896"
                stroke="currentColor"
                strokeWidth="2"
            ></path>
            <path
                d="M6.2627 24.4648L24.2462 7.09838"
                stroke="currentColor"
                strokeWidth="2"
            ></path>
        </svg>
    );
};

export default NewWindowIcon;
