import React from "react";
import CN from "classnames";
import PT from "prop-types";
import styled from "styled-components";
import { motion } from "framer-motion";

import { Footer, Header, ModalCallme } from "~/src/components";
import {
    down,
    header,
    VarsFontsStyle,
    VarsHeaderStyle,
    VarsThemeStyle,
    VarsGlobalStyle,
} from "~/src/config/vars";
import {
    ResetStyle,
    GlobalStyle,
    FontsStyle,
    FormStyle,
    ReactPerfectScrollbar,
} from "~/src/styles";

const Layout = ({
    children,
    show_padding,
    show_footer,
    show_footer_margin,
}) => {
    return (
        <>
            <VarsThemeStyle />
            <ResetStyle />
            <GlobalStyle />
            <FontsStyle />
            <FormStyle />
            <ReactPerfectScrollbar />
            <VarsFontsStyle />
            <VarsGlobalStyle />
            <VarsHeaderStyle />

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0, delay: 0 }}
            >
                <SLayout
                    id="global_content"
                    className={CN({
                        show_padding: show_padding,
                    })}
                >
                    <Header />
                    <SContent>{children}</SContent>
                    {show_footer && (
                        <Footer show_footer_margin={show_footer_margin} />
                    )}
                </SLayout>
                <SModalContent id="modal_content">
                    <ModalCallme name={"callme"} />
                </SModalContent>
            </motion.div>
        </>
    );
};

const SLayout = styled.div`
    /* padding-top: ${header.height}; */
`;

const SContent = styled.div`
    min-height: calc(100vh - 240px);
    ${SLayout}.show_padding & {
        padding-top: 4em;
        ${down("md")} {
            padding-top: 3em;
        }
        ${down("sm")} {
            padding-top: 2em;
        }
    }
`;
const SModalContent = styled.div``;

Layout.defaultProps = {
    show_padding: true,
    show_footer: true,
};

Layout.propTypes = {
    children: PT.node.isRequired,
    show_padding: PT.bool,
    show_footer_margin: PT.bool,
    show_footer: PT.bool,
};

export default Layout;
