import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

import frame_logo from "./card/frame_logo.svg";

const FrameCard = () => {
    const data = useStaticQuery(graphql`
        query FrameQuery {
            brush: file(name: { eq: "frame_brush" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 370)
                }
            }
            bg: file(name: { eq: "frame_image" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 370, placeholder: BLURRED)
                }
            }
        }
    `);
    const brush = getImage(data.brush);
    const bg = getImage(data.bg);
    //
    return (
        <ProjectCard title="Frame" desc="Design projects" slug="frame">
            <SFrameCard>
                <SBg image={bg} alt="Frame" />
                <SBrush image={brush} alt="Frame" />
                <SLogo src={frame_logo} alt="frame logo svg" />
            </SFrameCard>
        </ProjectCard>
    );
};

const SFrameCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    background-color: #001c3c;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SBg = styled(GatsbyImage)`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    opacity: 1;
    .project_card_hover & {
        opacity: 0;
    }
`;
const SBrush = styled(GatsbyImage)`
    ${position("absolute", "50%", "0", null, null)};
    width: 100%;
    height: 80%;
    transition: all 0.2s;
    opacity: 0;
    transform: translateY(-50%) translateX(200px);
    .project_card_hover & {
        opacity: 1;
        transform: translateY(-50%) translateX(50px);
    }
`;
const SLogo = styled.img`
    width: 140px;
    transition: all 0.3s;
    opacity: 0;
    transform: translateX(100px);
    .project_card_hover & {
        transform: translateX(0);
        opacity: 1;
    }
`;

export default FrameCard;
