import React from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

const BaltikaCard = () => {
    const data = useStaticQuery(graphql`
        query MaccoffeeQuery {
            bg: file(name: { eq: "maccoffee_card_bg" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            money1: file(
                name: { eq: "maccoffee_money1" }
                ext: { eq: ".png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 312)
                }
            }
            money2: file(
                name: { eq: "maccoffee_money2" }
                ext: { eq: ".png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 347)
                }
            }
            sachet: file(
                name: { eq: "maccoffee_sachet" }
                ext: { eq: ".png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);
    const bg = getImage(data.bg);
    const money1 = getImage(data.money1);
    const money2 = getImage(data.money2);
    const sachet = getImage(data.sachet);
    //
    const calc = (x, y, width, height) => [
        ((x - width / 2) / width) * 300,
        -((y - height / 2) / height) * 300,
    ];

    const trans1 = (x, y) => `translate3d(${x / 200}px,${y / 16}px,0)`;
    const trans2 = (x, y) => `translate3d(${x / 200}px,${y / 24}px,0)`;

    const [props, set] = useSpring(() => ({
        xy: [0, 0],
        config: { mass: 8, tension: 1000, friction: 100 },
    }));

    return (
        <ProjectCard
            title="Maccoffee"
            desc="Promo website of the company"
            slug="maccoffee"
        >
            <SBaltikaCard
                onMouseMove={(e) => {
                    const { x, y, width, height } =
                        e.currentTarget.getBoundingClientRect();
                    const c = calc(e.clientX - x, e.clientY - y, width, height);
                    set({
                        xy: c,
                    });
                }}
            >
                <SBg image={bg} alt="maccoffee" />
                <SMoney1 style={{ transform: props.xy.interpolate(trans1) }}>
                    <GatsbyImage image={money1} alt="maccoffee" />
                </SMoney1>
                <SMoney2 style={{ transform: props.xy.interpolate(trans2) }}>
                    <GatsbyImage image={money2} alt="maccoffee" />
                </SMoney2>
                <SLogo image={sachet} alt="maccoffee" />
            </SBaltikaCard>
        </ProjectCard>
    );
};

BaltikaCard.propTypes = {
    xy: PT.array,
};

const SBaltikaCard = styled.div`
    width: 100%;
    height: 100%;
    background-color: #1d1d1d;
`;
const SBg = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    background-size: cover;
    opacity: 0.5;
    transition: all 0.1s;
    .project_card_hover & {
        opacity: 1;
    }
`;
const SMoney1 = styled(animated.div)`
    ${position("absolute", "10%", null, null, "5%")};
    width: 50%;
    height: 100%;
    transform: rotate(-40deg);
`;
const SMoney2 = styled(animated.div)`
    ${position("absolute", "5%", "-10%", null, null)};
    width: 50%;
    height: 100%;
`;
const SLogo = styled(GatsbyImage)`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    width: 335px;
    height: 238px;
`;

export default BaltikaCard;
