import CN from "classnames";
import styled from "styled-components";
import PT from "prop-types";
import React, { useState } from "react";
import Textarea from "react-textarea-autosize";
import { down } from "~/src/config/vars";
import { SFormFieldError, SFormFieldFucus } from "./form_field_styles";
import BaseFormField from "./BaseFormField";

const FormTextariaField = ({ id, field, form, ...rest }) => {
    const [focused, setFocus] = useState(false);
    const onFocus = () => {
        setFocus(true);
    };
    const onBlur = (e) => {
        field.onBlur(e);
        setFocus(false);
    };
    const meta = form.getFieldMeta(field.name);
    const focus = !!(focused || field.value);
    return (
        <BaseFormField focus={focus} {...rest} id={id}>
            <SFormFieldTextarea
                id={id}
                className={CN(`SFormFieldInput`, {
                    focus,
                    input_error: meta.error,
                })}
                {...field}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </BaseFormField>
    );
};

FormTextariaField.propTypes = {
    id: PT.string,
    field: PT.object,
    form: PT.object,
};

const SFormFieldTextarea = styled(Textarea)`
    min-height: 90px;
    padding-bottom: 1em;
    resize: none;
    overflow: hidden;
    &.fucus {
        ${SFormFieldFucus};
    }
    &.input_error {
        ${SFormFieldError};
    }
    ${down("sm")} {
        min-height: 120px;
    }
    &.focus {
        padding-top: 28px;
    }
`;

export default FormTextariaField;
