import React from "react";

const FieldErrorIcon = () => {
    return (
        <svg
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
        >
            <path d="M 24.84375 3.4375 C 24.539063 3.484375 24.273438 3.667969 24.125 3.9375 L 0.125 45.5 C -0.0546875 45.8125 -0.0546875 46.195313 0.128906 46.503906 C 0.308594 46.8125 0.640625 47.003906 1 47 L 49 47 C 49.359375 47.003906 49.691406 46.8125 49.871094 46.503906 C 50.054688 46.195313 50.054688 45.8125 49.875 45.5 L 25.875 3.9375 C 25.671875 3.570313 25.257813 3.371094 24.84375 3.4375 Z M 25 6.4375 L 47.25 45 L 2.75 45 Z M 23.84375 18.90625 C 23.628906 18.90625 23.5 19.035156 23.5 19.25 L 23.5 33.71875 C 23.5 33.933594 23.628906 34.09375 23.84375 34.09375 L 26.15625 34.09375 C 26.371094 34.09375 26.5 33.933594 26.5 33.71875 L 26.5 19.25 C 26.5 19.035156 26.371094 18.90625 26.15625 18.90625 Z M 23.625 36.96875 C 23.492188 37.019531 23.4375 37.152344 23.4375 37.3125 L 23.4375 39.9375 C 23.4375 40.152344 23.566406 40.28125 23.78125 40.28125 L 26.21875 40.28125 C 26.433594 40.28125 26.5625 40.152344 26.5625 39.9375 L 26.5625 37.3125 C 26.5625 37.097656 26.433594 36.96875 26.21875 36.96875 L 23.78125 36.96875 C 23.726563 36.96875 23.667969 36.953125 23.625 36.96875 Z" />
        </svg>
    );
};

export default FieldErrorIcon;
