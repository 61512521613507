import React, { Component } from "react";
import PT from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import styled from "styled-components";
import { position } from "polished";

import { up, grid } from "~/src/config/vars";

export default class ColoredScrollbars extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);
        this.state = { top: 0, screenWidth: null };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.renderThumb = this.renderThumb.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    handleUpdate(values) {
        const { top } = values;
        this.setState({ top });
    }

    renderTrack({ style, ...props }) {
        return <STrack style={{ ...style }} {...props} />;
    }
    renderTrackHorizontal({ style, ...props }) {
        return <SRenderTrackHorizontal style={{ ...style }} {...props} />;
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
        this.updateWindowDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
        this.updateWindowDimensions();
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
    }

    renderThumb({ style, ...props }) {
        const { top } = this.state;
        let color = 220 - top * 220;
        const thumbStyle = {
            backgroundColor: `rgb(${Math.round(color)}, ${Math.round(
                color
            )}, ${Math.round(color)})`,
        };
        return <SThumb style={{ ...style, ...thumbStyle }} {...props} />;
    }

    render() {
        const { children, ...another } = this.props;
        const { screenWidth } = this.state;
        return (
            <SBlock>
                {screenWidth >= grid.lg && children ? (
                    <Scrollbars
                        renderThumbVertical={this.renderThumb}
                        renderTrackVertical={this.renderTrack}
                        renderTrackHorizontal={this.renderTrackHorizontal}
                        onUpdate={this.handleUpdate}
                        {...another}
                        {...this.props}
                    >
                        {children}
                    </Scrollbars>
                ) : null}
                {screenWidth <= grid.lg && children ? children : null}
            </SBlock>
        );
    }
}

ColoredScrollbars.propTypes = {
    children: PT.any,
};

const SBlock = styled.div`
    ${up("lg")} {
        height: 100%;
        display: flex;
    }
`;
const SThumb = styled.div``;
const STrack = styled.div`
    ${position("absolute", "0", null, "0", "0")};
    width: 6px;
    z-index: 5;
`;
const SRenderTrackHorizontal = styled.div`
    display: none;
`;
