import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import CoursiveVideoCompoents from "./CoursiveVideoCompoents";
import FigmaChangeDevice from "./FigmaChangeDevice";

const CoursiveCard = () => {
    const data = useStaticQuery(graphql`
        query CoursiveQuery {
            img_2: file(
                name: { eq: "coursive_colors_and_typography_2" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 88)
                }
            }
            img_3: file(
                name: { eq: "coursive_course_pages_3" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 88)
                }
            }
            img_5: file(
                name: { eq: "coursive_payments_5" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 88)
                }
            }
            img_6: file(
                name: { eq: "coursive_info_pages_6" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 88)
                }
            }
            img_7: file(name: { eq: "coursive_auth_7" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 88)
                }
            }
            img_8: file(
                name: { eq: "coursive_components_8" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 88)
                }
            }
            img_9: file(
                name: { eq: "coursive_quiz_task_9" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 88)
                }
            }
        }
    `);

    const img_2 = getImage(data.img_2);
    const img_3 = getImage(data.img_3);
    const img_5 = getImage(data.img_5);
    const img_6 = getImage(data.img_6);
    const img_7 = getImage(data.img_7);
    const img_8 = getImage(data.img_8);
    const img_9 = getImage(data.img_9);

    return (
        <SCoursive>
            <FigmaChangeDevice
                link_desktop="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FaQjYGoJV8k2HNt9TEshYMC%2Fcursive-spa%3Fpage-id%3D0%253A1%26node-id%3D2470%253A64208%26viewport%3D257%252C48%252C0.13%26scaling%3Dscale-down-width%26starting-point-node-id%3D354%253A60%26hide-ui%3D1"
                link_mobile="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FaQjYGoJV8k2HNt9TEshYMC%2Fcursive-spa%3Fpage-id%3D0%253A1%26node-id%3D2456%253A41468%26viewport%3D257%252C48%252C0.13%26scaling%3Dscale-down-width%26starting-point-node-id%3D354%253A60%26hide-ui%3D1"
            />

            <SImage image={img_2} alt="Coursive" />
            <SImage image={img_3} alt="Coursive" />
            <CoursiveVideoCompoents />
            <SImage image={img_5} alt="Coursive" />
            <SImage image={img_6} alt="Coursive" />
            <SImage image={img_7} alt="Coursive" />
            <SImage image={img_8} alt="Coursive" />
            <SImage image={img_9} alt="Coursive" />
        </SCoursive>
    );
};

const SCoursive = styled.div``;

const SImage = styled(GatsbyImage)``;

export default CoursiveCard;
