import React from "react";
import PT from "prop-types";
import CN from "classnames";
import styled from "styled-components";

import { Loader } from "~/src/components";
import { colors, down, fonts, global } from "~/src/config/vars";

const Button = ({
    type = "button",
    size = "default",
    color = "white",
    disabled,
    loading,
    active,
    children,
    onClick,
    block,
    className,
    ...another
}) => {
    return (
        <SButton
            type={type}
            onClick={onClick}
            className={CN(`SButton ${color} ${size && size} ${className}`, {
                disabled: disabled,
                active: active,
                loading: loading,
                block: block,
            })}
            {...another}
        >
            <SInfo>
                <SBtnText>{children}</SBtnText>
            </SInfo>
            {loading ? <Loader type="absolute" size="mini" /> : null}
        </SButton>
    );
};

Button.propTypes = {
    type: PT.oneOf(["button", "submit", "reset"]),
    color: PT.oneOf(["red_bor", "grey", "black", "white"]),
    disabled: PT.bool,
    loading: PT.bool,
    block: PT.bool,
    active: PT.bool,
    onClick: PT.func,
    children: PT.any.isRequired,
    bg_image: PT.string,
    size: PT.oneOf(["default", "small"]),
    className: PT.string,
};

const SButton = styled.button`
    cursor: pointer;
    box-shadow: none;
    padding: 0.6em 1em;
    border-style: solid;
    border-width: 2px;
    line-height: 1.3;
    position: relative;
    transition: all 0.15s;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-family: ${fonts.ff_global};
    @supports (font-variation-settings: normal) {
        font-family: ${fonts.ff_global_variable};
    }
    font-size: 1.1em;
    border-radius: ${global.border_radius};
    background-color: transparent;
    font-weight: ${fonts.fw_medium};
    ${down("sm")} {
        font-size: 0.96em;
    }
    &.red_bor {
        background-color: transparent;
        border-color: ${colors.red};
        color: ${colors.white};
        &:hover {
            background-color: ${colors.red};
            border-color: ${colors.red};
            color: ${colors.white};
        }
    }
    &.black {
        background-color: transparent;
        border-color: ${colors.white};
        color: ${colors.white};
        &:hover {
            background-color: ${colors.white};
            color: ${colors.black};
        }
    }
    &.white {
        background-color: ${colors.white};
        border-color: ${colors.white};
        color: ${colors.black};
        svg {
            color: ${colors.black};
        }
        @media (hover) {
            &:hover {
                background-color: transparent;
                color: ${colors.white};
            }
        }
    }
    &.small {
        font-size: 0.9em;
    }
    &.loading {
        pointer-events: none;
    }
    &.block {
        display: block;
        width: 100%;
    }
    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }
`;
const SInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    ${SButton}.loading & {
        opacity: 0;
    }
`;
const SBtnText = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
        width: 1.2em;
        height: 1.2em;
        margin-right: 0.5em;
    }
`;

export default Button;
