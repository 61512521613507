import { createGlobalStyle } from "styled-components";

import { down } from "~/src/config/vars";

export const url = "https://dangercactus.io";
export const presentation_link = "https://dangercactus.io";

export const global = {
    border_radius: "var(--border-radius)",
    transition: "var(--transition)",

    container_padding: "var(--container-padding)",
    container_padding_minus: "var(--container-padding-minus)",
};

export const VarsGlobalStyle = createGlobalStyle`
    :root {
        --border-radius: 0;
        --transition: 0.2s;

        --container-padding: 15px;
        --container-padding-minus: -15px;
        ${down("sm")} {
            --container-padding: 5vw;
            --container-padding-minus: -5vw;
        }
    }
`;
