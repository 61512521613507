/* eslint-disable prettier/prettier */
export const we = {
    v: "5.5.5",
    fr: 30,
    ip: 0,
    op: 83,
    w: 256,
    h: 256,

    ddd: 0,
    assets: [
        {
            id: "comp_0",
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [629.147, 783.455, 0], ix: 2 },
                        a: { a: 0, k: [41.438, 21.93, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 15,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [-0.811, -2.369],
                                                            [-11.447, -3.725],
                                                            [-7.328, 1.411],
                                                            [17.725, 1.301],
                                                            [1.993, 4.377],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [2.488, 7.264],
                                                            [6.712, 2.184],
                                                            [-2.465, 1.543],
                                                            [-28.275, -4.199],
                                                            [-1.001, -2.195],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.23, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-7.884, -6.719],
                                                            [15.676, 8.27],
                                                            [41.189, 8.247],
                                                            [8.879, 21.244],
                                                            [-36.686, -6.346],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 20,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [1.801, -1.739],
                                                            [0.272, -0.837],
                                                            [-2.335, -9.702],
                                                            [3.225, 14.551],
                                                            [-2.711, 6.64],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [-7.262, 7.014],
                                                            [-4.072, 12.525],
                                                            [-6.835, -5.202],
                                                            [-3.775, -18.699],
                                                            [0.912, -2.233],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.231, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-50.385, -4.469],
                                                            [-63.325, 14.02],
                                                            [-65.062, 49.997],
                                                            [-80.622, 22.744],
                                                            [-75.686, -12.596],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 25,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [-0.811, -2.369],
                                                            [-11.447, -3.725],
                                                            [-7.328, 1.411],
                                                            [17.725, 1.301],
                                                            [1.993, 4.377],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [2.488, 7.264],
                                                            [6.712, 2.184],
                                                            [-2.465, 1.543],
                                                            [-28.275, -4.199],
                                                            [-1.001, -2.195],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.23, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-7.884, -6.719],
                                                            [15.676, 8.27],
                                                            [41.189, 8.247],
                                                            [8.879, 21.244],
                                                            [-36.686, -6.346],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 30,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [1.801, -1.739],
                                                            [0.272, -0.837],
                                                            [-2.335, -9.702],
                                                            [3.225, 14.551],
                                                            [-2.711, 6.64],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [-7.262, 7.014],
                                                            [-4.072, 12.525],
                                                            [-6.835, -5.202],
                                                            [-3.775, -18.699],
                                                            [0.912, -2.233],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.231, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-50.385, -4.469],
                                                            [-63.325, 14.02],
                                                            [-65.062, 49.997],
                                                            [-80.622, 22.744],
                                                            [-75.686, -12.596],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 35,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [-0.811, -2.369],
                                                            [-11.447, -3.725],
                                                            [-7.328, 1.411],
                                                            [17.725, 1.301],
                                                            [1.993, 4.377],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [2.488, 7.264],
                                                            [6.712, 2.184],
                                                            [-2.465, 1.543],
                                                            [-28.275, -4.199],
                                                            [-1.001, -2.195],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.23, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-7.884, -6.719],
                                                            [15.676, 8.27],
                                                            [41.189, 8.247],
                                                            [8.879, 21.244],
                                                            [-36.686, -6.346],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 40,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [1.801, -1.739],
                                                            [0.272, -0.837],
                                                            [-2.335, -9.702],
                                                            [3.225, 14.551],
                                                            [-2.711, 6.64],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [-7.262, 7.014],
                                                            [-4.072, 12.525],
                                                            [-6.835, -5.202],
                                                            [-3.775, -18.699],
                                                            [0.912, -2.233],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.231, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-50.385, -4.469],
                                                            [-63.325, 14.02],
                                                            [-65.062, 49.997],
                                                            [-80.622, 22.744],
                                                            [-75.686, -12.596],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 45,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [-0.811, -2.369],
                                                            [-11.447, -3.725],
                                                            [-7.328, 1.411],
                                                            [17.725, 1.301],
                                                            [1.993, 4.377],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [2.488, 7.264],
                                                            [6.712, 2.184],
                                                            [-2.465, 1.543],
                                                            [-28.275, -4.199],
                                                            [-1.001, -2.195],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.23, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-7.884, -6.719],
                                                            [15.676, 8.27],
                                                            [41.189, 8.247],
                                                            [8.879, 21.244],
                                                            [-36.686, -6.346],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 50,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [1.801, -1.739],
                                                            [0.272, -0.837],
                                                            [-2.335, -9.702],
                                                            [3.225, 14.551],
                                                            [-2.711, 6.64],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [-7.262, 7.014],
                                                            [-4.072, 12.525],
                                                            [-6.835, -5.202],
                                                            [-3.775, -18.699],
                                                            [0.912, -2.233],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.231, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-50.385, -4.469],
                                                            [-63.325, 14.02],
                                                            [-65.062, 49.997],
                                                            [-80.622, 22.744],
                                                            [-75.686, -12.596],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 55,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [-0.811, -2.369],
                                                            [-11.447, -3.725],
                                                            [-7.328, 1.411],
                                                            [17.725, 1.301],
                                                            [1.993, 4.377],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [2.488, 7.264],
                                                            [6.712, 2.184],
                                                            [-2.465, 1.543],
                                                            [-28.275, -4.199],
                                                            [-1.001, -2.195],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.23, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-7.884, -6.719],
                                                            [15.676, 8.27],
                                                            [41.189, 8.247],
                                                            [8.879, 21.244],
                                                            [-36.686, -6.346],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 60,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [1.801, -1.739],
                                                            [0.272, -0.837],
                                                            [-2.335, -9.702],
                                                            [3.225, 14.551],
                                                            [-2.711, 6.64],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [-7.262, 7.014],
                                                            [-4.072, 12.525],
                                                            [-6.835, -5.202],
                                                            [-3.775, -18.699],
                                                            [0.912, -2.233],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.231, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-50.385, -4.469],
                                                            [-63.325, 14.02],
                                                            [-65.062, 49.997],
                                                            [-80.622, 22.744],
                                                            [-75.686, -12.596],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 65,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [-0.811, -2.369],
                                                            [-11.447, -3.725],
                                                            [-7.328, 1.411],
                                                            [17.725, 1.301],
                                                            [1.993, 4.377],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [2.488, 7.264],
                                                            [6.712, 2.184],
                                                            [-2.465, 1.543],
                                                            [-28.275, -4.199],
                                                            [-1.001, -2.195],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.23, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-7.884, -6.719],
                                                            [15.676, 8.27],
                                                            [41.189, 8.247],
                                                            [8.879, 21.244],
                                                            [-36.686, -6.346],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 70,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [1.801, -1.739],
                                                            [0.272, -0.837],
                                                            [-2.335, -9.702],
                                                            [3.225, 14.551],
                                                            [-2.711, 6.64],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [-7.262, 7.014],
                                                            [-4.072, 12.525],
                                                            [-6.835, -5.202],
                                                            [-3.775, -18.699],
                                                            [0.912, -2.233],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.231, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-50.385, -4.469],
                                                            [-63.325, 14.02],
                                                            [-65.062, 49.997],
                                                            [-80.622, 22.744],
                                                            [-75.686, -12.596],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 75,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [-0.811, -2.369],
                                                            [-11.447, -3.725],
                                                            [-7.328, 1.411],
                                                            [17.725, 1.301],
                                                            [1.993, 4.377],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [2.488, 7.264],
                                                            [6.712, 2.184],
                                                            [-2.465, 1.543],
                                                            [-28.275, -4.199],
                                                            [-1.001, -2.195],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.23, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-7.884, -6.719],
                                                            [15.676, 8.27],
                                                            [41.189, 8.247],
                                                            [8.879, 21.244],
                                                            [-36.686, -6.346],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 80,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [1.801, -1.739],
                                                            [0.272, -0.837],
                                                            [-2.335, -9.702],
                                                            [3.225, 14.551],
                                                            [-2.711, 6.64],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [-7.262, 7.014],
                                                            [-4.072, 12.525],
                                                            [-6.835, -5.202],
                                                            [-3.775, -18.699],
                                                            [0.912, -2.233],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.231, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-50.385, -4.469],
                                                            [-63.325, 14.02],
                                                            [-65.062, 49.997],
                                                            [-80.622, 22.744],
                                                            [-75.686, -12.596],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 85,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [-0.811, -2.369],
                                                            [-11.447, -3.725],
                                                            [-7.328, 1.411],
                                                            [17.725, 1.301],
                                                            [1.993, 4.377],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [2.488, 7.264],
                                                            [6.712, 2.184],
                                                            [-2.465, 1.543],
                                                            [-28.275, -4.199],
                                                            [-1.001, -2.195],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.23, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-7.884, -6.719],
                                                            [15.676, 8.27],
                                                            [41.189, 8.247],
                                                            [8.879, 21.244],
                                                            [-36.686, -6.346],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 90,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [1.801, -1.739],
                                                            [0.272, -0.837],
                                                            [-2.335, -9.702],
                                                            [3.225, 14.551],
                                                            [-2.711, 6.64],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [-7.262, 7.014],
                                                            [-4.072, 12.525],
                                                            [-6.835, -5.202],
                                                            [-3.775, -18.699],
                                                            [0.912, -2.233],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.231, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-50.385, -4.469],
                                                            [-63.325, 14.02],
                                                            [-65.062, 49.997],
                                                            [-80.622, 22.744],
                                                            [-75.686, -12.596],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                t: 95,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [0.012, 0.021],
                                                            [0, 0],
                                                            [0.016, -0.065],
                                                            [0, 0],
                                                            [0.042, -0.315],
                                                            [0.057, -0.651],
                                                            [-0.033, -1.327],
                                                            [-0.811, -2.369],
                                                            [-11.447, -3.725],
                                                            [-7.328, 1.411],
                                                            [17.725, 1.301],
                                                            [1.993, 4.377],
                                                            [0.746, 2.307],
                                                            [0.314, 1.22],
                                                            [0.146, 0.63],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        o: [
                                                            [-0.011, -0.05],
                                                            [0, 0],
                                                            [-0.019, 0.037],
                                                            [0, 0],
                                                            [-0.054, 0.283],
                                                            [-0.104, 0.603],
                                                            [-0.127, 1.311],
                                                            [0.061, 2.664],
                                                            [2.488, 7.264],
                                                            [6.712, 2.184],
                                                            [-2.465, 1.543],
                                                            [-28.275, -4.199],
                                                            [-1.001, -2.195],
                                                            [-0.379, -1.154],
                                                            [-0.163, -0.595],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [-8.194, -21.528],
                                                            [-8.23, -21.68],
                                                            [-8.259, -21.635],
                                                            [-8.319, -21.528],
                                                            [-8.423, -21.173],
                                                            [-8.591, -20.307],
                                                            [-8.831, -18.4],
                                                            [-8.962, -14.415],
                                                            [-7.884, -6.719],
                                                            [15.676, 8.27],
                                                            [41.189, 8.247],
                                                            [8.879, 21.244],
                                                            [-36.686, -6.346],
                                                            [-39.308, -13.079],
                                                            [-40.345, -16.619],
                                                            [-40.796, -18.492],
                                                            [-40.999, -19.508],
                                                            [-41.095, -20.088],
                                                            [-41.141, -20.385],
                                                            [-41.189, -20.882],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                        ],
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [41.438, 21.931], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [577.733, 800.065, 0], ix: 2 },
                        a: { a: 0, k: [24.564, 54.947, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 15,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [2.526, -2.501],
                                                            [3.662, -6.916],
                                                            [2.125, -8.051],
                                                            [0.721, -8.61],
                                                            [1.162, 8.877],
                                                            [-0.942, 9.411],
                                                            [-3.862, 9.416],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [-5.064, 5.01],
                                                            [-3.704, 6.907],
                                                            [-2.134, 8.053],
                                                            [-2.589, -8.244],
                                                            [-1.162, -8.873],
                                                            [0.909, -9.407],
                                                            [1.935, -4.707],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [23.953, -20.936],
                                                            [24.214, -21.198],
                                                            [24.133, -21.234],
                                                            [23.819, -21.195],
                                                            [22.99, -20.938],
                                                            [21.07, -20.078],
                                                            [17.013, -17.674],
                                                            [9.091, -11.121],
                                                            [-4.127, 6.961],
                                                            [-12.82, 29.582],
                                                            [-17.054, 54.697],
                                                            [-22.804, 29.017],
                                                            [-23.372, 1.525],
                                                            [-16.417, -26.913],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.049, -52.585],
                                                            [1.013, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 20,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [-0.638, -3.492],
                                                            [-5.606, -4.152],
                                                            [-7.803, -3.956],
                                                            [-8.929, -4.512],
                                                            [9.321, 4.667],
                                                            [8.89, 10.66],
                                                            [1.184, 10.597],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [1.176, 6.431],
                                                            [6.464, 4.787],
                                                            [8.337, 4.227],
                                                            [-9.179, -1.262],
                                                            [-14.4, -7.211],
                                                            [-5.982, -7.173],
                                                            [-0.565, -5.058],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [29.453, -28.311],
                                                            [28.464, -26.698],
                                                            [30.008, -26.234],
                                                            [28.569, -25.07],
                                                            [26.74, -25.063],
                                                            [25.32, -24.328],
                                                            [28.013, -22.174],
                                                            [25.341, -12.371],
                                                            [32.498, 1.586],
                                                            [47.555, 10.957],
                                                            [70.446, 20.947],
                                                            [22.446, 12.267],
                                                            [-1.372, -6.225],
                                                            [-3.417, -28.163],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.05, -52.585],
                                                            [1.014, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 25,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [2.526, -2.501],
                                                            [3.662, -6.916],
                                                            [2.125, -8.051],
                                                            [0.721, -8.61],
                                                            [1.162, 8.877],
                                                            [-0.942, 9.411],
                                                            [-3.862, 9.416],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [-5.064, 5.01],
                                                            [-3.704, 6.907],
                                                            [-2.134, 8.053],
                                                            [-2.589, -8.244],
                                                            [-1.162, -8.873],
                                                            [0.909, -9.407],
                                                            [1.935, -4.707],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [23.953, -20.936],
                                                            [24.214, -21.198],
                                                            [24.133, -21.234],
                                                            [23.819, -21.195],
                                                            [22.99, -20.938],
                                                            [21.07, -20.078],
                                                            [17.013, -17.674],
                                                            [9.091, -11.121],
                                                            [-4.127, 6.961],
                                                            [-12.82, 29.582],
                                                            [-17.054, 54.697],
                                                            [-22.804, 29.017],
                                                            [-23.372, 1.525],
                                                            [-16.417, -26.913],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.049, -52.585],
                                                            [1.013, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 30,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [-0.638, -3.492],
                                                            [-5.606, -4.152],
                                                            [-7.803, -3.956],
                                                            [-8.929, -4.512],
                                                            [9.321, 4.667],
                                                            [8.89, 10.66],
                                                            [1.184, 10.597],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [1.176, 6.431],
                                                            [6.464, 4.787],
                                                            [8.337, 4.227],
                                                            [-9.179, -1.262],
                                                            [-14.4, -7.211],
                                                            [-5.982, -7.173],
                                                            [-0.565, -5.058],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [29.453, -28.311],
                                                            [28.464, -26.698],
                                                            [30.008, -26.234],
                                                            [28.569, -25.07],
                                                            [26.74, -25.063],
                                                            [25.32, -24.328],
                                                            [28.013, -22.174],
                                                            [25.341, -12.371],
                                                            [32.498, 1.586],
                                                            [47.555, 10.957],
                                                            [70.446, 20.947],
                                                            [22.446, 12.267],
                                                            [-1.372, -6.225],
                                                            [-3.417, -28.163],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.05, -52.585],
                                                            [1.014, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 35,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [2.526, -2.501],
                                                            [3.662, -6.916],
                                                            [2.125, -8.051],
                                                            [0.721, -8.61],
                                                            [1.162, 8.877],
                                                            [-0.942, 9.411],
                                                            [-3.862, 9.416],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [-5.064, 5.01],
                                                            [-3.704, 6.907],
                                                            [-2.134, 8.053],
                                                            [-2.589, -8.244],
                                                            [-1.162, -8.873],
                                                            [0.909, -9.407],
                                                            [1.935, -4.707],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [23.953, -20.936],
                                                            [24.214, -21.198],
                                                            [24.133, -21.234],
                                                            [23.819, -21.195],
                                                            [22.99, -20.938],
                                                            [21.07, -20.078],
                                                            [17.013, -17.674],
                                                            [9.091, -11.121],
                                                            [-4.127, 6.961],
                                                            [-12.82, 29.582],
                                                            [-17.054, 54.697],
                                                            [-22.804, 29.017],
                                                            [-23.372, 1.525],
                                                            [-16.417, -26.913],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.049, -52.585],
                                                            [1.013, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 40,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [-0.638, -3.492],
                                                            [-5.606, -4.152],
                                                            [-7.803, -3.956],
                                                            [-8.929, -4.512],
                                                            [9.321, 4.667],
                                                            [8.89, 10.66],
                                                            [1.184, 10.597],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [1.176, 6.431],
                                                            [6.464, 4.787],
                                                            [8.337, 4.227],
                                                            [-9.179, -1.262],
                                                            [-14.4, -7.211],
                                                            [-5.982, -7.173],
                                                            [-0.565, -5.058],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [29.453, -28.311],
                                                            [28.464, -26.698],
                                                            [30.008, -26.234],
                                                            [28.569, -25.07],
                                                            [26.74, -25.063],
                                                            [25.32, -24.328],
                                                            [28.013, -22.174],
                                                            [25.341, -12.371],
                                                            [32.498, 1.586],
                                                            [47.555, 10.957],
                                                            [70.446, 20.947],
                                                            [22.446, 12.267],
                                                            [-1.372, -6.225],
                                                            [-3.417, -28.163],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.05, -52.585],
                                                            [1.014, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 45,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [2.526, -2.501],
                                                            [3.662, -6.916],
                                                            [2.125, -8.051],
                                                            [0.721, -8.61],
                                                            [1.162, 8.877],
                                                            [-0.942, 9.411],
                                                            [-3.862, 9.416],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [-5.064, 5.01],
                                                            [-3.704, 6.907],
                                                            [-2.134, 8.053],
                                                            [-2.589, -8.244],
                                                            [-1.162, -8.873],
                                                            [0.909, -9.407],
                                                            [1.935, -4.707],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [23.953, -20.936],
                                                            [24.214, -21.198],
                                                            [24.133, -21.234],
                                                            [23.819, -21.195],
                                                            [22.99, -20.938],
                                                            [21.07, -20.078],
                                                            [17.013, -17.674],
                                                            [9.091, -11.121],
                                                            [-4.127, 6.961],
                                                            [-12.82, 29.582],
                                                            [-17.054, 54.697],
                                                            [-22.804, 29.017],
                                                            [-23.372, 1.525],
                                                            [-16.417, -26.913],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.049, -52.585],
                                                            [1.013, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 50,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [-0.638, -3.492],
                                                            [-5.606, -4.152],
                                                            [-7.803, -3.956],
                                                            [-8.929, -4.512],
                                                            [9.321, 4.667],
                                                            [8.89, 10.66],
                                                            [1.184, 10.597],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [1.176, 6.431],
                                                            [6.464, 4.787],
                                                            [8.337, 4.227],
                                                            [-9.179, -1.262],
                                                            [-14.4, -7.211],
                                                            [-5.982, -7.173],
                                                            [-0.565, -5.058],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [29.453, -28.311],
                                                            [28.464, -26.698],
                                                            [30.008, -26.234],
                                                            [28.569, -25.07],
                                                            [26.74, -25.063],
                                                            [25.32, -24.328],
                                                            [28.013, -22.174],
                                                            [25.341, -12.371],
                                                            [32.498, 1.586],
                                                            [47.555, 10.957],
                                                            [70.446, 20.947],
                                                            [22.446, 12.267],
                                                            [-1.372, -6.225],
                                                            [-3.417, -28.163],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.05, -52.585],
                                                            [1.014, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 55,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [2.526, -2.501],
                                                            [3.662, -6.916],
                                                            [2.125, -8.051],
                                                            [0.721, -8.61],
                                                            [1.162, 8.877],
                                                            [-0.942, 9.411],
                                                            [-3.862, 9.416],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [-5.064, 5.01],
                                                            [-3.704, 6.907],
                                                            [-2.134, 8.053],
                                                            [-2.589, -8.244],
                                                            [-1.162, -8.873],
                                                            [0.909, -9.407],
                                                            [1.935, -4.707],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [23.953, -20.936],
                                                            [24.214, -21.198],
                                                            [24.133, -21.234],
                                                            [23.819, -21.195],
                                                            [22.99, -20.938],
                                                            [21.07, -20.078],
                                                            [17.013, -17.674],
                                                            [9.091, -11.121],
                                                            [-4.127, 6.961],
                                                            [-12.82, 29.582],
                                                            [-17.054, 54.697],
                                                            [-22.804, 29.017],
                                                            [-23.372, 1.525],
                                                            [-16.417, -26.913],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.049, -52.585],
                                                            [1.013, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 60,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [-0.638, -3.492],
                                                            [-5.606, -4.152],
                                                            [-7.803, -3.956],
                                                            [-8.929, -4.512],
                                                            [9.321, 4.667],
                                                            [8.89, 10.66],
                                                            [1.184, 10.597],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [1.176, 6.431],
                                                            [6.464, 4.787],
                                                            [8.337, 4.227],
                                                            [-9.179, -1.262],
                                                            [-14.4, -7.211],
                                                            [-5.982, -7.173],
                                                            [-0.565, -5.058],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [29.453, -28.311],
                                                            [28.464, -26.698],
                                                            [30.008, -26.234],
                                                            [28.569, -25.07],
                                                            [26.74, -25.063],
                                                            [25.32, -24.328],
                                                            [28.013, -22.174],
                                                            [25.341, -12.371],
                                                            [32.498, 1.586],
                                                            [47.555, 10.957],
                                                            [70.446, 20.947],
                                                            [22.446, 12.267],
                                                            [-1.372, -6.225],
                                                            [-3.417, -28.163],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.05, -52.585],
                                                            [1.014, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 65,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [2.526, -2.501],
                                                            [3.662, -6.916],
                                                            [2.125, -8.051],
                                                            [0.721, -8.61],
                                                            [1.162, 8.877],
                                                            [-0.942, 9.411],
                                                            [-3.862, 9.416],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [-5.064, 5.01],
                                                            [-3.704, 6.907],
                                                            [-2.134, 8.053],
                                                            [-2.589, -8.244],
                                                            [-1.162, -8.873],
                                                            [0.909, -9.407],
                                                            [1.935, -4.707],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [23.953, -20.936],
                                                            [24.214, -21.198],
                                                            [24.133, -21.234],
                                                            [23.819, -21.195],
                                                            [22.99, -20.938],
                                                            [21.07, -20.078],
                                                            [17.013, -17.674],
                                                            [9.091, -11.121],
                                                            [-4.127, 6.961],
                                                            [-12.82, 29.582],
                                                            [-17.054, 54.697],
                                                            [-22.804, 29.017],
                                                            [-23.372, 1.525],
                                                            [-16.417, -26.913],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.049, -52.585],
                                                            [1.013, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 70,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [-0.638, -3.492],
                                                            [-5.606, -4.152],
                                                            [-7.803, -3.956],
                                                            [-8.929, -4.512],
                                                            [9.321, 4.667],
                                                            [8.89, 10.66],
                                                            [1.184, 10.597],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [1.176, 6.431],
                                                            [6.464, 4.787],
                                                            [8.337, 4.227],
                                                            [-9.179, -1.262],
                                                            [-14.4, -7.211],
                                                            [-5.982, -7.173],
                                                            [-0.565, -5.058],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [29.453, -28.311],
                                                            [28.464, -26.698],
                                                            [30.008, -26.234],
                                                            [28.569, -25.07],
                                                            [26.74, -25.063],
                                                            [25.32, -24.328],
                                                            [28.013, -22.174],
                                                            [25.341, -12.371],
                                                            [32.498, 1.586],
                                                            [47.555, 10.957],
                                                            [70.446, 20.947],
                                                            [22.446, 12.267],
                                                            [-1.372, -6.225],
                                                            [-3.417, -28.163],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.05, -52.585],
                                                            [1.014, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 75,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [2.526, -2.501],
                                                            [3.662, -6.916],
                                                            [2.125, -8.051],
                                                            [0.721, -8.61],
                                                            [1.162, 8.877],
                                                            [-0.942, 9.411],
                                                            [-3.862, 9.416],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [-5.064, 5.01],
                                                            [-3.704, 6.907],
                                                            [-2.134, 8.053],
                                                            [-2.589, -8.244],
                                                            [-1.162, -8.873],
                                                            [0.909, -9.407],
                                                            [1.935, -4.707],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [23.953, -20.936],
                                                            [24.214, -21.198],
                                                            [24.133, -21.234],
                                                            [23.819, -21.195],
                                                            [22.99, -20.938],
                                                            [21.07, -20.078],
                                                            [17.013, -17.674],
                                                            [9.091, -11.121],
                                                            [-4.127, 6.961],
                                                            [-12.82, 29.582],
                                                            [-17.054, 54.697],
                                                            [-22.804, 29.017],
                                                            [-23.372, 1.525],
                                                            [-16.417, -26.913],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.049, -52.585],
                                                            [1.013, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 80,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [-0.638, -3.492],
                                                            [-5.606, -4.152],
                                                            [-7.803, -3.956],
                                                            [-8.929, -4.512],
                                                            [9.321, 4.667],
                                                            [8.89, 10.66],
                                                            [1.184, 10.597],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [1.176, 6.431],
                                                            [6.464, 4.787],
                                                            [8.337, 4.227],
                                                            [-9.179, -1.262],
                                                            [-14.4, -7.211],
                                                            [-5.982, -7.173],
                                                            [-0.565, -5.058],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [29.453, -28.311],
                                                            [28.464, -26.698],
                                                            [30.008, -26.234],
                                                            [28.569, -25.07],
                                                            [26.74, -25.063],
                                                            [25.32, -24.328],
                                                            [28.013, -22.174],
                                                            [25.341, -12.371],
                                                            [32.498, 1.586],
                                                            [47.555, 10.957],
                                                            [70.446, 20.947],
                                                            [22.446, 12.267],
                                                            [-1.372, -6.225],
                                                            [-3.417, -28.163],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.05, -52.585],
                                                            [1.014, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 85,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [2.526, -2.501],
                                                            [3.662, -6.916],
                                                            [2.125, -8.051],
                                                            [0.721, -8.61],
                                                            [1.162, 8.877],
                                                            [-0.942, 9.411],
                                                            [-3.862, 9.416],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [-5.064, 5.01],
                                                            [-3.704, 6.907],
                                                            [-2.134, 8.053],
                                                            [-2.589, -8.244],
                                                            [-1.162, -8.873],
                                                            [0.909, -9.407],
                                                            [1.935, -4.707],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [23.953, -20.936],
                                                            [24.214, -21.198],
                                                            [24.133, -21.234],
                                                            [23.819, -21.195],
                                                            [22.99, -20.938],
                                                            [21.07, -20.078],
                                                            [17.013, -17.674],
                                                            [9.091, -11.121],
                                                            [-4.127, 6.961],
                                                            [-12.82, 29.582],
                                                            [-17.054, 54.697],
                                                            [-22.804, 29.017],
                                                            [-23.372, 1.525],
                                                            [-16.417, -26.913],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.049, -52.585],
                                                            [1.013, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                i: { x: 0.667, y: 1 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 90,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [-0.638, -3.492],
                                                            [-5.606, -4.152],
                                                            [-7.803, -3.956],
                                                            [-8.929, -4.512],
                                                            [9.321, 4.667],
                                                            [8.89, 10.66],
                                                            [1.184, 10.597],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [1.176, 6.431],
                                                            [6.464, 4.787],
                                                            [8.337, 4.227],
                                                            [-9.179, -1.262],
                                                            [-14.4, -7.211],
                                                            [-5.982, -7.173],
                                                            [-0.565, -5.058],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [29.453, -28.311],
                                                            [28.464, -26.698],
                                                            [30.008, -26.234],
                                                            [28.569, -25.07],
                                                            [26.74, -25.063],
                                                            [25.32, -24.328],
                                                            [28.013, -22.174],
                                                            [25.341, -12.371],
                                                            [32.498, 1.586],
                                                            [47.555, 10.957],
                                                            [70.446, 20.947],
                                                            [22.446, 12.267],
                                                            [-1.372, -6.225],
                                                            [-3.417, -28.163],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.05, -52.585],
                                                            [1.014, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                            {
                                                t: 95,
                                                s: [
                                                    {
                                                        i: [
                                                            [0, 0],
                                                            [-0.039, 0.058],
                                                            [0.029, 0.011],
                                                            [0.126, -0.031],
                                                            [0.286, -0.092],
                                                            [0.663, -0.338],
                                                            [1.353, -0.923],
                                                            [2.526, -2.501],
                                                            [3.662, -6.916],
                                                            [2.125, -8.051],
                                                            [0.721, -8.61],
                                                            [1.162, 8.877],
                                                            [-0.942, 9.411],
                                                            [-3.862, 9.416],
                                                            [-2.892, 4.529],
                                                            [-1.776, 2.222],
                                                            [-1.022, 1.119],
                                                            [-0.623, 0.595],
                                                            [-0.365, 0.324],
                                                            [-0.249, 0.2],
                                                            [-0.586, 0.408],
                                                        ],
                                                        o: [
                                                            [0.362, -0.265],
                                                            [0.027, -0.052],
                                                            [-0.069, -0.017],
                                                            [-0.228, 0.035],
                                                            [-0.606, 0.237],
                                                            [-1.328, 0.666],
                                                            [-2.731, 1.82],
                                                            [-5.064, 5.01],
                                                            [-3.704, 6.907],
                                                            [-2.134, 8.053],
                                                            [-2.589, -8.244],
                                                            [-1.162, -8.873],
                                                            [0.909, -9.407],
                                                            [1.935, -4.707],
                                                            [1.432, -2.274],
                                                            [0.882, -1.111],
                                                            [0.541, -0.57],
                                                            [0.295, -0.29],
                                                            [0.186, -0.164],
                                                            [0.258, -0.206],
                                                            [0, 0],
                                                        ],
                                                        v: [
                                                            [23.953, -20.936],
                                                            [24.214, -21.198],
                                                            [24.133, -21.234],
                                                            [23.819, -21.195],
                                                            [22.99, -20.938],
                                                            [21.07, -20.078],
                                                            [17.013, -17.674],
                                                            [9.091, -11.121],
                                                            [-4.127, 6.961],
                                                            [-12.82, 29.582],
                                                            [-17.054, 54.697],
                                                            [-22.804, 29.017],
                                                            [-23.372, 1.525],
                                                            [-16.417, -26.913],
                                                            [-9.257, -40.803],
                                                            [-4.471, -47.535],
                                                            [-1.617, -50.872],
                                                            [0.049, -52.585],
                                                            [1.013, -53.492],
                                                            [1.581, -53.988],
                                                            [2.5, -54.697],
                                                        ],
                                                        c: true,
                                                    },
                                                ],
                                            },
                                        ],
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [24.564, 54.947], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 15,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 23,
                                    s: [-20],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 31,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 39,
                                    s: [-20],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 47,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 55,
                                    s: [-20],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 63,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 71,
                                    s: [-20],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 79,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 87,
                                    s: [-20],
                                },
                                { t: 95, s: [0] },
                            ],
                            ix: 10,
                        },
                        p: { a: 0, k: [507.15, 724.397, 0], ix: 2 },
                        a: { a: 0, k: [59.21, 28.098, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0.117, -0.096],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0.784, -0.525],
                                                [1.635, -0.946],
                                                [3.717, -1.4],
                                                [5.563, 0.48],
                                                [2.781, 0.948],
                                                [2.711, 1.541],
                                                [1.184, 1.76],
                                                [1.446, 1.645],
                                                [0.804, 2.896],
                                                [-0.756, 6.586],
                                                [-2.963, -5.206],
                                                [-2.606, -2.042],
                                                [-2.402, -0.428],
                                                [-1.419, 0.131],
                                                [-0.992, 0.388],
                                                [-2.087, 1.638],
                                                [-1.934, 2.102],
                                                [-0.9, 1.112],
                                                [-0.42, 0.559],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0.044, -0.024],
                                            ],
                                            o: [
                                                [-0.186, 0.171],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-0.778, 0.56],
                                                [-1.568, 1.048],
                                                [-3.267, 1.89],
                                                [-3.719, 1.373],
                                                [-3.29, -0.575],
                                                [-2.78, -0.95],
                                                [-2.721, -1.539],
                                                [-1.189, -1.765],
                                                [-2.097, -3.451],
                                                [-1.096, -6.359],
                                                [1.182, 6.511],
                                                [1.767, 2.257],
                                                [2.592, 2.047],
                                                [1.496, 0.215],
                                                [1.423, -0.144],
                                                [1.982, -0.767],
                                                [2.095, -1.647],
                                                [0.968, -1.048],
                                                [0.449, -0.556],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0.028, -0.048],
                                                [0, 0],
                                            ],
                                            v: [
                                                [34.71, 10.158],
                                                [34.239, 10.558],
                                                [33.851, 10.867],
                                                [33.107, 11.438],
                                                [31.654, 12.51],
                                                [28.756, 14.52],
                                                [22.623, 16.825],
                                                [10.595, 21.29],
                                                [-3.534, 23.363],
                                                [-11.453, 21.805],
                                                [-20.237, 17.96],
                                                [-27.397, 12.878],
                                                [-32.265, 7.656],
                                                [-36.543, -1.867],
                                                [-37.563, -19.991],
                                                [-31.177, -4.944],
                                                [-24.803, 1.787],
                                                [-16.992, 5.793],
                                                [-12.731, 5.835],
                                                [-8.699, 4.993],
                                                [-0.982, 1.295],
                                                [6.352, -4.267],
                                                [10.266, -7.427],
                                                [12.465, -8.01],
                                                [13.219, -9.039],
                                                [13.562, -9.535],
                                                [13.712, -9.766],
                                                [13.775, -9.909],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [60.71, 29.348], ix: 2 },
                                    a: { a: 0, k: [25.75, 1.25], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 15,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 23,
                                    s: [-20],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 31,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 39,
                                    s: [-20],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 47,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 55,
                                    s: [-20],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 63,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 71,
                                    s: [-20],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 79,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 87,
                                    s: [-20],
                                },
                                { t: 95, s: [0] },
                            ],
                            ix: 10,
                        },
                        p: { a: 0, k: [704.562, 689.296, 0], ix: 2 },
                        a: { a: 0, k: [0.793, 12.988, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-0.507, -0.33],
                                                [-0.403, -0.304],
                                                [-0.776, -0.622],
                                                [-1.428, -1.313],
                                                [-2.416, -2.994],
                                                [-2.413, -7.237],
                                                [-0.422, -3.684],
                                                [0.381, -3.552],
                                                [3.076, -5.896],
                                                [1.434, 6.01],
                                                [1.291, 2.649],
                                                [1.8, 2.222],
                                                [4.846, 2.869],
                                                [2.576, 1.063],
                                                [1.289, 0.406],
                                                [0.616, 0.162],
                                                [0.298, 0.076],
                                                [-0.075, -0.113],
                                            ],
                                            o: [
                                                [0.823, 0.452],
                                                [0.43, 0.304],
                                                [0.833, 0.607],
                                                [1.552, 1.236],
                                                [2.833, 2.651],
                                                [4.808, 5.985],
                                                [1.207, 3.607],
                                                [0.37, 3.7],
                                                [-0.859, 7.113],
                                                [0.865, -6.612],
                                                [-0.76, -3.003],
                                                [-1.348, -2.638],
                                                [-3.601, -4.452],
                                                [-2.43, -1.43],
                                                [-1.294, -0.515],
                                                [-0.641, -0.206],
                                                [-0.314, -0.091],
                                                [-0.23, -0.033],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-10.141, -38.738],
                                                [-8.51, -37.717],
                                                [-7.228, -36.803],
                                                [-4.831, -34.961],
                                                [-0.393, -31.117],
                                                [7.49, -22.658],
                                                [18.708, -2.725],
                                                [21.172, 8.259],
                                                [21.143, 19.171],
                                                [14.917, 38.738],
                                                [14.152, 19.523],
                                                [11.045, 11.029],
                                                [6.29, 3.741],
                                                [-6.663, -7.142],
                                                [-14.206, -10.883],
                                                [-18.083, -12.302],
                                                [-19.982, -12.864],
                                                [-20.88, -13.087],
                                                [-21.468, -13.132],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [21.792, 38.988], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [564.475, 715.645, 0], ix: 2 },
                        a: { a: 0, k: [8.26, 4.84, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0.72, 0.36],
                                                [0, 0],
                                                [-4.59, 0.27],
                                                [0, 0],
                                                [0, 0],
                                                [1.89, -0.18],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [4.59, -0.27],
                                                [0, 0],
                                                [0, 0],
                                                [-1.89, 0.18],
                                            ],
                                            v: [
                                                [-8.01, -3.96],
                                                [-8.01, 2.34],
                                                [-0.18, 4.32],
                                                [8.01, 2.88],
                                                [7.56, -4.59],
                                                [0, -2.7],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [8.26, 4.84], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [565.645, 730.36, 0], ix: 2 },
                        a: { a: 0, k: [11.32, 22.795, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-0.18, 3.6],
                                                [-5.04, 0.99],
                                                [0, 0],
                                                [-0.36, -3.51],
                                                [0.72, -3.42],
                                                [3.24, 0],
                                                [1.89, 11.16],
                                            ],
                                            o: [
                                                [0, 0],
                                                [5.04, -0.99],
                                                [0, 0],
                                                [0.36, 3.51],
                                                [-0.72, 3.419],
                                                [-3.24, 0],
                                                [-1.89, -11.16],
                                            ],
                                            v: [
                                                [-10.89, -21.555],
                                                [-1.44, -19.485],
                                                [7.65, -22.545],
                                                [9.45, -7.155],
                                                [10.35, 16.965],
                                                [4.23, 22.545],
                                                [-8.1, 8.235],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [11.32, 22.795], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 7,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [591.295, 683.739, 0], ix: 2 },
                        a: { a: 0, k: [7.72, 22.165, 0], ix: 1 },
                        s: {
                            a: 1,
                            k: [
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 12.5,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 15,
                                    s: [100, 10, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 17.5,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 73.75,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 76.25,
                                    s: [100, 10, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 78.75,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 120,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 122.5,
                                    s: [100, 10, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 125,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 166.25,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 168.75,
                                    s: [100, 10, 100],
                                },
                                { t: 171.25, s: [100, 100, 100] },
                            ],
                            ix: 6,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-3.69, -0.359],
                                                [-0.45, 9.989],
                                                [0.99, 2.79],
                                                [3.42, 0.54],
                                                [0.36, -4.05],
                                                [-1.71, -5.67],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0.45, -9.991],
                                                [-0.99, -2.789],
                                                [-3.42, -0.54],
                                                [-0.36, 4.051],
                                                [1.71, 5.67],
                                            ],
                                            v: [
                                                [1.26, 21.915],
                                                [7.02, 10.846],
                                                [5.4, -11.745],
                                                [-1.35, -21.375],
                                                [-7.02, -10.665],
                                                [-5.76, 11.025],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [7.72, 22.165], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 8,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [537.88, 685.224, 0], ix: 2 },
                        a: { a: 0, k: [9.385, 24.821, 0], ix: 1 },
                        s: {
                            a: 1,
                            k: [
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 12.5,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 15,
                                    s: [100, 0, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 17.5,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 73.75,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 76.25,
                                    s: [100, 10, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 78.75,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 120,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 122.5,
                                    s: [100, 10, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 125,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 166.25,
                                    s: [100, 100, 100],
                                },
                                {
                                    i: {
                                        x: [0.667, 0.667, 0.667],
                                        y: [1, 1, 1],
                                    },
                                    o: {
                                        x: [0.333, 0.333, 0.333],
                                        y: [0, 0, 0],
                                    },
                                    t: 168.75,
                                    s: [100, 10, 100],
                                },
                                { t: 171.25, s: [100, 100, 100] },
                            ],
                            ix: 6,
                        },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-2.43, -0.36],
                                                [-1.71, 10.349],
                                                [1.35, 2.7],
                                                [2.97, 0.72],
                                                [0.63, -5.58],
                                                [-2.79, -8.46],
                                            ],
                                            o: [
                                                [0, 0],
                                                [1.71, -10.35],
                                                [-1.35, -2.701],
                                                [-2.97, -0.721],
                                                [-0.63, 5.58],
                                                [2.79, 8.46],
                                            ],
                                            v: [
                                                [-0.225, 24.57],
                                                [7.425, 13.501],
                                                [7.425, -15.659],
                                                [0.225, -23.849],
                                                [-7.425, -13.589],
                                                [-6.345, 14.67],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [9.385, 24.821], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 9,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 17.5,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 20,
                                    s: [100],
                                },
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 22.5,
                                    s: [0],
                                },
                                { t: 25, s: [100] },
                            ],
                            ix: 11,
                        },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [649.855, 554.871, 0], ix: 2 },
                        a: { a: 0, k: [13.03, 5.744, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [2.385, -0.595],
                                                [-1.933, 1.39],
                                                [-2.19, 0.941],
                                                [-2.359, 0.433],
                                                [-2.417, -0.428],
                                                [2.069, -0.973],
                                                [2.073, -0.901],
                                                [2.146, -0.815],
                                            ],
                                            o: [
                                                [1.478, -1.98],
                                                [1.946, -1.373],
                                                [2.182, -0.956],
                                                [2.362, -0.419],
                                                [-2.156, 1.219],
                                                [-2.066, 0.956],
                                                [-2.075, 0.888],
                                                [-2.156, 0.806],
                                            ],
                                            v: [
                                                [-12.78, 5.494],
                                                [-7.536, 0.557],
                                                [-1.308, -2.912],
                                                [5.547, -4.981],
                                                [12.78, -5.066],
                                                [6.467, -1.973],
                                                [0.262, 0.765],
                                                [-6.057, 3.321],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [13.03, 5.744], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 10,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 20,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 22.5,
                                    s: [100],
                                },
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 25,
                                    s: [0],
                                },
                                { t: 27.5, s: [100] },
                            ],
                            ix: 11,
                        },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [625.855, 508.345, 0], ix: 2 },
                        a: { a: 0, k: [8.83, 13.27, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [1.785, -2.002],
                                                [-1.093, 2.391],
                                                [-1.377, 2.24],
                                                [-1.706, 2.039],
                                                [-0.987, 0.918],
                                                [-1.224, 0.547],
                                                [0.646, -1.138],
                                                [0.663, -1.101],
                                                [1.368, -2.197],
                                                [1.486, -2.144],
                                            ],
                                            o: [
                                                [0.686, -2.595],
                                                [1.115, -2.381],
                                                [1.386, -2.236],
                                                [0.861, -1.016],
                                                [0.981, -0.901],
                                                [-0.817, 1.082],
                                                [-0.687, 1.085],
                                                [-1.301, 2.228],
                                                [-1.359, 2.204],
                                                [-1.5, 2.137],
                                            ],
                                            v: [
                                                [-8.58, 13.02],
                                                [-5.8, 5.593],
                                                [-2.068, -1.344],
                                                [2.522, -7.792],
                                                [5.275, -10.704],
                                                [8.58, -13.02],
                                                [6.412, -9.767],
                                                [4.45, -6.434],
                                                [0.486, 0.228],
                                                [-3.76, 6.761],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [8.83, 13.27], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 11,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 17.5,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 20,
                                    s: [100],
                                },
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 22.5,
                                    s: [0],
                                },
                                { t: 25, s: [100] },
                            ],
                            ix: 11,
                        },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [547.375, 520.052, 0], ix: 2 },
                        a: { a: 0, k: [11.11, 10.803, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-0.938, -2.494],
                                                [1.861, 1.705],
                                                [0, 0],
                                                [0, 0],
                                                [0.235, 0.19],
                                                [0, 0],
                                                [1.728, 1.867],
                                                [1.381, 2.281],
                                                [-2.126, -1.416],
                                                [-1.923, -1.653],
                                                [-0.988, -1.135],
                                                [-0.721, -1.073],
                                            ],
                                            o: [
                                                [-2.228, -1.457],
                                                [0, 0],
                                                [0, 0],
                                                [-0.189, -0.165],
                                                [0, 0],
                                                [-1.921, -1.642],
                                                [-1.717, -1.879],
                                                [2.462, 1.014],
                                                [2.117, 1.429],
                                                [0.958, 0.865],
                                                [0.882, 1.025],
                                                [1.424, 2.147],
                                            ],
                                            v: [
                                                [10.86, 10.552],
                                                [4.84, 5.711],
                                                [2.14, 3.174],
                                                [1.522, 2.622],
                                                [0.808, 2.049],
                                                [-0.648, 0.836],
                                                [-6.124, -4.42],
                                                [-10.86, -10.552],
                                                [-4.066, -6.799],
                                                [1.99, -2.169],
                                                [4.864, 0.546],
                                                [7.222, 3.674],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [11.11, 10.803], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 12,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 16.25,
                                    s: [0],
                                },
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 18.75,
                                    s: [100],
                                },
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    t: 21.25,
                                    s: [0],
                                },
                                { t: 23.75, s: [100] },
                            ],
                            ix: 11,
                        },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [540.415, 565.465, 0], ix: 2 },
                        a: { a: 0, k: [11.47, 6.67, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [-1.56, -1.612],
                                                [1.989, 0.862],
                                                [1.871, 1.069],
                                                [1.761, 1.26],
                                                [1.558, 1.615],
                                                [-1.988, -0.864],
                                                [-1.868, -1.072],
                                                [-1.761, -1.261],
                                            ],
                                            o: [
                                                [-2.181, -0.528],
                                                [-1.979, -0.879],
                                                [-1.871, -1.068],
                                                [-1.752, -1.277],
                                                [2.182, 0.525],
                                                [1.979, 0.88],
                                                [1.87, 1.072],
                                                [1.751, 1.277],
                                            ],
                                            v: [
                                                [11.22, 6.42],
                                                [5.026, 4.23],
                                                [-0.745, 1.301],
                                                [-6.196, -2.186],
                                                [-11.22, -6.42],
                                                [-5.024, -4.234],
                                                [0.744, -1.302],
                                                [6.194, 2.19],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [11.47, 6.67], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 13,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [593.045, 555.625, 0], ix: 2 },
                        a: { a: 0, k: [11.07, 1.9, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-10.07, 0.9],
                                                [10.07, -0.9],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "st",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 2, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 10,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [11.07, 1.9], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 14,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [608.575, 571.885, 0], ix: 2 },
                        a: { a: 0, k: [10.66, 18.16, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-9.66, -17.16],
                                                [9.66, 17.16],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "st",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 2, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 10,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [10.66, 18.16], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 15,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [586.615, 577.105, 0], ix: 2 },
                        a: { a: 0, k: [6.74, 26.06, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-0.6, 5.04],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0.6, -5.04],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-1.74, 21.06],
                                                [-0.54, -3.9],
                                                [1.74, -21.06],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "st",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 2, ix: 5 },
                                    lc: 2,
                                    lj: 1,
                                    ml: 10,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [6.74, 26.06], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 16,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [598.375, 556.764, 0], ix: 2 },
                        a: { a: 0, k: [40.34, 45.8, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [2.039, -0.72],
                                                [2.52, 3.96],
                                                [0, 0],
                                                [-2.64, -1.92],
                                                [0.6, -0.72],
                                                [5.64, -2.04],
                                                [3.134, -0.6],
                                                [1.7, 3.72],
                                                [-2.28, 1.08],
                                                [-1.56, -1.08],
                                                [0.6, -1.44],
                                                [8.16, -1.68],
                                                [2.88, 0.72],
                                                [0, 0],
                                                [-0.48, 4.08],
                                                [-0.96, 4.2],
                                                [-1.2, 3.6],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-2.04, 0.72],
                                                [-2.52, -3.96],
                                                [0, 0],
                                                [2.64, 1.92],
                                                [-0.6, 0.72],
                                                [-5.64, 2.04],
                                                [-3.133, 0.6],
                                                [-1.7, -3.72],
                                                [2.28, -1.08],
                                                [1.56, 1.08],
                                                [-0.6, 1.44],
                                                [-8.16, 1.68],
                                                [0, 0],
                                                [0, 0],
                                                [0.48, -4.08],
                                                [0.96, -4.2],
                                                [1.2, -3.6],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-3.18, -40.8],
                                                [13.62, -16.8],
                                                [28.02, 7.44],
                                                [35.34, 21.36],
                                                [24.18, 28.32],
                                                [14.1, 28.32],
                                                [15.18, 21.24],
                                                [22.38, 21],
                                                [22.74, 26.04],
                                                [14.7, 32.28],
                                                [-0.767, 37.68],
                                                [-15.4, 35.4],
                                                [-13.02, 27.96],
                                                [-5.94, 27.24],
                                                [-3.42, 31.56],
                                                [-14.82, 39.12],
                                                [-32.46, 39.72],
                                                [-35.34, 39],
                                                [-32.82, 18.48],
                                                [-28.5, -5.04],
                                                [-25.14, -21.6],
                                                [-19.86, -37.92],
                                            ],
                                            c: false,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "st",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 3,
                                    },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 2, ix: 5 },
                                    lc: 2,
                                    lj: 2,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [40.34, 45.8], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 17,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [583.461, 482.304, 0], ix: 2 },
                        a: { a: 0, k: [8.985, 27.79, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-0.96, -3.12],
                                                [-0.12, -0.6],
                                                [-0.104, -1.016],
                                                [-4.321, 0.36],
                                                [0, 0.96],
                                                [1.44, 0.72],
                                                [0.359, 1.56],
                                                [0.359, 1.681],
                                                [0.26, 1.92],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0.96, 3.12],
                                                [0.119, 0.6],
                                                [0.104, 1.016],
                                                [4.32, -0.36],
                                                [0, -0.959],
                                                [-1.44, -0.72],
                                                [-0.36, -1.56],
                                                [-0.36, -1.68],
                                                [-0.26, -1.92],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-8.734, -27.045],
                                                [-3.746, 0.9],
                                                [0.334, 14.94],
                                                [-1.691, 20.164],
                                                [2.254, 27.18],
                                                [8.373, 23.58],
                                                [7.294, 16.98],
                                                [4.534, 14.34],
                                                [1.534, 0.42],
                                                [-0.486, -11.34],
                                                [-3.266, -27.54],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [8.985, 27.79], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 18,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [581.815, 502.524, 0], ix: 2 },
                        a: { a: 0, k: [20.95, 50.17, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-1.44, -6],
                                                [-0.72, -2.64],
                                                [-1.8, -0.6],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-0.12, 0.72],
                                                [0.48, 5.16],
                                                [0, 0],
                                                [-1.306, 0.72],
                                                [-0.36, 1.56],
                                                [0.12, 1.92],
                                                [0.48, 3.12],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [1.44, 6],
                                                [0.72, 2.64],
                                                [1.8, 0.6],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0.12, -0.72],
                                                [-0.48, -5.16],
                                                [0, 0],
                                                [1.307, -0.72],
                                                [0.36, -1.56],
                                                [-0.12, -1.92],
                                                [-0.48, -3.12],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-20.7, -48],
                                                [-14.22, -14.64],
                                                [-8.34, 10.08],
                                                [-3.9, 17.52],
                                                [1.38, 17.52],
                                                [7.86, 43.44],
                                                [10.62, 49.8],
                                                [11.34, 49.2],
                                                [10.74, 27.6],
                                                [9.54, 15.96],
                                                [15.793, 14.28],
                                                [20.1, 10.44],
                                                [20.58, 3.84],
                                                [14.1, -30],
                                                [10.14, -49.92],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: {
                                        a: 0,
                                        k: [
                                            0,
                                            0.423529416323,
                                            0.631372570992,
                                            1,
                                        ],
                                        ix: 4,
                                    },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [20.95, 50.17], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 19,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [575.32, 435.43, 0], ix: 2 },
                        a: { a: 0, k: [61.225, 14.515, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [4.59, -1.35],
                                                [0, 0],
                                                [0, 0],
                                                [-5.58, 0.54],
                                                [-6.749, 0],
                                                [0, 0],
                                                [0, 2.79],
                                                [0, 0],
                                                [7.02, -0.09],
                                                [14.04, -1.44],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [5.58, -0.54],
                                                [6.75, 0],
                                                [0, 0],
                                                [0, -2.79],
                                                [0, 0],
                                                [-7.02, 0.09],
                                                [-14.04, 1.44],
                                            ],
                                            v: [
                                                [-54.585, -2.745],
                                                [-60.975, 7.335],
                                                [-56.385, 14.265],
                                                [-3.555, 7.605],
                                                [31.274, 4.815],
                                                [60.975, 4.725],
                                                [59.715, -2.745],
                                                [60.975, -12.465],
                                                [38.475, -14.175],
                                                [-5.355, -11.205],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [61.225, 14.515], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 20,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [575.755, 402.895, 0], ix: 2 },
                        a: { a: 0, k: [60.07, 11.86, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-11.7, 1.08],
                                                [-9.9, 0],
                                                [-3.96, -0.36],
                                                [0.36, -1.52],
                                                [0, 0],
                                                [0, 0],
                                                [4.141, 0.54],
                                                [7.38, 0],
                                                [9, -1.2],
                                                [0, 0],
                                                [0, 0.84],
                                                [-0.54, 0.66],
                                                [0.3, 0.72],
                                                [0, 0.6],
                                            ],
                                            o: [
                                                [0, 0],
                                                [11.7, -1.08],
                                                [9.9, 0],
                                                [3.961, 0.36],
                                                [-0.36, 1.52],
                                                [0, 0],
                                                [0, 0],
                                                [-4.139, -0.54],
                                                [-7.38, 0],
                                                [-9, 1.2],
                                                [0, 0],
                                                [0, -0.84],
                                                [0.54, -0.66],
                                                [-0.3, -0.72],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-58.98, -3.33],
                                                [-33.78, -7.29],
                                                [21.3, -11.25],
                                                [44.339, -11.25],
                                                [56.22, -7.37],
                                                [54.06, 0.27],
                                                [59.82, 4.05],
                                                [54.419, 4.05],
                                                [19.14, 2.25],
                                                [-21.54, 4.29],
                                                [-58.98, 11.61],
                                                [-59.82, 10.53],
                                                [-57.06, 6.03],
                                                [-55.44, 2.43],
                                                [-58.98, -2.01],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [60.07, 11.86], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 21,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [574.375, 373.735, 0], ix: 2 },
                        a: { a: 0, k: [57.85, 11.32, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [-12.06, 0.9],
                                                [-7.56, -0.54],
                                                [0, 0],
                                                [3.96, 3.06],
                                                [0, 0],
                                                [13.68, -0.36],
                                                [8.64, -1.26],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [12.06, -0.9],
                                                [7.56, 0.54],
                                                [0, 0],
                                                [-3.96, -3.06],
                                                [0, 0],
                                                [-13.68, 0.36],
                                                [-8.64, 1.26],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-57.6, 11.07],
                                                [-8.46, 3.87],
                                                [33.48, 2.07],
                                                [57.6, 5.31],
                                                [44.46, -7.29],
                                                [37.62, -10.71],
                                                [7.38, -10.71],
                                                [-21.78, -8.91],
                                                [-45.18, -5.49],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [57.85, 11.32], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 22,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [569.335, 353.934, 0], ix: 2 },
                        a: { a: 0, k: [36.43, 9.34, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [1.08, -3.78],
                                                [-13.32, 0.72],
                                                [0, 0],
                                                [1.8, 0.72],
                                                [10.08, -0.54],
                                                [0, 0],
                                            ],
                                            o: [
                                                [0, 0],
                                                [13.32, -0.72],
                                                [0, 0],
                                                [-1.8, -0.72],
                                                [-10.08, 0.54],
                                                [0, 0],
                                            ],
                                            v: [
                                                [-36.18, 9.09],
                                                [4.32, 2.43],
                                                [36.18, 2.43],
                                                [21.24, -7.29],
                                                [-7.02, -8.55],
                                                [-22.14, -6.57],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [36.43, 9.34], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
                {
                    ddd: 0,
                    ind: 23,
                    ty: 4,

                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [598.315, 617.484, 0], ix: 2 },
                        a: { a: 0, k: [131.41, 165.13, 0], ix: 1 },
                        s: { a: 0, k: [100, 100, 100], ix: 6 },
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: "gr",
                            it: [
                                {
                                    ind: 0,
                                    ty: "sh",
                                    ix: 1,
                                    ks: {
                                        a: 0,
                                        k: {
                                            i: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [-4.081, -6.96],
                                                [-4.08, -17.04],
                                                [2.16, -15.84],
                                                [12, -19.439],
                                                [23.28, -12.72],
                                                [2.16, -0.72],
                                                [20.16, 10.319],
                                                [11.52, 19.681],
                                                [0.24, 29.04],
                                                [-14.88, 36.48],
                                            ],
                                            o: [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [0, 0],
                                                [4.08, 6.96],
                                                [4.08, 17.04],
                                                [-2.16, 15.84],
                                                [-12, 19.44],
                                                [-23.28, 12.72],
                                                [-2.161, 0.72],
                                                [-20.16, -10.321],
                                                [-11.52, -19.68],
                                                [-0.24, -29.04],
                                                [14.88, -36.48],
                                            ],
                                            v: [
                                                [-79.56, -156.48],
                                                [-37.56, -161.28],
                                                [-5.64, -163.44],
                                                [14.279, -164.88],
                                                [37.98, -163.68],
                                                [45.72, -164.88],
                                                [70.68, -127.68],
                                                [99.48, -81.36],
                                                [122.04, -30.72],
                                                [129, 36.96],
                                                [112.68, 94.08],
                                                [63.24, 143.04],
                                                [26.52, 160.08],
                                                [-47.64, 154.561],
                                                [-108.12, 97.92],
                                                [-130.92, 17.04],
                                                [-112.68, -76.32],
                                            ],
                                            c: true,
                                        },
                                        ix: 2,
                                    },

                                    hd: false,
                                },
                                {
                                    ty: "fl",
                                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                    o: { a: 0, k: 100, ix: 5 },
                                    r: 1,
                                    bm: 0,

                                    hd: false,
                                },
                                {
                                    ty: "tr",
                                    p: { a: 0, k: [131.41, 165.13], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                },
                            ],

                            np: 2,
                            cix: 2,
                            bm: 0,
                            ix: 1,

                            hd: false,
                        },
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0,
                },
            ],
        },
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 0,

            refId: "comp_0",
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [128, 128, 0], ix: 2 },
                a: { a: 0, k: [600, 600, 0], ix: 1 },
                s: { a: 0, k: [50, 50, 100], ix: 6 },
            },
            ao: 0,
            w: 1200,
            h: 1200,
            ip: 0,
            op: 83,
            st: -11,
            bm: 0,
        },
    ],
    markers: [],
};
