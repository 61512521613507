import React from "react";
import CN from "classnames";
import PT from "prop-types";
import styled from "styled-components";

import { TelegramIcon, TwitterIcon } from "~/src/components/icons";
import { colors, contacts, down, global } from "~/src/config/vars";

const Social = ({ bg }) => {
    return (
        <SSocial
            className={CN(`SSocialBlock`, {
                bg: bg,
            })}
        >
            {contacts.telegram ? (
                <a
                    href={contacts.telegram}
                    className="SFooterTopSocialIcon telegram"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TelegramIcon />
                </a>
            ) : null}
            {contacts.twitter ? (
                <a
                    href={contacts.twitter}
                    className="SFooterTopSocialIcon twitter"
                    target="_blank"
                    rel="noreferrer"
                >
                    <TwitterIcon />
                </a>
            ) : null}
        </SSocial>
    );
};

Social.propTypes = {
    bg: PT.bool,
};

const SSocial = styled.div`
    display: flex;
    align-items: center;
    ${down("sm")} {
    }
    a {
        transition: all ${global.transition};
        color: ${colors.grey_light4};
        @media (hover) {
            &:hover {
                color: ${colors.red};
            }
        }
    }
    .SFooterTopSocialIcon {
        margin-right: 0.8em;
        &:last-child {
            margin-right: 0;
        }
        ${down("sm")} {
        }
        svg {
            width: 1.4em;
            max-height: 1.1em;
        }
        &.telegram {
            svg {
                width: 1.16em;
                max-height: 1.16em;
            }
        }
        &.facebook {
            svg {
                width: 0.6em;
                max-height: 1.1em;
            }
        }
        &.instagram {
            svg {
                width: 1.1em;
                max-height: 1.1em;
            }
        }
        &.twitter {
            svg {
                width: 1.2em;
                max-height: 1.3em;
            }
        }
        &.youtube {
            svg {
                width: 1.3em;
                max-height: 1.1em;
            }
        }
        &:hover {
        }
    }
`;

export default Social;
