import React from "react";
import { position } from "polished";
import styled from "styled-components";

import { ProjectCard } from "~/src/components";

import ill from "./card/ill.webp";
import noise from "./card/noise.png";

const IndigoCard = () => {
    return (
        <ProjectCard
            title="Kyrgyz Indigo"
            desc="LGBT+ organization"
            // href="https://indigo.kg"
            // slug="indigo"
        >
            <SIndigoCard>
                <SLogo
                    viewBox="0 0 253 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.76058e-06 48C2.76058e-06 48 0.357604 42.3799 2.59005 35.497C4.2448 30.3952 7.80758 22.2793 14.9647 14.8024C27.4833 1.72456 43.2394 1.14441e-05 43.2394 1.14441e-05V3.23354C43.2394 3.23354 26.2602 6.02537 16.2597 15.8084C8.98958 22.9205 5.25204 30.1796 3.23756 35.7126C1.2551 41.1576 0.935297 48 0.935297 48H2.76058e-06Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M43.2392 -2.57492e-05V3.23351C43.2392 3.23351 29.3768 5.3892 18.8183 13.5808L17.4536 12.3593C29.3768 1.50896 43.2392 -2.57492e-05 43.2392 -2.57492e-05Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M2.87305 48C2.87305 48 3.56034 45.5725 4.09409 44.0479C7.51186 34.2852 11.1399 29.0779 18.5312 21.8443C28.4432 12.1437 43.2394 9.91617 43.2394 9.91617V13.1497C43.2394 13.1497 31.9627 14.1557 19.8959 23.2096C11.7681 29.3079 6.70162 38.7245 4.74053 44.1198C4.16592 45.7006 3.37583 48 3.37583 48H2.87305Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M43.2392 19.2575C25.6063 23.4864 11.5721 37.3201 7.46973 48H8.18943C8.18943 48 12.5796 37.9652 22.6555 31.6576C32.7314 25.35 43.2392 22.6263 43.2392 22.6263V19.2575Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M43.2392 22.6262V19.2574C43.2392 19.2574 40.1724 20.0227 38.4268 20.6227C36.6632 21.2289 34.0454 22.4191 34.0454 22.4191L38.6423 24.0718C39.9352 23.5688 43.2392 22.6262 43.2392 22.6262Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M43.2396 28.8144C25.6422 24.5748 11.6361 10.7066 7.54199 0H8.26025C8.26025 0 12.6417 10.0599 22.6973 16.3832C32.753 22.7066 43.2396 25.4371 43.2396 25.4371V28.8144Z"
                        fill="#AB80DE"
                    ></path>
                    <path
                        d="M43.2395 25.4371V28.8144C43.2395 28.8144 40.4848 28.1318 38.7145 27.521C36.4878 26.7527 34.2612 25.7246 34.2612 25.7246L38.7145 24C41.0129 24.7904 43.2395 25.4371 43.2395 25.4371Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M0.0717801 0C0.0717801 0 0.428787 5.62009 2.65752 12.503C4.30952 17.6048 7.86639 25.7207 15.0116 33.1976C27.5094 46.2754 43.2393 48 43.2393 48V44.7665C43.2393 44.7665 26.2883 41.9746 16.3045 32.1916C9.04643 25.0795 5.31509 17.8204 3.30396 12.2874C1.32479 6.84242 1.00552 0 1.00552 0H0.0717801Z"
                        fill="#AB80DE"
                    ></path>
                    <path
                        d="M43.2395 9.91599V13.1495C43.2395 13.1495 35.9851 13.7962 26.7195 18.8262L24.2056 17.3172C33.0402 11.4968 43.2395 9.91599 43.2395 9.91599Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M2.94482 0C2.94482 0 3.63212 2.42747 4.16587 3.9521C7.58364 13.7148 11.2117 18.9221 18.6029 26.1557C28.515 35.8563 43.3111 38.0838 43.3111 38.0838V34.8503C43.3111 34.8503 32.0344 33.8443 19.9676 24.7904C11.8399 18.6921 6.7734 9.27551 4.81231 3.88024C4.2377 2.2994 3.44761 0 3.44761 0H2.94482Z"
                        fill="#AB80DE"
                    ></path>
                    <path
                        d="M157.264 38.3477V5.79272H161.896V42.1708C160.321 42.1708 157.379 41.3599 157.264 38.3477Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M204.281 38.3477V5.79272H208.913V42.1708C207.338 42.1708 204.397 41.3599 204.281 38.3477Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M229.989 12.0488C229.989 7.322 233 5.79272 235.664 5.79272H236.59H237.517C240.18 5.79272 243.191 7.322 243.191 12.0488V35.7989C243.191 39.9696 240.412 42.1708 237.517 42.1708H236.59H235.664C232.769 42.1708 229.989 39.9696 229.989 35.7989V12.0488ZM236.59 10.4269C235.934 10.4269 234.622 10.8884 234.622 12.388L234.622 35.3441C234.622 36.7283 235.365 37.305 236.59 37.305C237.815 37.305 238.559 36.7283 238.559 35.3441L238.559 12.388C238.559 10.8884 237.246 10.4269 236.59 10.4269Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M166.76 42.1708V5.79272H170.929L176.024 25.7196V5.79272H180.309V42.1708H176.603L171.276 22.244V42.1708H166.76Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M185.983 5.79272V42.1708H192.237C196.985 42.1708 199.533 39.066 199.533 34.9879V12.2805C199.533 9.15249 196.406 5.79272 192.584 5.79272H185.983ZM192.7 10.4269C192.005 10.4269 190.616 10.8884 190.616 12.388L190.616 35.3441C190.616 36.7283 191.403 37.305 192.7 37.305C193.997 37.305 194.785 36.7283 194.785 35.3441L194.785 12.388C194.785 10.8884 193.395 10.4269 192.7 10.4269Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M218.293 5.79272H223.504V10.4269H216.787V34.7562C216.787 35.7989 217.042 37.4208 218.988 37.4208C220.933 37.4208 221.42 35.9147 221.42 34.7562V23.9818H218.988V19.4635H221.999C225.056 19.4635 225.897 22.3212 225.936 23.6342V35.7989C225.936 37.6525 224.662 42.055 218.988 42.055C214.355 42.055 212.271 38.116 212.271 35.7989V11.8171C212.271 6.9976 216.286 5.79272 218.293 5.79272Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M247.823 13.4377V23.866V24.0939V34.5223C247.823 39.2481 244.812 41.1246 242.959 41.9354C250.139 42.0512 252.571 39.5029 252.571 34.5223V24.0939V23.866V13.4377C252.571 8.457 250.139 5.90873 242.959 6.02453C244.812 6.83534 247.823 8.71181 247.823 13.4377Z"
                        fill="#3868DE"
                    ></path>
                    <path
                        d="M110.247 5.79272H115.458V10.4269H108.742V34.7562C108.742 35.7989 108.996 37.4208 110.942 37.4208C112.887 37.4208 113.374 35.9147 113.374 34.7562V23.9818H110.942V19.4635H113.953C117.01 19.4635 117.852 22.3212 117.89 23.6342V35.7989C117.89 37.6525 116.616 42.055 110.942 42.055C106.31 42.055 104.225 38.116 104.225 35.7989V11.8171C104.225 6.9976 108.24 5.79272 110.247 5.79272Z"
                        fill="#AB80DE"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M69.542 5.79272H73.9042V27.4574H76.2001V42.1708H73.9042V31.9757C72.4502 31.6281 69.6568 30.0062 69.542 26.2989C69.4499 23.322 69.4865 16.966 69.5169 11.6801C69.5301 9.39923 69.542 7.31764 69.542 5.79272ZM82.8582 5.79272H78.496V27.4574H76.2001V42.1708H78.496V31.9757C79.9501 31.6281 82.7434 30.0062 82.8582 26.2989C82.9504 23.322 82.9138 16.966 82.8833 11.6801C82.8702 9.39923 82.8582 7.31764 82.8582 5.79272Z"
                        fill="#AB80DE"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M120.033 5.79272H124.395V27.4574H126.691H128.987V5.79272H133.349C133.349 7.31764 133.361 9.39923 133.374 11.6801C133.405 16.966 133.442 23.322 133.349 26.2989C133.235 30.0062 130.441 31.6281 128.987 31.9757V42.1708H126.691H124.395V31.9757C122.941 31.6281 120.148 30.0062 120.033 26.2989C119.941 23.322 119.978 16.966 120.008 11.6801C120.021 9.39923 120.033 7.31764 120.033 5.79272Z"
                        fill="#AB80DE"
                    ></path>
                    <path
                        d="M135.724 10.311V5.79272H149.158V15.1769C149.158 18.2354 148.077 19.6952 147.536 20.0427C145.838 21.4716 142.209 24.5611 141.283 25.4879C140.356 26.4147 140.125 27.1871 140.125 27.4574V37.4208H149.158V42.1708H135.724V26.994C135.724 24.6769 137.577 22.7074 138.503 22.0123C140.086 20.6993 143.39 17.9342 143.946 17.3781C144.502 16.822 144.641 16.1423 144.641 15.872V10.311H135.724Z"
                        fill="#AB80DE"
                    ></path>
                    <path
                        d="M58.2503 5.79272H53.7339V42.1708H58.1345V23.8659H60.3348C62.373 23.8659 62.7281 26.4919 62.6509 27.805V42.1708H67.0515V27.805C67.0515 23.4489 63.2685 21.8192 61.377 21.5489C64.6196 21.5489 67.0515 18.1891 67.0515 15.6403V5.79272H62.7667C62.7281 7.80086 62.6509 12.5818 62.6509 15.6403C62.6509 18.6988 61.1068 19.3862 60.3348 19.3476H58.2503V5.79272Z"
                        fill="#AB80DE"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M87.5488 5.79272V42.1708H92.0652V23.8659H94.2655C95.0376 23.8659 96.5816 24.7001 96.5816 28.0367V42.1708H100.982V28.0367C100.905 25.9127 99.5926 21.6647 94.9604 21.6647C96.9677 21.3944 100.982 19.5793 100.982 14.9452V12.6281C100.982 9.6159 99.6389 5.79272 94.2655 5.79272H87.5488ZM92.181 10.4269V19.4635H94.4971C95.1534 19.3091 96.5816 17.9574 96.5816 15.4086V13.555C96.5816 12.1647 95.5394 10.4269 93.2233 10.4269H92.181Z"
                        fill="#AB80DE"
                    ></path>
                </SLogo>
                <SIll src={ill} alt="indigo illustration" />
            </SIndigoCard>
        </ProjectCard>
    );
};

const SIndigoCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-image: url(${noise});
    &:before {
        content: "";
        background: linear-gradient(
            180deg,
            rgba(254, 225, 245, 0.9) 0%,
            rgba(247, 240, 255, 0.9) 60.98%
        );
        ${position("absolute", "0", "0", null, null)};
        width: 100%;
        height: 100%;
    }
`;

const SIll = styled.img`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    transition: all 0.2s;
    opacity: 0;
    width: 70%;
    display: block;
    z-index: 2;
    .project_card_hover & {
        opacity: 1;
    }
`;
const SLogo = styled.svg`
    ${position("absolute", "30%", null, null, "1em")};
    transition: all 0.2s;
    opacity: 1;
    width: 70%;
    z-index: 2;
    display: block;
    .project_card_hover & {
        opacity: 0;
    }
`;

export default IndigoCard;
