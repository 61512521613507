import React from "react";
import { position } from "polished";
import styled from "styled-components";

import { ProjectCard } from "~/src/components";

import um_capsule from "./card/um_capsule.svg";
import um_tablet from "./card/um_tablet.svg";
import um_logo from "./card/um_logo.png";

const UmCard = () => {
    return (
        <ProjectCard
            title="Unione Medical Korea"
            desc="Medical tourism"
            slug="um"
            href="http://um.dangercactus.io"
        >
            <SUmCard>
                <SBg />
                <SCapsule />
                <SCapsule2 />
                <STablet />
                <STablet2 />
                <SLogo />
            </SUmCard>
        </ProjectCard>
    );
};

const SUmCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    background-color: #ced6da;
`;
const SBg = styled.div`
    ${position("absolute", "0", "0", "0", "0")};
    transition: all 0.2s ease-in-out;
    background-color: #496b7b;
    opacity: 0;
    .project_card_hover & {
        opacity: 1;
    }
`;
const SCapsule = styled.div`
    ${position("absolute", "3%", null, null, "86%")};
    background-image: url(${um_capsule});
    background-size: cover;
    width: 78px;
    height: 107px;
`;
const SCapsule2 = styled.div`
    ${position("absolute", "55%", null, null, "84%")};
    background-image: url(${um_capsule});
    background-size: cover;
    width: 78px;
    height: 107px;
    transform: rotate(300deg);
`;
const STablet = styled.div`
    ${position("absolute", "-10%", null, null, "4%")};
    background-image: url(${um_tablet});
    background-size: cover;
    width: 120px;
    height: 113px;
`;
const STablet2 = styled.div`
    ${position("absolute", "44%", null, null, "-10%")};
    background-image: url(${um_tablet});
    background-size: cover;
    width: 120px;
    height: 113px;
    transform: rotate(80deg);
`;
const SLogo = styled.div`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    background-image: url(${um_logo});
    background-size: cover;
    width: 100px;
    height: 100px;
    transition: all 0.3s;
    .project_card_hover & {
        transform: translateY(-50%) translateX(-50%) rotate(13deg);
    }
`;

export default UmCard;
