import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";
import { down } from "~/src/config/vars";

import main_poster_fog_1 from "./card/main_poster_fog_1.png";
import main_poster_fog_2 from "./card/main_poster_fog_2.png";

const BlindvilleCard = () => {
    const data = useStaticQuery(graphql`
        query BlindvilleQuery {
            bg: file(name: { eq: "blindville_card" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 370, placeholder: BLURRED)
                }
            }
        }
    `);
    const bg = getImage(data.bg);
    //
    return (
        <ProjectCard
            title="Blindville"
            desc="MYSTICISM. drama. detective"
            href="https://blindville.ideaca.today"
            slug="blindville"
        >
            <SBlindvilleCard>
                <SBg image={bg} alt="blindville" />
                <SFog>
                    <div className="fogs">
                        <div className="img first"></div>
                        <div className="img second"></div>
                    </div>
                </SFog>
            </SBlindvilleCard>
        </ProjectCard>
    );
};

const SBlindvilleCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
`;
const SBg = styled(GatsbyImage)`
    ${position("absolute", 0, 0, 0, 0)};
    z-index: 2;
    transition: all 0.2s;
    filter: grayscale(100%);
    .project_card_hover & {
        filter: grayscale(0);
    }
`;

const SFog = styled.div`
    ${position("absolute", "0", "0", "0", "0")};
    height: 100%;
    width: 100%;
    z-index: 2;
    .fogs {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .img {
        position: absolute;
        height: 100%;
        width: 300vw;
        top: 0;
        left: 0;
    }

    .first {
        background: url("${main_poster_fog_1}") repeat-x;
        background-size: contain;
        background-position: center;
        animation: marquee 80s linear forwards;
        animation-play-state: paused;
        .project_card_hover & {
            animation-play-state: running;
        }
        ${down("md")} {
            animation: marquee 40s linear forwards;
            animation-play-state: paused;
            .project_card_hover & {
                animation-play-state: running;
            }
        }
    }
    .second {
        background: url("${main_poster_fog_2}") repeat-x;
        background-size: contain;
        background-position: center;
        animation: marquee 60s linear forwards;
        animation-play-state: paused;
        .project_card_hover & {
            animation-play-state: running;
        }
        ${down("md")} {
            animation: marquee 30s linear forwards;
            animation-play-state: paused;
            .project_card_hover & {
                animation-play-state: running;
            }
        }
    }
    @keyframes marquee {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-200vw, 0, 0);
        }
    }
`;

export default BlindvilleCard;
