import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

import altkorea_logo from "./card/altkorea_logo.svg";

const AltkoreaCard = () => {
    const data = useStaticQuery(graphql`
        query AltkoreaQuery {
            bg: file(name: { eq: "altkorea_image" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
            hover: file(name: { eq: "altkorea_hover" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);
    const bg = getImage(data.bg);
    const hover = getImage(data.hover);
    //
    return (
        <ProjectCard
            title="ALT Korea"
            desc="Parcel delivery from Korea"
            slug="altkorea"
        >
            <SAltkoreaCard>
                <SBg image={bg} alt="ALT Korea" objectFit="cover" />
                <SHover image={hover} alt="ALT Korea" objectFit="cover" />
                <SLogo src={altkorea_logo} alt="ALT Korea" />
            </SAltkoreaCard>
        </ProjectCard>
    );
};

const SAltkoreaCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SBg = styled(GatsbyImage)`
    ${position("absolute", 0, 0, 0, 0)};
`;

const SHover = styled(GatsbyImage)`
    ${position("absolute", 0, 0, 0, 0)};
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    opacity: 0;
    stroke-dasharray: 0 80;
    animation: altkorea_pink 10s ease-in-out infinite;
    stroke-dashoffset: 10px;
    animation-play-state: paused;
    .project_card_hover & {
        animation-play-state: running;
    }
    @keyframes altkorea_pink {
        0% {
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        20% {
            opacity: 0.4;
        }
        30% {
            opacity: 1;
        }
        40% {
            opacity: 0.4;
        }
        50% {
            opacity: 1;
        }
        60% {
            opacity: 0.4;
        }
        70% {
            opacity: 1;
        }
        80% {
            opacity: 0.4;
        }
        90% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

const SLogo = styled.img`
    width: 140px;
    transition: all 0.3s;
    z-index: 2;
    opacity: 0;
    transform: translateX(100px);
    .project_card_hover & {
        transform: translateX(0);
        opacity: 1;
    }
`;

export default AltkoreaCard;
