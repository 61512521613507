import React from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { graphql, useStaticQuery } from "gatsby";

import { ProjectCard } from "~/src/components";

const AltamiraCard = () => {
    const data = useStaticQuery(graphql`
        query AltamiraQuery {
            bg: file(name: { eq: "altamira_bg2" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 370, placeholder: BLURRED)
                }
            }
        }
    `);
    const calc = (x, y, width, height) => [
        ((x - width / 2) / width) * 300,
        -((y - height / 2) / height) * 300,
    ];

    const trans1 = (x, y) => `translate3d(${x / 150}px,${y / 10}px, 0)`;
    const trans2 = (x, y) => `translate3d(${x / 150}px,${y / -10}px, 0)`;

    const [props, set] = useSpring(() => ({
        xy: [0, 0],
        config: { mass: 8, tension: 1000, friction: 100 },
    }));

    return (
        <ProjectCard
            title="Altamira"
            desc="Corporate website of the hotel"
            image={data.bg}
            grayscale
            slug="altamira"
            href="https://altamira.kg"
        >
            <SAltamiraCard
                onMouseMove={(e) => {
                    const { x, y, width, height } =
                        e.currentTarget.getBoundingClientRect();
                    const c = calc(e.clientX - x, e.clientY - y, width, height);
                    set({
                        xy: c,
                    });
                }}
            >
                <SHover />
                <SCharacters
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400.081 59.999"
                >
                    <g transform="translate(-0.001 0)">
                        <SCharactersPath
                            style={{ transform: props.xy.interpolate(trans1) }}
                            d="M379.736,2.007a2.1,2.1,0,0,0-.807.479c-.766.847-.747.235-.755,24.678l-.007,23.081-6.792-6.78c-3.735-3.729-7-6.859-7.261-6.956a2.234,2.234,0,0,0-2.885,3.038c.372.87,17.988,18.167,18.7,18.357a2.136,2.136,0,0,0,2.308-.794l.516-.641.083-23.666.084-23.667,3.833,3.277c2.108,1.8,4.058,3.48,4.333,3.727s1.25,1.086,2.167,1.863,1.854,1.584,2.083,1.793c1.791,1.631,3.462,1.762,4.329.339.93-1.524.549-2.129-3.55-5.644-4.016-3.442-3.991-3.421-5.448-4.658-.594-.5-1.163-.991-1.264-1.083s-.588-.512-1.083-.935-2.1-1.8-3.567-3.059c-3.493-3-3.735-3.133-5.014-2.749"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <SCharactersPath
                            style={{ transform: props.xy.interpolate(trans1) }}
                            d="M338.083.193a4.105,4.105,0,0,0-.75.616l-.416.432-.05,4.322-.05,4.321-7.089,7.058c-6.742,6.712-7.395,7.318-7.395,6.867,0-1.469-5.384-12-6.57-12.856a2.31,2.31,0,0,0-3.422,1.186c-.22.817-.055,1.365.873,2.894,8.332,13.725,8.807,25.426,1.2,29.63-1.834,1.014-2.439,1.932-2.085,3.165,1.343,4.684,9.618-1.6,11.519-8.745a32.841,32.841,0,0,0,.185-9l-.184-.833,6.49-6.5,6.491-6.5V37.225c0,18.805.028,21.032.265,21.532a2.238,2.238,0,0,0,3.858.123c.484-.739.453-57.228-.032-57.944a2.465,2.465,0,0,0-2.841-.743"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <SCharactersPath
                            style={{ transform: props.xy.interpolate(trans2) }}
                            d="M277.577.174a2.84,2.84,0,0,0-1,.666l-.494.515-.044,28.3c-.035,22.457,0,28.434.167,28.942a2.23,2.23,0,0,0,3.242,1.076c1.028-.627.961.768,1.052-22.156l.083-21.065,6.917,6.855c7.32,7.254,7.269,7.212,8.542,6.958a2.292,2.292,0,0,0,1.622-2.776c-.126-.439-2.15-2.562-8.655-9.074l-8.491-8.5-.051-4.25c-.047-3.945-.074-4.283-.384-4.7a2.421,2.421,0,0,0-2.506-.792"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <SCharactersPath
                            style={{ transform: props.xy.interpolate(trans1) }}
                            d="M226.529,2.087a2.1,2.1,0,0,0-1.29,1.525c-.237,1.2-.06,1.441,3.879,5.376l3.742,3.738-7.01,7-7.01,7.005-3.816-3.784-3.817-3.784h-1.015a2.153,2.153,0,0,0-2.246,2.708c.081.422.929,1.372,3.823,4.287l3.718,3.746-3.717,3.754c-4.368,4.412-4.639,4.958-3.181,6.416s2,1.192,6.438-3.2l3.776-3.745,7.043,7,7.043,7L229.122,50.9c-3.555,3.553-3.774,3.808-3.88,4.513a2.221,2.221,0,0,0,3.117,2.372c.84-.382,26.9-26.464,27.125-27.148.468-1.427,1.052-.766-13.415-15.183C227.554.989,228.059,1.423,226.529,2.087m16.757,20.976,6.892,6.841-6.963,6.964c-3.83,3.831-7.039,6.965-7.132,6.965s-3.3-3.133-7.129-6.962l-6.961-6.962,7-6.969c4.569-4.55,7.067-6.924,7.2-6.842.112.069,3.3,3.2,7.1,6.965"
                            transform="translate(1 1)"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <SCharactersPath
                            style={{ transform: props.xy.interpolate(trans2) }}
                            d="M174.907,2.011a2.175,2.175,0,0,0-1.384,1.472c-.138.413-.19,6.835-.19,23.662v23.1l-6.875-6.858c-6-5.988-6.954-6.871-7.5-6.972a2.164,2.164,0,0,0-2.718,2.714c.122.695,17.606,18.365,18.512,18.709a2.35,2.35,0,0,0,2.779-.867c.177-.31.235-4.948.3-24.16l.084-23.776,2.916,2.5,3.335,2.859c.23.2,1.167,1,2.082,1.776,2.073,1.765,2.159,1.839,4.051,3.47,1.716,1.479,2.3,1.706,3.446,1.33a2.3,2.3,0,0,0,.491-3.947c-.212-.167-.784-.645-1.27-1.063s-3.135-2.685-5.885-5.039-5.412-4.638-5.916-5.076-1.441-1.238-2.082-1.777-1.391-1.189-1.667-1.442a2.46,2.46,0,0,0-2.511-.612"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <SCharactersPath
                            style={{ transform: props.xy.interpolate(trans1) }}
                            d="M122,2.616a1.81,1.81,0,0,0-.726.357c-.383.343-4.422,5.222-8.937,10.8-1.327,1.638-2.62,3.2-2.874,3.479-1.634,1.769-.985,4.25,1.11,4.25,1.131,0,1.488-.249,2.967-2.071,1.616-1.99,3.583-4.391,5.444-6.648l1.349-1.635v9.223c0,8.575-.02,9.242-.291,9.48-.161.142-.967,1-1.792,1.912S116.3,33.9,115.75,34.5c-1.938,2.092-2.865,3.1-3.7,4L110.675,40c-2.665,2.9-2.653,2.966,1.362,7.222,2.232,2.367,3.64,3.866,6.042,6.435.553.591,1.576,1.659,2.275,2.375l1.27,1.3h1.99l1.568-1.6c.863-.881,1.645-1.688,1.739-1.792.324-.361,5.648-6.027,6.834-7.273,4.4-4.623,4.978-3.183-5.625-13.927l-3.3-3.343.044-9.153.043-9.152L126,12.437c7.091,8.8,7.416,9.139,8.753,9.028,2.681-.222,2.548-3.214-.259-5.8-.05-.046-.825-.984-1.723-2.084s-1.9-2.324-2.229-2.72S129.564,9.672,129.1,9.1c-5.625-6.945-5.463-6.8-7.1-6.48m9.2,39.8c.077.13-1.073,1.438-3.167,3.6-1.808,1.869-3.789,3.923-4.4,4.565l-1.115,1.166-.477-.5c-.262-.274-1.631-1.737-3.041-3.249s-3.035-3.238-3.608-3.833c-1.282-1.332-1.231-1.2-.7-1.8,1.411-1.584,2.316-2.584,3.278-3.621.6-.642,1.533-1.654,2.083-2.25s1.374-1.48,1.828-1.965l.827-.882,4.188,4.279c2.3,2.353,4.242,4.371,4.308,4.483"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <SCharactersPath
                            style={{ transform: props.xy.interpolate(trans2) }}
                            d="M53.016,2.046c-1.974.71-1.8,2.216.674,5.871,1.055,1.558,4.892,7.273,8.527,12.7l6.608,9.867.046,12.992.046,12.992.516.641a2.194,2.194,0,0,0,3.646-.277c.33-.57.339-.87.383-13.472l.046-12.888,1.621-2.41c.892-1.325,1.733-2.591,1.871-2.813s3.269-4.9,6.958-10.4c7.219-10.761,7.03-10.42,6.481-11.734a2.269,2.269,0,0,0-3.231-.837C86.767,2.6,84.59,5.788,75.72,19.083c-5.1,7.648-4.484,6.93-5.127,5.958-.288-.435-3.483-5.2-7.1-10.591S56.842,4.525,56.75,4.372c-1.322-2.2-2.334-2.83-3.734-2.326"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                        <SCharactersPath
                            style={{ transform: props.xy.interpolate(trans1) }}
                            d="M18.741,2.011a2.178,2.178,0,0,0-1.385,1.472c-.138.413-.189,6.835-.189,23.664v23.1l-6.792-6.78c-3.735-3.729-7-6.859-7.261-6.956A2.225,2.225,0,0,0,.171,39.447c.307.844,18.123,18.364,18.8,18.491a2.214,2.214,0,0,0,2.26-.828l.516-.641.083-23.67.084-23.67,1.333,1.15c.733.632,2.5,2.145,3.917,3.362s2.771,2.379,3,2.581,1.166,1,2.083,1.779,1.7,1.45,1.75,1.5c1.752,1.854,3.753,2.127,4.662.635.847-1.389.438-2.22-2.216-4.495-1.208-1.036-2.8-2.41-3.542-3.053s-1.565-1.352-1.833-1.574-1.059-.9-1.758-1.5C28.011,8.389,27.184,7.68,25,5.819c-1.274-1.086-2.067-1.77-2.4-2.07-1.918-1.734-2.685-2.079-3.861-1.738"
                            fill="#fff"
                            fillRule="evenodd"
                        />
                    </g>
                </SCharacters>
            </SAltamiraCard>
        </ProjectCard>
    );
};

AltamiraCard.propTypes = {
    xy: PT.array,
    data: PT.object,
};

const SAltamiraCard = styled.div`
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
`;

const SHover = styled.div`
    ${position("absolute", "0", null, null, "0")};
    background-color: rgba(0, 0, 0, 0.26);
    width: 100%;
    height: 100%;
    transition: opacity 0.2s;
    opacity: 0;
`;
const SCharacters = styled(animated.svg)`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    width: 280px;
    height: 110px;
    transition: opacity 0.1s;
`;
const SCharactersPath = styled(animated.path)``;

export default AltamiraCard;
