import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HoverVideoPlayer from "react-hover-video-player";
import Observer from "@researchgate/react-intersection-observer";

import { size_down, down } from "~/src/config/vars";

import video_mp4 from "./images/coursive_video_components.mp4";
import video_webm from "./images/coursive_video_components.webm";
import cursore from "~/src/images/cursore.svg";

const CoursiveVideoCompoents = () => {
    const only_mobile = size_down("md");
    const [play, setPlay] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (only_mobile) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [only_mobile]);

    const handleIntersection = (event) => {
        if (isMobile) {
            if (event.isIntersecting) {
                setPlay(true);
            } else {
                setPlay(false);
            }
        }
    };

    const options = {
        onChange: handleIntersection,
        threshold: 1,
    };

    return (
        <Observer {...options}>
            <SCoursiveVideoCompoents>
                <HoverVideoPlayer
                    videoSrc={[
                        { src: video_webm, type: "video/webm" },
                        { src: video_mp4, type: "video/mp4" },
                    ]}
                    style={{ display: "flex" }}
                    focused={play}
                />
            </SCoursiveVideoCompoents>
        </Observer>
    );
};

const SCoursiveVideoCompoents = styled.div`
    padding: 0 7vw;
    background-color: #000;
    ${down("md")} {
        padding: 0 10vw;
    }
    cursor: url(${cursore}), auto;
    cursor: url(${cursore}) 15 15, move;
`;

export default CoursiveVideoCompoents;
