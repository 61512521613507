import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";
import { colors, down } from "~/src/config/vars";

import crew2crew_planet from "./card/crew2crew_planet.jpg";

const Crew2crewCard = () => {
    const data = useStaticQuery(graphql`
        query Crew2crewQuery {
            right_1: file(
                name: { eq: "crew2crew_right_1" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 50)
                }
            }
            right_2: file(
                name: { eq: "crew2crew_right_2" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 50)
                }
            }
            right_3: file(
                name: { eq: "crew2crew_right_3" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 50)
                }
            }
            fly: file(name: { eq: "crew2crew_fly" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 290)
                }
            }
            cloud1: file(
                name: { eq: "crew2crew_cloud1" }
                ext: { eq: ".png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 110)
                }
            }
            cloud2: file(
                name: { eq: "crew2crew_cloud2" }
                ext: { eq: ".png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 120)
                }
            }
            cloud3: file(
                name: { eq: "crew2crew_cloud3" }
                ext: { eq: ".png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 160)
                }
            }
            logo: file(name: { eq: "crew2crew_logo" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(width: 187)
                }
            }
            planet: file(
                name: { eq: "crew2crew_planet" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 187)
                }
            }
        }
    `);
    const right_1 = getImage(data.right_1);
    const right_2 = getImage(data.right_2);
    const right_3 = getImage(data.right_3);
    const fly = getImage(data.fly);
    const cloud1 = getImage(data.cloud1);
    const cloud2 = getImage(data.cloud2);
    const cloud3 = getImage(data.cloud3);
    const logo = getImage(data.logo);
    return (
        <ProjectCard
            title="Crew2crew"
            desc="Discounts and tours for pilots"
            slug="crew2crew"
            size="big"
        >
            <SCrew2crewCard>
                <SPlanet />
                <SGradient />
                <SFly image={fly} alt="Crew2Crew" objectFit="contain" />
                <SCloud1 image={cloud1} alt="Crew2Crew" objectFit="contain" />
                <SCloud2 image={cloud2} alt="Crew2Crew" objectFit="contain" />
                <SCloud3 image={cloud3} alt="Crew2Crew" objectFit="contain" />
                <SLogo image={logo} alt="Crew2Crew" />
                <SRight>
                    <SGallery>
                        <GatsbyImage image={right_1} alt="Crew2Crew" />
                        <GatsbyImage image={right_2} alt="Crew2Crew" />
                        <GatsbyImage image={right_3} alt="Crew2Crew" />
                    </SGallery>
                    <SButton>
                        <SButtonBlock>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16.188 25.519"
                            >
                                <path
                                    id="layer1"
                                    d="M26,20.006,40,32,26,44.006"
                                    transform="translate(-25.349 -19.247)"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeMiterlimit="10"
                                    strokeWidth="2"
                                />
                            </svg>
                        </SButtonBlock>
                    </SButton>
                </SRight>
            </SCrew2crewCard>
        </ProjectCard>
    );
};

const SCrew2crewCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
`;

const SPlanet = styled.div`
    ${position("absolute", "0", null, null, "0")};
    background: url(${crew2crew_planet}) repeat-x;
    width: 5838px;
    height: 100%;
    animation: slide 30s linear infinite;
    animation-play-state: paused;
    .project_card_hover & {
        animation-play-state: running;
    }
    @keyframes slide {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-1946px, 0, 0);
        }
    }
`;
const SGradient = styled.div`
    ${position("absolute", "0", null, null, "170px")};
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        rgba(222, 250, 252, 0) 0%,
        rgba(222, 250, 252, 0.99) 43%,
        rgba(222, 250, 252, 0.95) 100%
    );
`;
const SFly = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "90px")};
    width: 290px;
    height: 100%;
    ${down("lg")} {
        width: 240px;
        left: 0em;
        right: auto;
    }
`;
const SCloud1 = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "120px")};
    width: 110px;
    height: 100%;
    ${down("lg")} {
        left: 30px;
        top: -40px;
    }
`;
const SCloud2 = styled(GatsbyImage)`
    ${position("absolute", "20px", null, null, "310px")};
    width: 120px;
    height: 100%;
    ${down("lg")} {
        left: 170px;
    }
`;
const SCloud3 = styled(GatsbyImage)`
    ${position("absolute", "140px", null, null, "60px")};
    width: 160px;
    height: 100%;
    ${down("lg")} {
        top: 100px;
        width: 89px;
    }
`;
const SLogo = styled(GatsbyImage)`
    ${position("absolute", "50%", "9em", null, null)};
    transform: translateY(-50%);
    width: 187px;
    height: 110px;
    ${down("lg")} {
        display: none;
    }
`;
const SRight = styled.div`
    ${position("absolute", "50%", "-3em", null, null)};
    transform: translateY(-50%);
    background-size: cover;
    width: 107px;
    height: 80%;
    transition: all 0.1s;
    .project_card_hover & {
        transform: translateY(-50%) translateX(-1em);
    }
`;
const SGallery = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    > div {
        ${position("absolute", "50%", null, null, "0")};
        transform: translateY(-50%);
        border-radius: 8px;
        opacity: 1;
        &:nth-of-type(1) {
            width: 40px;
            height: 80%;
            transition: all 0.4s 0.1s;
        }
        &:nth-of-type(2) {
            width: 40px;
            height: 90%;
            left: 0.8em;
            transition: all 0.4s 0.2s;
        }
        &:nth-of-type(3) {
            width: 40px;
            height: 100%;
            left: 1.6em;
            transition: all 0.4s 0.3s;
        }
    }
    .project_card_hover & {
        > div {
            transform: translateY(-50%) translateX(0.2em);
        }
    }
`;
const SButton = styled.div`
    ${position("absolute", "50%", null, null, "-1.5em")};
    transform: translateY(-50%);
    border: 1px solid #ff0084;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    padding: 0.5em;
    transition: all 0.1s;
    .project_card_hover & {
        transform: translateY(-50%);
        border: 1px solid #0063db;
    }
`;
const SButtonBlock = styled.div`
    width: 1.9em;
    height: 1.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ff0084;
    box-shadow: 4px 4px 15px 0px #ff0084;
    transition: all 0.1s;
    svg {
        color: ${colors.white};
        width: 0.8em;
        height: 0.8em;
    }
    .project_card_hover & {
        background-color: #0063db;
        box-shadow: -1px -1px 20px 0px transparent;
    }
`;

export default Crew2crewCard;
