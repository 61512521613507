import React, { useState, useEffect } from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";
import Lottie from "react-lottie";
import Observer from "@researchgate/react-intersection-observer";

import { ProjectCard } from "~/src/components";
import { size_down } from "~/src/config/vars";
import idea_logo from "./card/idea_logo.svg";

import { we } from "./card/we";

const IdeacaCard = () => {
    const only_mobile = size_down("md");
    const [isStopped, setIsStopped] = useState(true);
    // const [isPaused, setIsPaused] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (only_mobile) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [only_mobile]);

    const handleIntersection = (event) => {
        if (isMobile) {
            if (event.isIntersecting) {
                setIsStopped(false);
            } else {
                setIsStopped(true);
            }
        }
    };

    const options = {
        onChange: handleIntersection,
        threshold: 1,
    };

    const defaultOptions = {
        loop: true,
        autoplay: false,
        animationData: we,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <ProjectCard
            title="Idea CA"
            desc="Debate technology"
            slug="ideaca"
            href="https://ideaca.today"
        >
            <Observer {...options}>
                <SIdeacaCard
                    onMouseEnter={() => setIsStopped(false)}
                    onMouseLeave={() => setIsStopped(true)}
                >
                    <SIcon>
                        <Lottie
                            width={"6em"}
                            height={"6em"}
                            options={defaultOptions}
                            isStopped={isStopped}
                            // isPaused={isPaused}
                        />
                    </SIcon>
                    <SLogo src={idea_logo} alt="ideaca logo svg" />
                </SIdeacaCard>
            </Observer>
        </ProjectCard>
    );
};

IdeacaCard.propTypes = {
    xy: PT.array,
};

const SIdeacaCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    background-color: #006ca1;
`;
const SIcon = styled.div`
    ${position("absolute", null, "0", "1em", null)};
`;
const SLogo = styled.img`
    ${position("absolute", "1em", null, null, "1em")};
    width: 180px;
    transition: all 0.3s;
    z-index: 2;
`;

export default IdeacaCard;
