import React from "react";

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.963 6.015">
            <path
                d="M5.73.52,2.606,3.862,1.267,2.429.017,3.766,2.606,6.535,6.98,1.857,5.73.52h0Z"
                transform="translate(-0.017 -0.52)"
                fill="currentColor"
            />
        </svg>
    );
};

export default CheckIcon;
