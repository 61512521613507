import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

import lovelydom_logo from "./card/lovelydom_logo.png";
import bulb1 from "./card/bulb1.png";
import bulb2 from "./card/bulb2.png";
import bulb3 from "./card/bulb3.png";
import bulb4 from "./card/bulb4.png";
import bulb5 from "./card/bulb5.png";

const LovelydomCard = () => {
    const data = useStaticQuery(graphql`
        query LovelydomQuery {
            bg: file(name: { eq: "lovelydom_bg" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
        }
    `);
    const bg = getImage(data.bg);
    return (
        <ProjectCard
            title="Lovely Dom"
            desc="Chain of decor shops"
            slug="lovelydom"
        >
            <SLovelydomCard>
                <SBg image={bg} alt="Lovely Dom" />
                <SLogo />
                <SBulb className="SBulb1" />
                <SBulb className="SBulb2" />
                <SBulb className="SBulb3" />
                <SBulb className="SBulb4" />
                <SBulb className="SBulb5" />
                <SBulb className="SBulb6" />
                <SBulb className="SBulb7" />
                <SBulb className="SBulb8" />
                <SBulb className="SBulb9" />
                <SBulb className="SBulb10" />
                <SBulb className="SBulb11" />
            </SLovelydomCard>
        </ProjectCard>
    );
};

const SLovelydomCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
`;
const SBg = styled(GatsbyImage)`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
`;
const SLogo = styled.div`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    background-image: url(${lovelydom_logo});
    background-size: cover;
    width: 267px;
    height: 80px;
`;
const SBulb = styled.div`
    position: absolute;
    filter: brightness(110%);
    transition: all 0.1s;
    background-size: cover;
    opacity: 0;
    .project_card_hover & {
        animation: lovelydom_bulb 2s infinite;
    }
    &.SBulb1 {
        background-image: url(${bulb1});
        width: 46px;
        height: 46px;
        left: 44.2%;
        top: -7%;
    }
    &.SBulb2 {
        background-image: url(${bulb2});
        width: 40px;
        height: 40px;
        left: 72.5%;
        top: -6%;
        transition-delay: 0.1s;
    }
    &.SBulb3 {
        background-image: url(${bulb3});
        width: 34px;
        height: 34px;
        left: 54.5%;
        bottom: -5%;
        transition-delay: 0.3s;
    }
    &.SBulb4 {
        background-image: url(${bulb4});
        width: 44px;
        height: 34px;
        left: 59%;
        bottom: 6%;
        transform: rotate(-24deg);
        transition-delay: 0.03s;
        filter: hue-rotate(40deg);
    }
    &.SBulb5 {
        background-image: url(${bulb5});
        width: 34px;
        height: 44px;
        left: 81%;
        bottom: -5%;
        transform: rotate(-4deg);
        transition-delay: 0.1s;
        filter: hue-rotate(240deg);
    }
    &.SBulb6 {
        background-image: url(${bulb2});
        width: 44px;
        height: 43px;
        left: 4.2%;
        top: -3.3%;
        transition-delay: 0.2s;
        filter: hue-rotate(150deg);
    }
    &.SBulb7 {
        background-image: url(${bulb2});
        width: 46px;
        height: 46px;
        left: 13.2%;
        top: -9.3%;
        transition-delay: 0.06s;
    }
    &.SBulb8 {
        background-image: url(${bulb2});
        width: 46px;
        height: 46px;
        left: 24.2%;
        top: 6.9%;
        transition-delay: 0.2s;
        filter: hue-rotate(320deg);
    }
    &.SBulb9 {
        background-image: url(${bulb2});
        width: 46px;
        height: 46px;
        left: 53.2%;
        top: -5.3%;
        transition-delay: 0.3s;
        filter: hue-rotate(190deg);
    }
    &.SBulb10 {
        background-image: url(${bulb2});
        width: 36px;
        height: 36px;
        left: 41.2%;
        top: 93.7%;
        transition-delay: 0.3s;
    }
    &.SBulb11 {
        background-image: url(${bulb2});
        width: 36px;
        height: 36px;
        left: 13.2%;
        top: 93%;
        transition-delay: 0.05s;
        filter: hue-rotate(310deg);
    }
    @keyframes lovelydom_bulb {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 0.8;
        }
        100% {
            opacity: 0.2;
        }
    }
`;

export default LovelydomCard;
