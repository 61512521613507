import styled from "styled-components";
import { position } from "polished";

import { colors, global } from "~/src/config/vars";

export const SFormFieldFucus = {
    borderColor: colors.grey,
};

export const SFormFieldError = {
    borderColor: colors.red,
};

export const SErrorTextTooltip = styled.div`
    ${position("absolute", "0.8em", "15px", null, null)};
    z-index: 4;
    .SSelectBlock & {
        display: none;
    }
`;

export const SHelp = styled.div`
    ${position("absolute", "0.8em", "15px", null, null)};
    z-index: 4;
`;
export const STooltip = styled.div`
    display: inline;
    -webkit-perspective: 500px;
    perspective: 500px;
`;
export const STooltipIcon = styled.div`
    width: 1.4em;
    height: 1.4em;
    color: ${colors.grey_light2};
    transition: ${global.transition};
    ${SErrorTextTooltip} & {
        color: ${colors.black};
    }
    ${SErrorTextTooltip}:hover & {
        color: ${colors.red};
    }
    ${SHelp}:hover & {
        color: ${colors.yellow};
    }
`;
export const STooltipContent = styled.div`
    ${position("absolute", null, "-8px", null, null)};
    transition: all 200ms ease;
    transform: translate3d(0, 0%, 0);
    transform-origin: 0 10px;
    background-color: ${colors.black_light2};
    border-radius: 4px;
    color: ${colors.white};
    display: block;
    font-size: 14px;
    font-weight: normal;
    opacity: 0;
    padding: 0.6em 1.2em;
    pointer-events: none;
    text-align: left;
    bottom: 100%;
    min-width: 296px;
    ${SHelp}:hover &, ${SErrorTextTooltip}:hover & {
        transform: translate3d(0, -12px, 0);
        opacity: 1;
        pointer-events: auto;
    }
    &:before {
        content: "";
        ${position("absolute", null, "10px", "-20px", null)};
        border-color: transparent;
        border-style: solid;
        border-width: 10px;
        display: block;
        height: 0;
        margin-left: -10px;
        width: 0;
        border-top-color: ${colors.black_light2};
    }
    &:after {
        content: "";
        ${position("absolute", "0", "10px", null, null)};
        display: block;
        width: 100%;
        height: 20px;
    }
`;
