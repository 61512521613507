import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

import { ProjectCard } from "~/src/components";

const MediajasaCard = () => {
    const data = useStaticQuery(graphql`
        query MapincidentsQuery {
            bg: file(
                name: { eq: "mapincidents_card_hover" }
                ext: { eq: ".jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 370, placeholder: BLURRED)
                }
            }
        }
    `);
    return (
        <ProjectCard
            title="COVID-19 and discrimination"
            desc="Xenophobia, intolerance and discrimination"
            image={data.bg}
            grayscale
            slug="mapincidents"
            href="https://mapincidents.kg"
        >
            <SMediajasaCard />
        </ProjectCard>
    );
};

const SMediajasaCard = styled.div``;

export default MediajasaCard;
