import React from "react";
import PT from "prop-types";
import { position } from "polished";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { ProjectCard } from "~/src/components";
import deure_logo from "./card/deure_logo.svg";

const DeureCard = () => {
    const calc = (x, y, width, height) => [
        ((x - width / 2) / width) * 300,
        -((y - height / 2) / height) * 300,
    ];

    const trans1 = (x, y) => `translate3d(${x / 24}px,${y / 16}px,0)`;
    const trans2 = (x, y) => `translate3d(${x / 16}px,${y / 24}px,0)`;
    const trans3 = (x, y) => `translate3d(${x / 24}px,${y / 16}px,0)`;

    const [props, set] = useSpring(() => ({
        xy: [0, 0],
        config: { mass: 8, tension: 1000, friction: 100 },
    }));
    return (
        <ProjectCard
            title="DE URE consult"
            desc="Law firm"
            slug="deure"
            href="http://deure.kg"
        >
            <SDeureCard
                onMouseMove={(e) => {
                    const { x, y, width, height } =
                        e.currentTarget.getBoundingClientRect();
                    const c = calc(e.clientX - x, e.clientY - y, width, height);
                    set({
                        xy: c,
                    });
                }}
            >
                <SFigures
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 500 348"
                >
                    <defs>
                        <clipPath id="clip-deure">
                            <rect width="500" height="348" />
                        </clipPath>
                    </defs>
                    <g id="deure" clipPath="url(#clip-deure)">
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans1) }}
                        >
                            <SFigurePath
                                className="figure20"
                                d="M28.256,84.073l55.7-56.512V0L0,84.073Z"
                                transform="translate(165 171)"
                                fill="#4150e5"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans2) }}
                        >
                            <SFigurePath
                                className="figure19"
                                d="M-8806.42,1467.08h-15.595L-8995,1640.88v14.589h15.126l173.453-174.728Z"
                                transform="translate(9207.992 -1448.156)"
                                fill="#fa5a67"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans3) }}
                        >
                            <SFigurePath
                                className="figure18"
                                d="M28.256,84.073l55.7-56.512V0L0,84.073Z"
                                transform="translate(349 9)"
                                fill="#fa5a67"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans1) }}
                        >
                            <SFigurePath
                                className="figure17"
                                d="M28.256,84.073l55.7-56.512V0L0,84.073Z"
                                transform="translate(64 27)"
                                fill="#4150e5"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans2) }}
                        >
                            <SFigurePath
                                className="figure16"
                                d="M-8965.09,1602.1v-29.669L-8995,1602.1Z"
                                transform="translate(9319.996 -1391.865)"
                                fill="#fa5a67"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans3) }}
                        >
                            <SFigurePath
                                className="figure15"
                                d="M-8965.09,1602.1v-29.669L-8995,1602.1Z"
                                transform="translate(9339.996 -1458.865)"
                                fill="#4150e5"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans1) }}
                        >
                            <SFigurePath
                                className="figure14"
                                d="M57.847,0,0,58.074H29.326L57.847,28.625Z"
                                transform="translate(273.407 113.421)"
                                fill="none"
                                stroke="#fa5a67"
                                strokeWidth="2"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans2) }}
                        >
                            <SFigurePath
                                className="figure13"
                                d="M-8903.74,1570.9l30.237-29.985v-30.238l-60.329,60.222Z"
                                transform="translate(9132.664 -1328.359)"
                                fill="#fa5a67"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans3) }}
                        >
                            <SFigurePath
                                className="figure12"
                                d="M0,0V29.669L29.9,0Z"
                                transform="translate(162 103)"
                                fill="#4150e5"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans1) }}
                        >
                            <SFigurePath
                                className="figure11"
                                d="M0,0V29.669L29.9,0Z"
                                transform="translate(239 71)"
                                fill="none"
                                stroke="#fa5a67"
                                strokeWidth="2"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans2) }}
                        >
                            <SFigurePath
                                className="figure10"
                                d="M57.847,0,0,58.074H29.326L57.847,28.625Z"
                                transform="translate(131.407 42.421)"
                                fill="none"
                                stroke="#fa5a67"
                                strokeWidth="2"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans3) }}
                        >
                            <SFigurePath
                                className="figure9"
                                d="M57.847,0,0,58.074H29.326L57.847,28.625Z"
                                transform="translate(265.847 188.074) rotate(180)"
                                fill="#fa5a67"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans1) }}
                        >
                            <SFigurePath
                                className="figure8"
                                d="M60.377,0,0,61.26V91.137L91.007,0Z"
                                transform="translate(107 145)"
                                fill="none"
                                stroke="#4150e5"
                                strokeWidth="2"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans2) }}
                        >
                            <SFigurePath
                                className="figure7"
                                d="M60.377,0,0,61.26V91.137L91.007,0Z"
                                transform="translate(254 218)"
                                fill="none"
                                stroke="#4150e5"
                                strokeWidth="2"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans3) }}
                        >
                            <SFigurePath
                                className="figure6"
                                d="M55.7,0,0,56.512V84.073L83.955,0Z"
                                transform="translate(269 44)"
                                fill="#4150e5"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans1) }}
                        >
                            <SFigurePath
                                className="figure5"
                                d="M0,0V29.669L29.9,0Z"
                                transform="translate(361 174)"
                                fill="#4150e5"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans2) }}
                        >
                            <SFigurePath
                                className="figure4"
                                d="M-8869.34,1467.62l-27.769,28.093H-8925.2l28.094-28.093Z"
                                transform="translate(9214.402 -1293.24)"
                                fill="#4150e5"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans3) }}
                        >
                            <SFigurePath
                                className="figure3"
                                d="M57.847,0,0,58.074H29.326L57.847,28.625Z"
                                transform="translate(417.847 245.074) rotate(180)"
                                fill="#fa5a67"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans1) }}
                        >
                            <SFigurePath
                                className="figure2"
                                d="M28.256,84.073l55.7-56.512V0L0,84.073Z"
                                transform="translate(119 255)"
                                fill="#fa5a67"
                            />
                        </SFigureG>
                        <SFigureG
                            style={{ transform: props.xy.interpolate(trans2) }}
                        >
                            <SFigurePath
                                className="figure1"
                                d="M-8869.34,1467.62l-27.769,28.093H-8925.2l28.094-28.093Z"
                                transform="translate(9061.402 -1285.24)"
                                fill="#fa5a67"
                            />
                        </SFigureG>
                    </g>
                </SFigures>
                <SLogo src={deure_logo} alt="Deure" />
            </SDeureCard>
        </ProjectCard>
    );
};

DeureCard.propTypes = {
    xy: PT.array,
};

const SDeureCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    background-color: #1724a9;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SFigures = styled.svg`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    opacity: 1;
    .project_card_hover & {
        opacity: 0.7;
    }
`;
const SFigureG = styled(animated.g)``;
const SFigurePath = styled.path`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    opacity: 1;
    .project_card_hover & {
        opacity: 0.7;
    }
`;
const SLogo = styled.img`
    ${position("absolute", "50%", null, null, "50%")};
    transform: translateY(-50%) translateX(-50%);
    transition: all 0.2s;
    opacity: 0;
    width: 70%;
    display: block;
    .project_card_hover & {
        opacity: 1;
    }
`;

export default DeureCard;
