import React, { useState } from "react";
import CN from "classnames";
import PT from "prop-types";
import styled from "styled-components";

import {
    SFormFieldError,
    SFormFieldFucus,
} from "~/src/components/form/form_field_styles";
import BaseFormField from "~/src/components/form/BaseFormField";

const FormTextInput = ({ field, form, ...rest }) => {
    const [focused, setFocus] = useState(false);
    const onFocus = () => {
        setFocus(true);
    };
    const onBlur = (e) => {
        field.onBlur(e);
        setFocus(false);
    };
    const meta = form.getFieldMeta(field.name);
    const focus = !!(focused || field.value);
    return (
        <BaseFormField focus={focus} {...rest}>
            <SFormFieldInput
                className={CN(`SFormFieldInput`, {
                    focus,
                    input_error: meta.error,
                })}
                type="text"
                {...field}
                {...rest}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </BaseFormField>
    );
};

FormTextInput.propTypes = {
    field: PT.object,
    form: PT.object,
};

const SFormFieldInput = styled.input`
    &.fucus {
        ${SFormFieldFucus};
    }
    &.input_error {
        ${SFormFieldError};
    }
    &.focus {
        padding-top: 18px;
    }
`;

export default FormTextInput;
