import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

import { ProjectCard } from "~/src/components";

import orionhotel_logo from "./card/orionhotel_logo.svg";

const OrionhotelCard = () => {
    const data = useStaticQuery(graphql`
        query OrionhotelQuery {
            bg: file(name: { eq: "orionhotel_card" }, ext: { eq: ".jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 370, placeholder: BLURRED)
                }
            }
        }
    `);
    return (
        <ProjectCard
            title="Orion Hotel"
            desc="Hotel in the Bishkek"
            image={data.bg}
            grayscale
            href="https://www.orionbishkek.com"
            slug="orionhotel"
        >
            <SOrionhotelCard>
                <SLogo src={orionhotel_logo} alt="orion logo svg" />
            </SOrionhotelCard>
        </ProjectCard>
    );
};

const SOrionhotelCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
`;
const SLogo = styled.img`
    width: 140px;
    transition: all 0.3s;
    opacity: 0;
    transform: translateY(-30px);
    .project_card_hover & {
        transform: translateY(0);
        opacity: 1;
    }
`;

export default OrionhotelCard;
