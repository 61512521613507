import React, { useState, useEffect } from "react";
import PT from "prop-types";
import CN from "classnames";
import { position } from "polished";
import styled from "styled-components";
import { Link } from "gatsby";
import Observer from "@researchgate/react-intersection-observer";

import { NewWindowIcon } from "~/src/components/icons";
import { ProjectCardImage, Anim } from "~/src/components";
import { colors, down, size_down, fonts } from "~/src/config/vars";

const ProjectCard = ({
    size = "default",
    title,
    desc,
    slug,
    href,
    //
    image,
    image_hover,
    grayscale,
    children,
}) => {
    const only_mobile = size_down("md");
    const [hover, setHover] = useState(false);
    const [visible, setVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const handleHover = () => {
        setHover(true);
    };
    const handleLeave = () => {
        setHover(false);
    };
    const handleVisibility = ({ isIntersecting }) => {
        setVisible(isIntersecting);
    };

    useEffect(() => {
        if (only_mobile) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [only_mobile]);

    return (
        <Observer onChange={handleVisibility} threshold={1}>
            <SProjectCard
                className={CN({
                    big: size === "big",
                    project_card_hover:
                        (!isMobile && hover) || (isMobile && visible),
                })}
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
            >
                <Anim>
                    <SBlock>
                        {slug ? (
                            <Link to={`/projects/${slug}`}>
                                {children}
                                {image && (
                                    <ProjectCardImage
                                        title={title}
                                        image={image}
                                        image_hover={image_hover}
                                        grayscale={grayscale}
                                    />
                                )}
                            </Link>
                        ) : href ? (
                            <a href={href} target={"_blank"} rel="noreferrer">
                                {children}
                                {image && (
                                    <ProjectCardImage
                                        title={title}
                                        image={image}
                                        image_hover={image_hover}
                                        grayscale={grayscale}
                                    />
                                )}
                            </a>
                        ) : (
                            <>
                                {children}
                                {image && (
                                    <ProjectCardImage
                                        title={title}
                                        image={image}
                                        image_hover={image_hover}
                                        grayscale={grayscale}
                                    />
                                )}
                            </>
                        )}

                        <SInfo>
                            <SInfoTop>
                                <STitle>{title}</STitle>
                                {href ? (
                                    <SLink
                                        href={href}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <NewWindowIcon />
                                    </SLink>
                                ) : null}
                            </SInfoTop>
                            <SDesc>{desc}</SDesc>
                        </SInfo>
                    </SBlock>
                </Anim>
            </SProjectCard>
        </Observer>
    );
};

ProjectCard.propTypes = {
    data: PT.object,
    title: PT.string,
    desc: PT.string,
    slug: PT.string,
    href: PT.string,
    children: PT.any,
    size: PT.oneOf(["default", "big"]),
    //
    image: PT.object,
    image_hover: PT.object,
    grayscale: PT.bool,
};

const SProjectCard = styled.div`
    background-color: ${colors.black};
    width: calc(100% / 3);
    &.big {
        width: calc(100% - (100% / 3));
    }
    > div {
        display: contents;
    }
    ${down("lg")} {
        width: 50%;
        &.big {
            width: 50%;
        }
    }
    ${down("sm")} {
        width: 100%;
        &:last-of-type {
            margin-bottom: 0;
        }
        &.big {
            width: 100%;
        }
    }
`;

const SBlock = styled.div`
    position: relative;
    overflow: hidden;
    /* cursor: pointer; */
    display: block;
    height: 0;
    padding-top: 70%;
    .big & {
        height: 100%;
        padding-top: 0;
        ${down("lg")} {
            height: 0;
            padding-top: 70%;
        }
    }
`;
const SInfo = styled.div`
    ${position("absolute", null, null, "1em", "1em")};
    z-index: 4;
    width: 100%;
    pointer-events: none;
`;
const SInfoTop = styled.div`
    ${position("absolute", null, null, "0", "0")};
    display: flex;
    transition: all 0.2s ease-in-out;
    @media (hover) {
        ${SBlock}:hover & {
            bottom: 2em;
        }
    }
`;
const STitle = styled.div`
    background-color: ${colors.black};
    color: ${colors.white};
    display: inline-block;
    padding: 0.3em 0.5em;
    font-size: ${fonts.fs_18};
    font-weight: ${fonts.fw_bold};
    align-items: stretch;
`;
const SLink = styled.a`
    display: flex;
    align-items: center;
    background-color: ${colors.black};
    color: ${colors.white};
    margin-left: 0.3em;
    padding: 0 0.3em;
    pointer-events: all;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    ${SBlock}:hover & {
        opacity: 1;
    }
    svg {
        width: 1.2em;
    }
    ${down("sm")} {
        display: none;
    }
`;

const SDesc = styled.div`
    ${position("absolute", null, null, "0", "0")};
    background: ${colors.white};
    font-size: ${fonts.fs_16};
    color: ${colors.black};
    padding: 0.2em 0.4em;
    transition: all 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    transform: translateY(20px);
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.21);
    font-variation-settings: "ital" 100;
    @media (hover) {
        ${SBlock}:hover & {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            font-variation-settings: "ital" 500;
        }
    }
`;

export default ProjectCard;
