import React from "react";
import PT from "prop-types";
import styled from "styled-components";
import { position } from "polished";
import { animated as div } from "react-spring";

const CoursiveCardCharacter = ({ interpBg, interpSpot, background }) => {
    return (
        <SCoursiveCardCharacter>
            <div.svg
                viewBox="0 0 192 143"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: interpBg }}
            >
                <path
                    className="character"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M96 116.856C104.867 121.107 112.375 125.438 119.099 129.316C132.399 136.988 142.626 142.888 154.219 142.888C164.22 142.888 165.258 135.03 165.258 135.03C165.258 135.03 176.988 142.17 185.499 134.546C204.148 117.829 180.154 38.7777 145.52 15.1087C129.152 3.92949 111.958 -0.639495 96 0.0713834C80.0415 -0.639495 62.8476 3.92949 46.4799 15.1087C11.8463 38.7777 -12.1477 117.829 6.50065 134.546C15.0124 142.17 26.7418 135.03 26.7418 135.03C26.7418 135.03 27.7799 142.888 37.7812 142.888C49.3738 142.888 59.6014 136.988 72.9015 129.316C79.6247 125.438 87.1332 121.107 96 116.856Z"
                    style={{
                        fill: background ? `${background}` : null,
                    }}
                />
                <div.path
                    transform={interpSpot}
                    d="M70.8406 32.5168C70.8007 29.3329 69.2533 26.7697 67.3843 26.7916C65.5153 26.8136 64.0325 29.4125 64.0723 32.5964C64.1121 35.7803 65.6596 38.3435 67.5286 38.3216C69.3976 38.2996 70.8804 35.7007 70.8406 32.5168Z"
                    fill={background ? "black" : "white"}
                />
                <div.path
                    transform={interpSpot}
                    d="M108.495 32.5168C108.455 29.3329 106.908 26.7697 105.039 26.7916C103.17 26.8136 101.687 29.4125 101.727 32.5964C101.767 35.7803 103.314 38.3435 105.183 38.3216C107.052 38.2996 108.535 35.7007 108.495 32.5168Z"
                    fill={background ? "black" : "white"}
                />
            </div.svg>
        </SCoursiveCardCharacter>
    );
};

CoursiveCardCharacter.propTypes = {
    interpBg: PT.any,
    interpSpot: PT.any,
    background: PT.string,
};

const SCoursiveCardCharacter = styled.div`
    .character {
        fill: #c4adff;
        .project_card_hover & {
            fill: #eb5757;
        }
    }
    svg {
        ${position("absolute", null, "10px", "-162px", null)};
        width: 192px;
        path {
            transition: "all 0.2s";
        }
    }
`;

export default CoursiveCardCharacter;
