import React from "react";
import { position } from "polished";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { ProjectCard } from "~/src/components";

import artischock_logo_left from "./card/artischock_logo_left.svg";
import artischock_logo_center from "./card/artischock_logo_center.svg";
import artischock_logo_right from "./card/artischock_logo_right.svg";

import noise from "./card/noise.gif";

const ArtishockCard = () => {
    const data = useStaticQuery(graphql`
        query ArtishockQuery {
            product: file(name: { eq: "product" }, ext: { eq: ".png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);
    const product = getImage(data.product);
    //
    return (
        <ProjectCard
            title="ArtiShock"
            desc="Souvenir products"
            slug="artishock"
        >
            <SArtishockCard>
                <SBg />
                <SLogo>
                    <SLogoLeft>
                        <img src={artischock_logo_left} alt="artishock" />
                    </SLogoLeft>
                    <SLogoCenter>
                        <img src={artischock_logo_center} alt="artishock" />
                    </SLogoCenter>
                    <SLogoRight>
                        <img src={artischock_logo_right} alt="artishock" />
                    </SLogoRight>
                </SLogo>
                <SProduct>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 511.358 348.193"
                    >
                        <path
                            d="M9975.1-3298.1H9584.581s-123.092,172.7-120.806,242.12c.947,28.739,27.506,47.682,51.619,64.9a525.383,525.383,0,0,0,69.186,41.175H9975.1Z"
                            transform="translate(-9463.744 3298.1)"
                            fill="#fe2d85"
                        />
                    </svg>
                    <SProductImage
                        image={product}
                        alt="product"
                        objectFit="contain"
                    />
                </SProduct>
            </SArtishockCard>
        </ProjectCard>
    );
};

const SArtishockCard = styled.div`
    ${position("absolute", "0", "0", null, null)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SBg = styled.div`
    ${position("absolute", "0", null, null, "0")};
    width: 100%;
    height: 100%;
    background-image: url(${noise});
    &:before {
        content: "";
        ${position("absolute", "0", null, null, "0")};
        width: 100%;
        height: 100%;
        background-color: #222222;
        opacity: 0.98;
    }
`;

const SProduct = styled.div`
    ${position("absolute", "0", "-30%", null, null)};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-out 0.1s;
    opacity: 1;
    svg {
        ${position("absolute", "0", "-20%", null, null)};
        height: 100%;
    }
    .project_card_hover & {
        right: -100%;
        opacity: 0;
        transition: all 0.2s ease-out 0s;
    }
`;

const SProductImage = styled(GatsbyImage)`
    ${position("absolute", "0", "0", null, null)};
    width: 80%;
    height: 100%;
    > div {
        width: 80%;
    }
`;

const SLogo = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    left: -25%;
    transition: all 0.2s ease-out 0.05s;
    .project_card_hover & {
        left: 0;
    }
    img {
        height: 30px;
        display: block;
    }
`;
const SLogoLeft = styled.div`
    overflow: hidden;
    width: 0;
    opacity: 0;
    transition: all 0.2s ease-out 0.1s;
    .project_card_hover & {
        width: 140px;
        opacity: 1;
    }
`;
const SLogoCenter = styled.div`
    margin: 0 0.1em;
    img {
        width: auto;
        height: 60px;
    }
`;
const SLogoRight = styled.div`
    overflow: hidden;
    width: 0;
    opacity: 0;
    transition: all 0.2s ease-out 0.1s;
    .project_card_hover & {
        width: 54px;
        opacity: 1;
    }
`;

export default ArtishockCard;
