import React from "react";

const HelpIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <circle
                cx="50"
                cy="50"
                r="45.5"
                strokeWidth="5.2173913px"
                stroke="currentColor"
                fill="none"
            ></circle>
            <path
                fill="currentColor"
                d="M35.2 42.1c-.3-7.6 2.8-15 14.8-15 8.6 0 14.3 4.3 14.3 11.9 0 5.1-2.2 7.9-6.8 11.8-2.8 2.2-5.6 4.7-5.6 8.7V61h-4.8c-.3-2.8.1-7 3-10 3.4-3.6 8.5-6.2 8.5-11.6 0-2.8-1.8-7.4-8.6-7.4-2.5 0-9.3.5-9 10.3h-5.8zm11.1 26.2h7.3v7.3h-7.3v-7.3z"
            ></path>
        </svg>
    );
};

export default HelpIcon;
